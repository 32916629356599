<template>
    <addPodcast v-if="openModal" :type="selectedType" @closeModal="openModal = false, isEdit = false"
        :isEdit="isEdit" />
    <div class="useful" :class="{ mainStyle3: $store.state.role == 'supervisor' }">
        <div class="useful__left">
            <platformTable />
        </div>
        <div class="useful__right">
            <div class="useful__right__top">
                <div class="useful__right__top__tabs">
                    <!-- <div
                        class="useful__right__top__tabs__in"
                        v-for="(tab, index) in tabs"
                        :key="tab"
                        :class="{ customTab: selectedTab === index }"
                        @click="selectTab(index)"
                        ref="tabs"
                    >
                        {{ tab.name }}
                    </div> -->
                    <swiper class="useful__right__top__tabs__swiper" @swiper="getSwiperCategory" :breakpoints="{
                        0: {
                            slidesPerView: 'auto'
                        }
                    }" :spaceBetween="50" :speed="500" :lazy="true">
                        <swiper-slide v-for="(tab, index) in tabs" :key="tab"
                            :class="{ customTab: selectedTab === index }"
                            @click="selectTab(index), isCommunity = false, openQuestion = false" ref="tabs">
                            {{ tab.name }}
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="useful__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="useful__right__block" v-if="selectedTab == 0">
                <div class="useful__right__block__in">
                    <p class="useful__right__block__in__title">Аудио-подкасты</p>
                    <div class="useful__right__block__in__bot">
                        <div class="useful__right__block__in__bot__in" v-for="item in visibleAudios" :key="item.id">
                            <grantCard @openModal="openModal = true, isEdit = true, newType('podcast')" :item="item" />
                            <div class="edit" v-if="$store.state.role == 'supervisor'">
                                <img src="@/assets/icons/editBlack.svg" @click="newType('podcast'), isEdit = true" />
                                <img src="@/assets/icons/delete.svg" @click="deleteItem(item, 'audios')" />
                            </div>
                        </div>
                    </div>
                    <button class="more" v-if="audios.length > 5" @click="toggleShowMore('audios')">
                        {{ showMoreAudios ? 'Скрыть' : 'Показать больше' }}
                    </button>
                </div>
                <div class="useful__right__block__in">
                    <p class="useful__right__block__in__title">Видео-подкасты</p>
                    <div class="useful__right__block__in__bot">
                        <div class="useful__right__block__in__bot__in" v-for="item in visibleVideos" :key="item.id">
                            <grantCard @openModal="openModal = true, isEdit = true, newType('podcast')" :item="item" />
                            <div class="edit" v-if="$store.state.role == 'supervisor'">
                                <img src="@/assets/icons/editBlack.svg" @click="newType('podcast'), isEdit = true" />
                                <img src="@/assets/icons/delete.svg" @click="deleteItem(item, 'videos')" />
                            </div>
                        </div>
                    </div>
                    <button class="more" v-if="videos.length > 5" @click="toggleShowMore('videos')">
                        {{ showMoreVideos ? 'Скрыть' : 'Показать больше' }}
                    </button>
                </div>
            </div>
            <!-- <button class="more" v-if="selectedTab == 0" :class="{supervisorButton: $store.state.role == 'supervisor'}">Показать больше</button> -->
            <div class="rating" v-if="selectedTab == 1">
                <p class="customTitle">Рейтинги</p>
                <div class="rating__block">
                    <div class="rating__block__in" v-for="item in ratings" :key="item">
                        <grantCard @openModal="openModal = true, isEdit = true, newType('podcast')" :item="item" />
                    </div>
                </div>
            </div>
            <div class="community" v-if="selectedTab == 2 && isCommunity == false">
                <p class="customTitle">Community</p>
                <div class="community__block">
                    <div class="community__block__in" v-for="item in community" :key="item" @click="newCommunity(item)">
                        <img :src="item.image" />
                        <p>{{ item.title }}</p>
                        <div class="edit" v-if="$store.state.role == 'supervisor'">
                            <img src="@/assets/icons/editBlack.svg" @click="newType('country'), isEdit = true" />
                            <img src="@/assets/icons/delete.svg" />
                        </div>
                    </div>
                    <div class="plus" v-if="$store.state.role == 'supervisor'" @click="newType('country')">
                        <img src="@/assets/icons/plus.svg" />
                    </div>
                </div>
            </div>
            <div class="community" v-if="isCommunity">
                <p class="customTitle" @click="isCommunity = false"><span>
                        < {{ selectedCommunity }}</span>
                </p>
                <div class="community__block">
                    <a :href="item.hyperlink" class="community__block__in1" v-for="item in communityIn" :key="item"
                        @click="isCommunity = true">
                        <p>{{ item.title }}</p>
                        <div class="edit" v-if="$store.state.role == 'supervisor'" style="top: 10px; right: 10px;">
                            <img src="@/assets/icons/editBlack.svg" @click="newType('community'), isEdit = true" />
                            <img src="@/assets/icons/delete.svg" />
                        </div>
                    </a>
                    <div class="plus" v-if="$store.state.role == 'supervisor'" @click="newType('community')"
                        style="aspect-ratio: 4.6;">
                        <img src="@/assets/icons/plus.svg" />
                    </div>
                </div>
            </div>
            <div class="gide" v-if="selectedTab == 3">
                <p class="customTitle">Гайды</p>
                <div class="gide__block">
                    <div class="gide__block__in" v-for="item in visibleGuides" :key="item.id">
                        <grantCard @openModal="openModal = true, isEdit = true, newType('gide')" :item="item" />
                        <div class="edit" v-if="$store.state.role == 'supervisor'">
                            <img src="@/assets/icons/editBlack.svg" @click="newType('gide'), isEdit = true" />
                            <img src="@/assets/icons/delete.svg" @click="deleteItem(item, 'guides')" />
                        </div>
                    </div>
                </div>
                <button class="more" v-if="guides.length > 5" @click="toggleShowMore('guides')">
                    {{ showMoreGuides ? 'Скрыть' : 'Показать больше' }}
                </button>
            </div>
            <!-- <button class="more" v-if="selectedTab == 3" :class="{supervisorButton: $store.state.role == 'supervisor'}">Показать больше</button> -->
            <div class="community" v-if="selectedTab == 4 && openQuestion == false">
                <p class="customTitle">Q&A</p>
                <div class="community__block">
                    <div class="community__block__in" v-for="item in questions" :key="item"
                        @click="openNewQuestion(item.items)">
                        <img :src="item.image" class="community__block__in__image" />
                        <p>{{ item.title }}</p>
                        <div class="edit" v-if="$store.state.role == 'supervisor'">
                            <img src="@/assets/icons/editBlack.svg" />
                            <img src="@/assets/icons/delete.svg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="inQa" v-if="openQuestion">
                <div class="inQa__top">
                    <div class="inQa__top__left">
                        <p @click="openQuestion = false" style="cursor: pointer;"><span>
                                < Q&A</span>
                        </p>
                    </div>
                    <div class="inQa__top__right">
                        <input type="search" placeholder="Поиск вопроса..." />
                        <button :class="{ supervisorButton: $store.state.role == 'supervisor' }">Поиск вопроса</button>
                        <button class="inQa__top__right__add" v-if="$store.state.role == 'supervisor'"
                            @click="newType('question')">Добавить вопрос</button>
                    </div>
                </div>
                <div class="inQa__block">
                    <div class="inQa__block__in" v-for="(item, index) in dopQuestions" :key="item">
                        <div class="edit" v-if="$store.state.role == 'supervisor' && selectedQuestion != index">
                            <img src="@/assets/icons/editBlack.svg" @click="newType('question'), isEdit = true" />
                            <img src="@/assets/icons/delete.svg" />
                        </div>
                        <div class="inQa__block__in__top" @click="selectQuestion(index)">
                            <div class="inQa__block__in__top__left">
                                <p class="inQa__block__in__top__left__question">Вопрос {{ index + 1 }}:</p>
                                <p>{{ item.title }}</p>
                            </div>
                            <img src="@/assets/icons/redSelect.svg" v-if="selectedQuestion == index"
                                style="rotate: 180deg" />
                            <img src="@/assets/icons/redSelect.svg" v-else />
                        </div>
                        <div class="inQa__block__in__bot" v-if="selectedQuestion == index" v-html="item.description">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import grantCard from '@/components/grantCard.vue';
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination } from 'swiper';
import "swiper/swiper.min.css";
import userInfo from '@/components/userInfo.vue';
SwiperCore.use([Navigation, Pagination, A11y, Autoplay])
import customSelect from '@/components/customSelect.vue';
import addPodcast from '@/components/supervisor/addPodcast.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default {
    components: {
        platformTable, Swiper, SwiperSlide, newCardComponent, userInfo, grantCard, customSelect, addPodcast
    },
    data() {
        return {
            activeTab: 'list',
            dopQuestions: '',
            selectedType: '', // Initial limit for guides
            showMoreAudios: false, // Flag for Show More / Hide in audios
            showMoreVideos: false, // Flag for Show More / Hide in videos
            showMoreGuides: false,
            selectedStip: -1,
            selectedStep: -1,
            audiosLimit: 10,
            videosLimit: 10,
            guidesLimit: 10,
            selectedTab: 0,
            ratings: [],
            isCommunity: false,
            selectedCommunity: '',
            isEdit: false,
            openQuestion: false,
            swiperRefCategory: null,
            community: false,
            selectedQuestion: -1,
            swiperRef: null,
            openModal: false,
            activeSwiperIndex: 0,
            questions: false,
            communityIn: '',
            guides: '',
            audios: '',
            videos: '',
            visibleAudios: [], // Array for the visible audio podcasts
            visibleVideos: [], // Array for the visible video podcasts
            visibleGuides: [],
            tabs: [
                {
                    name: 'Полезное'
                },
                {
                    name: 'Рейтинги'
                },
                {
                    name: 'Community'
                },
                {
                    name: 'Гайды'
                },
                {
                    name: 'Q&A'
                },
            ],
        }
    },
    methods: {
        toggleShowMore(type) {
            if (type === 'audios') {
                if (this.showMoreAudios) {
                    // Collapse to show 5 items
                    this.visibleAudios = this.audios.slice(0, 10);
                } else {
                    // Show all items
                    this.visibleAudios = this.audios;
                }
                this.showMoreAudios = !this.showMoreAudios;
            } else if (type === 'videos') {
                if (this.showMoreVideos) {
                    // Collapse to show 5 items
                    this.visibleVideos = this.videos.slice(0, 10);
                } else {
                    // Show all items
                    this.visibleVideos = this.videos;
                }
                this.showMoreVideos = !this.showMoreVideos;
            } else if (type === 'guides') {
                if (this.showMoreGuides) {
                    // Collapse to show 5 items
                    this.visibleGuides = this.guides.slice(0, 10);
                } else {
                    // Show all items
                    this.visibleGuides = this.guides;
                }
                this.showMoreGuides = !this.showMoreGuides;
            }
        },
        newCommunity(item) {
            this.selectedCommunity = item.title;
            this.communityIn = item.items;
            this.isCommunity = true
        },
        openNewQuestion(item) {
            this.dopQuestions = item;
            this.openQuestion = true
        },
        newType(item) {
            this.openModal = true;
            this.selectedType = item;
        },
        selectTab(index) {
            this.$router.push({ query: { tab: index } });
            this.selectedTab = index;
            this.swiperRefCategory?.slideTo(index, 500);
            this.scrollToTop();
        },
        getSwiperCategory(swiper) {
            this.swiperRefCategory = swiper;
            this.activeSwiperIndex = swiper.activeIndex;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        selectQuestion(index) {
            if (index == this.selectedQuestion) {
                this.selectedQuestion = -1
            }
            else {
                this.selectedQuestion = index
            }
        },
        async getPage() {
            await axios.get(`/V1/platform/useful/audios`)
                .then(res => {
                    this.audios = res.data.data;
                    this.visibleAudios = this.audios.slice(0, this.audiosLimit);
                })
                .catch(err => { });

            await axios.get(`/V1/platform/useful/videos`)
                .then(res => {
                    this.videos = res.data.data;
                    this.visibleVideos = this.videos.slice(0, this.videosLimit);
                })
                .catch(err => { });

            await axios.get(`/V1/platform/useful/guides`)
                .then(res => {
                    this.guides = res.data.data;
                    this.visibleGuides = this.guides.slice(0, this.guidesLimit);
                })
                .catch(err => { });
            await axios.get(`/V1/page/questions`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.questions = res.data.questions
                })
                .catch(err => {
                })
            await axios.get(`/platform/ratings`)
                .then(res => {
                    this.ratings = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/platform/communities`)
                .then(res => {
                    this.community = res.data.data
                })
                .catch(err => {
                })
        },
    },
    async created() {
        if (this.$route.query.tab) {
            this.selectedTab = parseInt(this.$route.query.tab);
        }
        await this.getPage();
    }
}
</script>
<style lang="scss" scoped>
.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}

a {
    color: inherit;
}

.customTitle {
    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
    color: #0000008A;
    font-weight: 600;
    cursor: pointer;

    @media(max-width:768px) {
        font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
    }
}

.edit {
    display: flex;
    gap: 10px;
    align-items: center;
    right: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
    top: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
    position: absolute;
    z-index: 2;
}

.plus {
    border: 1px solid #0000001A;
    width: 18%;
    display: flex;
    aspect-ratio: 0.763;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 8px 4px #0000000D inset;
    border-radius: 10px;
    cursor: pointer;
}

.rating {
    display: flex;
    flex-direction: column;
    gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

    &__block {
        display: flex;
        flex-wrap: wrap;
        gap: 2.5%;

        &__in {
            width: 18%;
            padding: 10px;
            border-radius: 5px;
            background-color: white;
            display: flex;
            cursor: pointer;
            flex-direction: column;
            gap: 10px;
            position: relative;

            &__top {
                width: 100%;
                position: relative;

                img {
                    width: 100%;
                    height: auto;
                }

                &__status {
                    position: absolute;
                    background-color: #FD2D2A;
                    color: white;
                    left: 10px;
                    top: 10px;
                    padding: 5px;
                    border-radius: 5px;
                }
            }

            &__name {
                font-weight: 700;
                text-align: center;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                /* Limiting the text to 3 lines */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            button {
                padding: 10px 0px;
                width: 100%;
                background-color: #FD2D2A;
                border: none;
                border-radius: 5px;
                color: white;
            }
        }
    }

    @media(max-width:768px) {
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

        &__block {
            gap: 2%;

            &__in {
                width: 49%;
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
            }
        }
    }
}

.useful {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    min-height: 100vh;

    &__left {
        width: 20%;
    }

    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        display: flex;
        flex-direction: column;
        gap: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__tabs {
                width: 70%;
                font-weight: 600;
                font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);
                ;
                color: #00000026;
                display: flex;
                position: relative;

                &__swiper {
                    width: 100%;

                    .swiper-slide {
                        cursor: pointer;
                        width: fit-content;
                    }
                }

                .customTab {
                    color: #000; // Активный таб выделяется
                }
            }

            &__right {
                width: 25%;
            }
        }

        &__block {
            display: flex;
            flex-direction: column;
            gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

            &__in {
                display: flex;
                flex-direction: column;
                cursor: pointer;
                gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

                &__title {
                    color: #0000008A;
                    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                }

                &__bot {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: stretch;
                    gap: 2.5%;
                    row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

                    &__in {
                        width: 18%;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    @media(max-width:768px) {
        padding-top: 0px;

        &__left {
            display: none;
        }

        &__right {
            width: 100%;
            padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            border-radius: 0px;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

            &__top {
                &__tabs {
                    width: 100%;
                    font-size: 21px;
                    margin-left: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                }

                &__right {
                    display: none;
                }
            }

            &__block {
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                &__in {
                    gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                    &__title {
                        font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
                    }

                    &__bot {
                        gap: 2%;
                        row-gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                        &__in {
                            width: 49%;
                        }
                    }
                }
            }
        }
    }
}

.community {
    display: flex;
    flex-direction: column;
    gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

    &__block {
        display: flex;
        flex-wrap: wrap;
        gap: 3.5%;
        row-gap: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);

        .edit {
            right: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            top: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        }

        .plus {
            width: 31%;
            aspect-ratio: 1.54;
        }

        &__in,
        &__in1 {
            position: relative;
            width: 31%;
            cursor: pointer;
            padding: 10px;
            background-color: white;
            border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;

            img {
                width: 100%;
                aspect-ratio: 2;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        &__in1 {
            padding: 35px 10px;
            text-align: center;
            border: 1px solid #0000001A;
            box-shadow: 0px 4px 3px 1px #0000000D;
        }
    }

    @media(max-width:768px) {
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

        &__block {
            flex-direction: column;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

            &__in,
            &__in1 {
                width: 100%;
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
            }
        }
    }
}

.gide {
    display: flex;
    flex-direction: column;
    gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

    &__block {
        display: flex;
        flex-wrap: wrap;
        gap: 2.5%;
        row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

        &__in {
            width: 18%;
            cursor: pointer;
        }
    }

    @media(max-width:768px) {
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

        &__block {
            gap: 2%;
            row-gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

            &__in {
                width: 49%;
            }
        }
    }
}

.inQa {
    display: flex;
    flex-direction: column;
    gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

    &__top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        select,
        input {
            padding: 10px min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            border-radius: 10px;
            color: #0000008A;
            background-color: transparent;
            border: 1px solid #0000001A;
            cursor: pointer;
        }

        &__left {
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            align-items: center;
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            color: #0000008A;
            font-weight: 600;
            width: 25%;

            &__select {
                width: 70%;
            }
        }

        &__right {
            width: 70%;
            display: flex;
            justify-content: space-between;

            input {
                width: 80%;
                font-style: italic;
            }

            &__add {
                background-color: transparent !important;
                border: 1px solid #0000001A !important;
                color: black !important;
            }

            button {
                width: 18%;
                background-color: #11415D;
                color: white;
                border: none;
                padding: 10px min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                border-radius: 10px;
            }
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

        &__in {
            display: flex;
            flex-direction: column;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            padding: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
            border-radius: 10px;
            background-color: white;
            box-shadow: 0px 8px 8px 2px #0000000D;
            cursor: pointer;
            position: relative;

            .edit {
                right: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                top: 50%;
                transform: translate(-50%, -50%);
            }

            &__top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                img {
                    width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                }

                &__left {
                    display: flex;
                    gap: 10px;
                    font-weight: 700;
                    align-items: center;

                    &__question {
                        color: #0000008A;
                        font-weight: 400;
                    }
                }
            }

            &__bot {
                padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                background-color: #F5F7FB;
                border: 1px solid #00000024;
                height: 150px;
                border-radius: 10px;
                font-weight: 500;
            }
        }
    }

    @media(max-width:768px) {
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

        &__top {
            flex-direction: column;
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

            &__left {
                width: 100%;
                font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);

                &__select {
                    width: 80%;
                }
            }

            &__right {
                width: 100%;
                flex-direction: column;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                input {
                    width: 100%;
                }

                button {
                    width: 100%;
                }
            }
        }

        &__block {
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

            &__in {
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                &__top {
                    &__left {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }
        }
    }
}
</style>