<template>
    <div class="grantCard">
        <div class="grantCard__img" :style="{ backgroundImage: `url('${item?.image || item?.icon || item?.premium_option?.icon ||  require('@/assets/icons/course.webp')}')` }">
            <div class="bilim" :class="{supervisorButton: $store.state.role == 'supervisor'}" v-if="isGrant">
                <p>{{ item.scholarship_name }}</p>
            </div>
        </div>
        <p class="grantCard__stip" v-if="isGrant">{{item?.study_degree_name}}</p>
        <div class="line" v-if="isGrant"></div>
        <div class="grantCard__category" v-html="item?.title"></div>
        <button  v-if="isToBuy" @click="openExtend()">Купить</button>
        <a :href="item?.file"  v-if="item.file && isToBuy == null" target="_blank">Подробнее</a>
        <a :href="item?.hyperlink" v-if="item.hyperlink && isToBuy == null" target="_blank">Подробнее</a>
        <a :href="item?.link"  v-if="item.link && isToBuy == null" target="_blank">Подробнее</a>
    </div>
</template>
<script>
import aboutProgram from './newPlatform/aboutProgram.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default {
    components: {
        aboutProgram
    },  
    props: ['isGrant', 'item', 'isToBuy'],
    data(){
        return{
            isEdit: false,
        }
    },
    methods: {
        openExtend(){
            this.$emit('openExtend', this.item)
        },
        buyCard(){
            const data = {
                id: this.item.id
            }
            axios.post('/platform/client/baskets/buy', data , {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 202) {
                toast.success('Покупка успешно совершена')
                this.$emit('deleteBuy')
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
.customImage{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #f0f2f578;
        border-radius: 10px;
    }
}
.customButton{
    background-color: #F0F2F5 !important;
    color: black !important;
}
a{
    color: white !important;
}
    .grantCard{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px);
        padding: 10px;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        border-radius: 10px;
        position: relative;
        height: 100%;
        &__img{
            width: 100%;
            aspect-ratio: 1;
            position: relative;
            background-size: cover;
            background-position: center;
            border-radius: 10px;
                .bilim{
                    position: absolute;
                    z-index: 998;
                    background-color: white;
                    bottom: 10px;
                    left: 10px;
                    display: flex;
                    gap: 5px;
                    color: white;
                    img{
                        width: 13px;
                    }
                    font-size: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px);
                    padding: 5px;
                    border-radius: 5px;
                    background-color: #FD2D2A;
                    align-items: center;
                }
        }
        &__edit{
            display: flex;
            gap: 10px;
            align-items: center;
            position: absolute;
            top: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            right: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            img{
                cursor: pointer;
            }
        }
        &__stip{
            color: #0000008A;
        }
        button, a{
            width: 100%;
            border-radius: 10px;
            padding: 5px 0px;
            background-color: #FD2D2A;
            border: none;
            cursor: pointer;
            color: white;
            text-align: center;
            font-weight: 700;
        }
        @media(max-width:768px){
            font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
            button{
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
            }
        }
    }
</style>