import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import findPrograms from "@/views/newPlatform/findPrograms.vue";
import favouritePrograms from "@/views/newPlatform/favouritePrograms.vue";
import indexPlatform from "@/views/newPlatform/index.vue";
import inProgram from "@/views/newPlatform/inProgram.vue";
import admission from "@/views/newPlatform/admission.vue";
import schools from "@/views/newPlatform/schools.vue";
import intGrants from "@/views/newPlatform/intGrants.vue";
import premium from "@/views/newPlatform/premium.vue";
import useful from "@/views/newPlatform/useful.vue";
import curatorIndex from "@/views/curator/index.vue";
import users from "@/views/curator/users.vue";
import loginPage from "../views/login/loginPage.vue";
import registrationPage from "../views/login/registrationPage.vue";
import programs from "@/views/curator/programs.vue";
import userView from "@/views/curator/userView.vue";
import notifications from '@/views/curator/notifications.vue';
import index from '@/views/supervisor/index.vue';
import curators from '@/views/supervisor/curators.vue';
import superUsers from '@/views/supervisor/users.vue';
import platform from '@/views/supervisor/platform.vue';
import superSchools from '@/views/supervisor/schools.vue';
import news from '@/views/supervisor/news.vue';
import premiumVisor from '@/views/supervisor/premium.vue';
import basket from '@/views/newPlatform/basket.vue';
import waitPrograms from '@/views/curator/waitPrograms.vue';
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/testPlatform/basket',
    name: 'basket',
    component: basket
  },
  {
    path: "/login",
    name: "login",
    component: loginPage
  },
  {
    path: "/registration",
    name: "registration",
    component: registrationPage
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  },
  {
    path: "/supervisor",
    name: "supervisor",
    component: index,
  },
  {
    path: "/supervisor/news",
    name: "news",
    component: news,
  },
  {
    path: "/supervisor/premium",
    name: "premiumVisor",
    component: premiumVisor,
  },
  {
    path: "/supervisor/curators",
    name: "curators",
    component: curators,
  },
  {
    path: "/supervisor/users",
    name: "superUsers",
    component: superUsers,
  },
  {
    path: "/supervisor/schools",
    name: "superSchools",
    component: superSchools,
  },
  {
    path: "/supervisor/platform",
    name: "platform",
    component: platform,
  },
  {
    path: "/curator",
    name: "curator",
    component: curatorIndex,
  },
  {
    path: "/curator/notifications",
    name: "notifications",
    component: notifications,
  },
  {
    path: "/curator/waitPrograms",
    name: "waitPrograms",
    component: waitPrograms,
  },
  {
    path: "/curator/users",
    name: "users",
    component: users,
  },
  {
    path: "/curator/programs",
    name: "programs",
    component: programs,
  },
  {
    path: "/curator/users/:id",
    name: "userView",
    component: userView,
  },
  {
    path: "/testPlatform",
    name: "test",
    component: indexPlatform,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatform/admission",
    name: "admission",
    component: admission,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatform/premium",
    name: "premium",
    component: premium,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatform/intGrants",
    name: "intGrants",
    component: intGrants,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatform/useful",
    name: "useful",
    component: useful,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatform/schools",
    name: "schools",
    component:schools,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testFavourite",
    name: "testFavourite",
    component: favouritePrograms,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatformFind",
    name: "testFind",
    component: findPrograms,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatformFind/:id",
    name: "testProgram",
    component: inProgram,
    meta: {
      isPlatform: true
    }
  },
]

const router = createRouter({ 
  history: createWebHistory(process.env.BASE_URL), 
  routes, 
  scrollBehavior(to) { 
    // Проверяем, есть ли у нас хэш-адрес в объекте to 
    if (to.hash) { 
       
        const el = document.querySelector(to.hash); 
        if (el) { 
        const scrollTop = el.getBoundingClientRect().top + window.pageYOffset - 85; 
        window.scrollTo({ 
            top: scrollTop, 
            behavior: 'smooth' 
        }); 
        return null; 
      } 
 
    } else { 
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' }) 
      // return { top: 0 } 
    } 
  } 
})
// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (localStorage.getItem("access_token")) {
//       next();
//     }
//     else {
//       next({ name: "home" });
//     }
//   }
//   else {
//     next();
//   }
// });

export default router;
