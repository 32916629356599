<template>
    <div class="mainStyle" :class="{ mainStyle3: $store.state.role == 'supervisor' }">
        <div class="mainStyle__left">
            <platformTable />
        </div>
        <div class="mainStyle__right">
            <div class="mainStyle__right__top">
                <div class="mainStyle__right__top__left" v-if="isInAdmission">
                    <p>Пользователи > {{ editUser.name }} > <span>Поступление ВУЗ</span></p>
                </div>
                <div class="mainStyle__right__top__left" v-else>
                    <p style="color: #0000008A;">Пользователи ></p>
                    <p class="mainStyle__right__top__left__title">{{ editUser.name }}</p>
                </div>
                <div class="mainStyle__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="userCard">
                <div class="userCard__customTop" v-if="isInAdmission">
                    <div class="userCard__customTop__left">
                        <img src="@/assets/icons/univer.svg" />
                        <div class="userCard__customTop__left__right">
                            <p class="userCard__customTop__left__right__name bold">Massachusetts Institute of Technology
                            </p>
                            <p class="userCard__customTop__left__right__email grey">Massachusets, USA</p>
                        </div>
                    </div>
                    <div class="userCard__customTop__export">
                        <img src="@/assets/icons/export.svg" />
                        <p>Экспортировать данные</p>
                    </div>
                </div>
                <div class="userCard__top" v-else>
                    <img :src="editUser.image_url" v-if="editUser.image_url" />
                    <img src="@/assets/icons/profile.webp" v-else />
                    <div class="userCard__top__right">
                        <p class="userCard__top__right__name bold">{{ editUser.name }}</p>
                        <p class="userCard__top__right__email grey">{{ editUser.id }}</p>
                    </div>
                </div>
                <div class="userCard__tabs">
                    <div class="userCard__tabs__in" v-for="(item, index) in tabs" :key="item"
                        @click="newIndex(index)">
                        <div class="userCard__tabs__in__name" :class="{ activeTab: index == activeTab }">{{ item.name }}
                        </div>
                        <div class="line2"></div>
                    </div>
                </div>
                <div class="main" v-if="activeTab == 0 && isInAdmission == false">
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Договор</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInputWhite">
                            <label>Статус договора</label>
                            <customSelect :list="contractStatusses" v-model="contStatusId" @change="newCoundOfDays(), mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Номер договора</label>
                            <input placeholder="Не выбрано" v-model="contractNumber" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Дата заключения договора</label>
                            <input type="date"  value=""  v-model="contractDate" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Количество дней договора</label>
                            <input placeholder=".." v-model="countDays" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Подача</label>
                            <customSelect :list="supplyList" v-model="supplyId" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Статус клиента</label>
                            <customSelect :list="clientStatusses" v-model="clientStatusId" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Желаемая степень</label>
                            <customSelect :list="steps" v-model="stepId" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Год поступления</label>
                            <input type="text" disabled placeholder="Выбирается супервайзером" @change="mainIsChanged()"/>
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Виджет “Ключевые сроки” > GENERAL ENGLISH</p>
                        <div class="customTabTitle__right">
                            <div class="customTabTitle__right__add" @click="newCources()">
                                <img src="@/assets/icons/plus.svg" />
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__inputs1" v-for="item in keyCources" :key="item">
                        <div class="customInputWhite">
                            <label>Название элемента</label>
                            <input v-model="item.title" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Срок</label>
                            <input type="date"  value=""  v-model="item.deadline" @change="mainIsChanged()"/>
                        </div>
                        <div class="main__inputs__delete" @click="deleteGeneral(item.id)">
                            <img src="@/assets/icons/delete.svg" />
                        </div>
                        <div class="main__inputs__select">
                            <customSelect :list="courseStatusses" v-model="item.status" @change="mainIsChanged()"/>
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Виджет “Ключевые сроки” > Тесты</p>
                        <div class="customTabTitle__right">
                            <div class="customTabTitle__right__add" @click="newTests()">
                                <img src="@/assets/icons/plus.svg" />
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__inputs1" v-for="item in keyTests" :key="item">
                        <div class="customInputWhite">
                            <label>Название элемента</label>
                            <input v-model="item.title" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Срок</label>
                            <input type="date" value="" v-model="item.deadline" @change="mainIsChanged()"/>
                        </div>
                        <div class="main__inputs__delete" @click="deleteTest(item.id)">
                            <img src="@/assets/icons/delete.svg" />
                        </div>
                        <div class="main__inputs__select">
                            <customSelect :list="courseStatusses" v-model="item.status" @change="mainIsChanged()"/>
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Виджет “Индивидуальный план”</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInputWhite">
                            <label>Индивидуальный план 1</label>
                            <input type="text" v-model="individuals[0].hyperlink" placeholder="Введите ссылку" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Индивидуальный план 2</label>
                            <input type="text" placeholder="Введите ссылку" v-model="individuals[1].hyperlink" @change="mainIsChanged()"/>
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Виджет “Международная стипендия”</p>
                    </div>
                    <div class="main__inputs1">
                        <div class="customInputWhite">
                            <label>Название элемента</label>
                            <input v-model="intSch[0].title" disabled @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Срок</label>
                            <input type="date" value="" v-model="intSch[0].date" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Название элемента</label>
                            <input v-model="intSch[1].title" disabled @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Срок</label>
                            <input type="date" value="" v-model="intSch[1].date" @change="mainIsChanged()"/>
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Виджет “Риски”</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInputWhite">
                            <label>Риски пользователя</label>
                            <customSelect :list="newRisks" v-model="newRiskFromSelect" @change="newRisk2(), mainIsChanged()" :isRisk="true"/>
                        </div>
                    </div>
                    <div class="main__risks" v-if="risks.length > 0">
                        <div class="main__risks__in" v-for="item in risks" @click="deleteRisks(item.id)" :key="item">
                            <img src="@/assets/icons/exit.svg" />
                            <p>{{ item.title }}</p>
                        </div>
                    </div>
                    <div class="main__risks" v-if="risksToSend.length > 0">
                        <div class="main__risks__in" v-for="item in risksToSend" @click="deleteNewRisks(item.id)" :key="item">
                            <img src="@/assets/icons/exit.svg" />
                            <p>{{ item.title }}</p>
                        </div>
                    </div>
                    <div class="main__risk">
                        <input placeholder="Введите название риска..." v-model="newRisk" />
                        <button @click="newRisk1(), mainIsChanged()">Добавить новый риск</button>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save"
                            :class="{ supervisorButton: $store.state.role == 'supervisor' }"
                            @click="sendMain()">Применить</button>
                        <button class="main__buttons__delete" @click="reload()">Сбросить</button>
                    </div>
                </div>
                <div class="general" v-if="activeTab == 1 && isInAdmission == false">
                    <AddProduct v-if="openAdd" @closeModal="openAdd = false" :is-add="true" @newProduct="newProduct()"/>
                    <AddProduct v-if="openRedactor" @closeModal="openRedactor = false" :is-redactor="true" :item="selectedBuy" @newProduct="newProduct()"/>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Личная информация</p>
                    </div>
                    <div class="general__inputs">
                        <div class="customInputWhite">
                            <label>Фамилия</label>
                            <input v-model="editUser.surname" :placeholder="editUser.surname" @input="validateCyrillicSurname" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Имя</label>
                            <input v-model="editUser.name" :placeholder="editUser.name" @input="validateCyrillicName" @change="mainIsChanged()"/>
                        </div>
                        <div class="newcustomInputWhite">
                            <label>ИИН</label>
                            <div class="newcustomInputWhite__in">
                                <input v-model="editUser.iin" :placeholder="editUser.iin" @change="mainIsChanged()"/>
                                <div class="line1"></div>
                                <p v-if="editUser.iin_status == 0" @click="confirmGeneral('iin_status')">Подтвердить</p>
                                <p v-if="editUser.iin_status == 1" @click="declineGeneral('iin_status')">Подтверждено</p>
                            </div>
                        </div>
                        <div class="customInputWhite">
                            <label>Дата рождения</label>
                            <input type="date"  value=""  v-model="editUser.birthday" :placeholder="editUser.birthday" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Страна</label>
                            <customSelect :list="countries" @change="cityList(), mainIsChanged()" v-model="editUser.country_id"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Город</label>
                            <customSelect :list="cities" v-model="editUser.city_id" @change="mainIsChanged()"/>
                        </div>
                        <div class="newcustomInputWhite">
                            <label>Телефон</label>
                            <div class="newcustomInputWhite__in">
                                <input v-model="editUser.phone" :placeholder="editUser.phone" @change="mainIsChanged()"/>
                                <div class="line1"></div>
                                <p v-if="editUser.phone_status == 0" @click="confirmGeneral('phone_status')">Подтвердить
                                </p>
                                <p v-if="editUser.phone_status == 1" @click="declineGeneral('phone_status')">Подтверждено</p>
                            </div>
                        </div>
                        <div class="newcustomInputWhite">
                            <label style="color: #0000004A;">Почта</label>
                            <div class="newcustomInputWhite__in">
                                <input v-model="editUser.email" :placeholder="editUser.email" disabled style="color: #0000004A;" @change="mainIsChanged()"/>
                                <!-- <div class="line1"></div>
                                <p v-if="editUser.email_status == 0" @click="confirmGeneral('email_status')">Подтвердить
                                </p>
                                <p v-if="editUser.email_status == 1" @click="declineGeneral('email_status')">Подтверждено</p> -->
                            </div>
                        </div>
                        <div class="newcustomInputWhite">
                            <label>Телеграм</label>
                            <div class="newcustomInputWhite__in">
                                <input v-model="editUser.telegram" :placeholder="editUser.telegram" @change="mainIsChanged()"/>
                                <div class="line1"></div>
                                <p v-if="editUser.telegram_status == 0" @click="confirmGeneral('telegram_status')">
                                    Подтвердить</p>
                                <p v-if="editUser.telegram_status == 1" @click="declineGeneral('telegram_status')">Подтверждено</p>
                            </div>
                        </div>
                        <div class="customInputWhite">
                            <label>Пол</label>
                            <customSelect :list="genders" v-model="editUser.gender" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Адрес</label>
                            <input v-model="editUser.address" :placeholder="editUser.address" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Id</label>
                            <input v-model="editUser.id" :placeholder="editUser.id" disabled style="color: #0000004A;" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite" style="width: 100%;">
                            <label>Другое</label>
                            <textarea v-model="editUser.other" placeholder="Допольнительная информация" @change="mainIsChanged()"></textarea>
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Прогресс по клиенту</p>
                    </div>
                    <div class="general__progress">
                        <div class="general__progress__in">
                            <div class="customInputWhite">
                                <label>Введите процент</label>
                                <input v-model="progress" @change="mainIsChanged()"/>
                            </div>

                        </div>
                        <div class="general__progress__in">
                            <div class="customInputWhite">
                                <label>Выберите этап</label>
                                <customSelect :list="stages" v-model="selectedStage" @change="mainIsChanged()"/>
                            </div>
                        </div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save"
                            :class="{ supervisorButton: $store.state.role == 'supervisor' }"
                            @click="sendData()">Применить</button>
                        <button class="main__buttons__delete" @click="reload()">Сбросить</button>
                    </div>
                    
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Ваши покупки</p>
                    </div>
                    <div class="general__buys">
                        <div class="general__buys__in" v-for="item in userBuys" :key="item">
                            <div class="general__buys__in__edit">
                                <img src="@/assets/icons/redactor.svg" @click="openRedactor = true, selectedBuy = item" />
                                <div class="line1"></div>
                                <img src="@/assets/icons/basketGrey.svg" @click="deleteBuy(item.id)" />
                            </div>
                            <p class="general__buys__in__title" v-if="item.type == 1" v-html="courseMethod(item?.course?.type_name)" style="text-align: center;"></p>
                            <p class="general__buys__in__title" v-if="item.type == 2">Премиум опция<br/><br/></p>
                            <p class="general__buys__in__title" v-if="item.type == 3">Наставничество<br/><br/></p>
                            <div class="line"></div>
                            <img src="@/assets/icons/mentorMe.svg"/>
                            <p class="general__buys__in__name" v-if="item?.course?.type_name && item.type == 1">{{ item?.course?.title }}</p>
                            <p class="general__buys__in__name" v-if="item?.package?.title && item.type == 3">{{ item?.package?.title }}</p>
                            <p class="general__buys__in__name" v-if="item?.premium_option?.title && item.type == 2">{{ item?.premium_option?.title }}</p>
                            <p class="general__buys__in__sub" >{{ item?.comment }}</p>
                        </div>
                        <div class="general__buys__plus" @click="openAdd = true">
                            <img src="@/assets/icons/plus.svg" />
                        </div>
                    </div>
                </div>
                <div class="document" v-if="activeTab == 3 && isInAdmission == false">
                    <AddFile v-if="openFile" @closeModal="openFile = false" @getPageNew="getUser(), openFile = false"/>
                    <p class="document__title">Файлы и документы</p>
                    <div class="document__block">
                        <div class="document__block__in" v-for="item in documents" :key="item.id">
                            <img class="document__block__in__delete" src="@/assets/icons/delete.svg" @click="deleteDocument(item.id)"/>
                            <div class="document__block__in__left">
                                <img src="@/assets/icons/cvType.svg" v-if="item?.field == 'cv'"/>
                                <img src="@/assets/icons/otherType.svg" v-else/>
                            </div>
                            <div class="line2"></div>
                            <div class="document__block__in__right">
                                <a :href="item.link" class="document__block__in__right__pdf"
                                    :class="{ customGreen: item?.status == 1, notApproved: item?.status == 2 }">link
                                </a>
                                <p class="document__block__in__right__name"
                                    :class="{ customGreen: item?.status == 1, notApproved: item?.status == 2 }">{{
                                        item?.name }}</p>
                                <div class="document__block__in__right__undefined" v-if="item?.status == 0">
                                    <p class="document__block__in__right__undefined__left"
                                    >
                                        Approve</p>
                                    <div class="line2"></div>
                                    <p class="document__block__in__right__undefined__right"
                                        >Not yet</p>
                                </div>
                                <div class="document__block__in__right__approved" v-if="item?.status == 1">Approved
                                </div>
                                <div class="document__block__in__right__not" v-if="item?.status == 2">Not yet</div>
                            </div>
                        </div>
                        <div class="document__block__plus" @click="openFile = true">
                            <img src="@/assets/icons/plus.svg" />
                        </div>
                    </div>
                    <!-- <div class="main__buttons">
                        <button class="main__buttons__save"
                            :class="{ supervisorButton: $store.state.role == 'supervisor' }">Применить</button>
                        <button class="main__buttons__delete" @click="reload()">Сбросить</button>
                    </div> -->
                </div>
                <div class="favourite" v-if="activeTab == 4 && isInAdmission == false">
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Избранные программы клиента</p>
                        <div class="customTabTitle__right">
                            <div class="customTabTitle__right__add saveButton" @click="sendSuper"
                                v-if="$store.state.role == 'curator'">
                                <p>Отправить супервайзеру</p>
                            </div>
                            <div class="customTabTitle__right__check" v-if="$store.state.role == 'supervisor'">
                                <span>Утвердить</span> / Отклонить
                            </div>
                        </div>
                    </div>
                    <div class="favourite__block">
                        <div class="favourite__block__in" v-for="item in favourites" :key="item">
                            <NewCardComponent :item="item" :user-id="$route.params.id" :is-client="true"
                                @newAdmission="getAdmissions(), getFavourites()" />
                        </div>
                    </div>
                    <!-- <div class="main__buttons">
                        <button class="main__buttons__save" :class="{supervisorButton: $store.state.role == 'supervisor'}">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div> -->
                </div>
                <div class="admission" v-if="activeTab == 5 && isInAdmission == false">
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Выберите ВУЗ для детального редактирования</p>
                    </div>
                    <div class="admission__block">
                        <div class="admission__block__in" v-for="item in admissionPrograms" :key="item">
                            <NewCardComponent :is-admission="true" :item="item.university_program"
                                @changeAdmission="changeProgramm(item.id, item)" />
                        </div>
                    </div>
                </div>
                <div class="general" v-if="activeTab == 2 && isInAdmission == false">
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Академические результаты</p>
                    </div>
                    <div class="result__inputs">
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="school">Школа</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="text" id="school" v-model="results.school" @change="mainIsChanged()">
                                    <div class="line1"></div>
                                    <p v-if="results.status_school == 0 || results.status_school == 2" @click="confirmResult('status_school')">
                                        Подтвердить</p>
                                    <p v-if="results.status_school == 1" @click="declineResult('status_school')">Подтверждено</p>
                                </div>
                            </div>
                        </div>

                        <!-- Углубленное изучение -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="studyField">Углубленное изучение</label>
                                <div class="newcustomInputWhite__in">
                                    <customSelect v-model="results.depth_study" :list="depthSudy" class="customSelectClass" isBorder @change="mainIsChanged()"/>
                                    <div class="line1"></div>
                                    <p v-if="results.status_depth_study == 0 || results.status_depth_study == 2"
                                        @click="confirmResult('status_depth_study')">Подтвердить</p>
                                    <p v-if="results.status_depth_study == 1" @dblclick="declineResult('status_depth_study')">Подтверждено</p>
                                </div>
                            </div>
                        </div>

                        <!-- Средний балл аттестата -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="averageScore">Средний балл аттестата</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="text" id="averageScore" v-model="results.gpa_school" @change="mainIsChanged()">
                                    <div class="line1"></div>
                                    <p v-if="results.status_gpa_school == 0 || results.status_gpa_school == 2"
                                        @click="confirmResult('status_gpa_school')">Подтвердить</p>
                                    <p v-if="results.status_gpa_school == 1" @dblclick="declineResult('status_gpa_school')">Подтверждено</p>
                                </div>
                            </div>
                        </div>
                        <!-- Placement Test Level -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="placementLevel">Placement Test Level</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="text" id="placementLevel" v-model="results.placement_test_level" @change="mainIsChanged()">
                                    <div class="line1"></div>
                                    <p
                                        @click="confirmResult('status_placement_test_level')" v-if="results.status_placement_test_level == 0 || results.status_placement_test_level == 2">Подтвердить</p>
                                    <p v-if="results.status_placement_test_level == 1" @dblclick="declineResult('status_placement_test_level')">Подтверждено</p>
                                </div>
                            </div>
                        </div>

                        <!-- Placement Test Date -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="placementDate">Placement Test Date</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="date"  value=""  id="placementDate" v-model="results.placement_test_date" @change="mainIsChanged()">
                                    <div class="line1"></div>
                                    <p v-if="results.status_placement_test_date == 0 || results.status_placement_test_date == 2"
                                        @click="confirmResult('status_placement_test_date')">Подтвердить</p>
                                    <p v-if="results.status_placement_test_date == 1" @dblclick="declineResult('status_placement_test_date')">Подтверждено</p>
                                </div>
                            </div>
                        </div>

                        <!-- IELTS/TOEFL -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="ielts">IELTS/TOEFL</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="text" id="ielts" v-model="results.ielts" @change="mainIsChanged()">
                                    <div class="line1"></div>
                                    <p v-if="results.status_ielts == 0 || results.status_ielts == 2" @click="confirmResult('status_ielts')">
                                        Подтвердить</p>
                                    <p v-if="results.status_ielts == 1" @dblclick="declineResult('status_ielts')">Подтверждено</p>
                                </div>
                            </div>
                        </div>

                        <!-- IELTS/TOEFL Date -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="ieltsDate">IELTS/TOEFL Date</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="date"  value=""  id="ieltsDate" v-model="results.ielts_date" @change="mainIsChanged()">
                                    <div class="line1"></div>
                                    <p v-if="results.status_ielts_date == 0 || results.status_ielts_date == 2"
                                        @click="confirmResult('status_ielts_date')">Подтвердить</p>
                                    <p v-if="results.status_ielts_date == 1" @dblclick="declineResult('status_ielts_date')">Подтверждено</p>
                                </div>
                            </div>
                        </div>
                        <!-- SAT -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="sat">SAT</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="text" id="sat" v-model="results.sat" @change="mainIsChanged()">
                                    <div class="line1"></div>
                                    <p v-if="results.status_sat == 0 || results.status_sat == 2" @click="confirmResult('status_sat')">Подтвердить
                                    </p>
                                    <p v-if="results.status_sat == 1" @dblclick="declineResult('status_sat')">Подтверждено</p>
                                </div>
                            </div>
                            </div>
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="sat">SAT Date</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="date"  value=""  id="sat" v-model="results.sat_date" @change="mainIsChanged()">
                                        <div class="line1"></div>
                                        <p v-if="results.status_sat_date == 0 || results.status_sat_date == 2" @click="confirmResult('status_sat')">Подтвердить
                                        </p>
                                        <p v-if="results.status_sat_date == 1" @dblclick="declineResult('status_sat')">Подтверждено</p>
                                    </div>
                                </div>
                            </div>
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="bachelorGPA">GPA Бакалавриат</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="text" id="bachelorGPA" v-model="results.gpa_bachelor" @change="mainIsChanged()">
                                        <div class="line1"></div>
                                        <p v-if="results.status_gpa_bachelor == 0 || results.status_gpa_bachelor == 2"
                                            @click="confirmResult('status_gpa_bachelor')">Подтвердить</p>
                                        <p v-if="results.status_gpa_bachelor == 1" @dblclick="declineResult('status_gpa_bachelor')">Подтверждено</p>
                                    </div>
                                </div>
                            </div>

                            <!-- GPA Магистратура -->
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="masterGPA">GPA Магистратура</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="text" id="masterGPA" v-model="results.gpa_master" @change="mainIsChanged()">
                                        <div class="line1"></div>
                                        <p v-if="results.status_gpa_master == 0 || results.status_gpa_master == 2"
                                            @click="confirmResult('status_gpa_master')">Подтвердить</p>
                                        <p v-if="results.status_gpa_master == 1" @dblclick="declineResult('status_gpa_master')">Подтверждено</p>
                                    </div>
                                </div>
                            </div>

                            <!-- KazTest/Qazresmi -->
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="kazTest">KazTest/Qazresmi</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="text" id="kazTest" v-model="results.kaz_test" @change="mainIsChanged()">
                                        <div class="line1"></div>
                                        <p v-if="results.status_kaz_test == 0 || results.status_kaz_test == 2"
                                            @click="confirmResult('status_kaz_test')">Подтвердить</p>
                                        <p v-if="results.status_kaz_test == 1" @dblclick="declineResult('status_kaz_test')">Подтверждено</p>
                                    </div>
                                </div>
                            </div>

                            <!-- KazTest/Qazresmi Date -->
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="kazTestDate">KazTest/Qazresmi Date</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="date"  value=""  id="kazTestDate" v-model="results.kaz_test_date" @change="mainIsChanged()">
                                        <div class="line1"></div>
                                        <p v-if="results.status_kaz_test_date == 0 || results.status_kaz_test_date == 2"
                                            @click="confirmResult('status_kaz_test_date')">Подтвердить</p>
                                        <p v-if="results.status_kaz_test_date == 1" @dblclick="declineResult('status_kaz_test_date')">Подтверждено</p>
                                    </div>
                                </div>
                            </div>
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="gre">GRE/GMAT</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="text" id="gre" v-model="results.gre" @change="mainIsChanged()">
                                        <div class="line1"></div>
                                        <p v-if="results.status_gre == 0 || results.status_gre == 2" @click="confirmResult('status_gre')">
                                            Подтвердить</p>
                                        <p v-if="results.status_gre == 1" @dblclick="declineResult('status_gre')">Подтверждено</p>
                                    </div>
                                </div>
                            </div>

                            <!-- GRE/GMAT Date -->
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="greDate">GRE/GMAT Date</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="date"  value=""  id="greDate" v-model="results.gre_date" @change="mainIsChanged()">
                                        <div class="line1"></div>
                                        <p v-if="results.status_gre_date == 0 || results.status_gre_date == 2"
                                            @click="confirmResult('status_gre_date')">Подтвердить</p>
                                        <p v-if="results.status_gre_date == 1" @dblclick="declineResult('status_gre_date')">Подтверждено</p>
                                    </div>
                                </div>
                        </div>
                        
                        <div class="result__inputs__in" style="width: 100%;">
                                <div class="customInputWhite" style="width: 100%;">
                                    <label >Другое</label>
                                    <textarea placeholder="Дополнительная информация" v-model="results.other" @change="mainIsChanged()"></textarea>
                                </div>
                            </div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save" @click="newAcademResults()">Применить</button>
                        <button class="main__buttons__delete" @click="reload()">Сбросить</button>
                    </div>
                </div>
                <div class="inAdmission" v-if="isInAdmission">
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Статус ВУЗа</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInputWhite">
                            <label>Поступление</label>
                            <customSelect :list="stips" v-model="statusDate.package_id" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Приоритет</label>
                            <customSelect :list="priority" v-model="statusDate.priority" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Статус заявки</label>
                            <customSelect :list="statusses" v-model="statusDate.status" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Вид оффера</label>
                            <customSelect :list="offers" v-model="statusDate.type_offer" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Deadline 1</label>
                            <input type="date"  value=""  v-model="statusDate.first_date" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Deadline 2</label>
                            <input type="date"  value=""  v-model="statusDate.second_date" @change="mainIsChanged()"/>
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Сроки поступления и услуг</p>
                        <div class="customTabTitle__right">
                            <div class="customTabTitle__right__add" @click="newDeadline()">
                                <img src="@/assets/icons/plus.svg" />
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__inputs" v-for="item in deadlines" :key="item">
                        <div class="customInputWhite">
                            <label>Вид элемента</label>
                            <customSelect :list="documentTypes" v-model="item.document_type" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite" style="width: 20%;">
                            <label>Дата начала</label>
                            <input type="date"  value=""  v-model="item.date_from" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite" style="width: 20%;">
                            <label>Дата завершения</label>
                            <input type="date"  value=""  v-model="item.date_to" @change="mainIsChanged()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Статус услуги</label>
                            <customSelect :list="courseStatusses" v-model="item.status" @change="mainIsChanged()"/>
                        </div>
                        <div class="main__inputs__delete" @click="deleteDocs(item.document_type)">
                            <img src="@/assets/icons/delete.svg" />
                        </div>
                        <div class="line"></div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save" @click="sendDeadline()">Применить</button>
                        <button class="main__buttons__delete" @click="reload()">Сбросить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import customSelect from "@/components/customSelect.vue";
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import NewCardComponent from "@/components/newCardComponent.vue";
import AddProduct from "@/components/newPlatform/addProduct.vue";
import AddFile from "@/components/newPlatform/addFile.vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import axios from "axios";
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        NewCardComponent,
        AddProduct,
        AddFile,
        customSelect
    },
    data() {
        return {
            depthSudy: [
                {
                    id: 0,
                    title: 'Физмат'
                },
                {
                    id: 1,
                    title: 'Гуманитарий'
                },
            ],
            intSch: [
                {
                    title: 'Прием документов',
                    date: '',
                    id: '',
                    user_id: this.$route.params.id
                },
                {
                    title: 'Конкурсный отбор',
                    date: '',
                    id: '',
                    user_id:  this.$route.params.id
                },
            ],
            contStatusId: null,
            selectedBuy: '',
            clientStatusId: null,
            isMainChanged: false,
            supplyId: null,
            newRiskFromSelect: '',
            supplyList: [
                {
                    id: 0,
                    title: 'Первая'
                },
                {
                    id: 1,
                    title: 'Переподача'
                }
            ],
            contractStatusses: [
                {
                    id: 1,
                    title: 'Действует'
                },
                {
                    id: 2,
                    title: 'Истек'
                },
            ],
            clientStatusses: [
                {
                    id: 0,
                    title: 'Активный'
                },
                {
                    id: 1,
                    title: 'Неактивный'
                },
                {
                    id: 2,
                    title: 'Проблемный'
                },
            ],
            InternationalSch: {
                title1: '',
                title2: '',
                date1: '',
                date2: ''
            },
            statusses: [
                {
                    id: 0,
                    title: 'Принято'
                },
                {
                    id: 1,
                    title: 'В процессе'
                },
                {
                    id: 2,
                    title: 'Отклонено'
                },
            ],
            tabs: [
                {
                    name: 'Главное'
                },
                {
                    name: 'Общее'
                },
                {
                    name: 'Результаты'
                },
                {
                    name: 'Документы'
                },
                {
                    name: 'Избранное'
                },
                {
                    name: 'Поступление'
                },
            ],
            cities: [
                {
                    title: 'Almaty',
                    id: 0
                },
                {
                    title: 'Astana',
                    id: 1
                },
                {
                    title: 'Shymkent',
                    id: 2
                },
            ],
            genders: [
                {
                    title: 'Мужчина',
                    id: 0
                },
                {
                    title: 'Женщина',
                    id: 1
                },
            ],
            editUser: '',
            stages: [
                {
                    title: '1',
                    id: 1
                },
                {
                    title: '2',
                    id: 2
                },
                {
                    title: '3',
                    id: 3
                },
                {
                    title: '4',
                    id: 4
                },
            ],
            courseStatusses: [
                {
                    id: 2,
                    title: 'Не начато'
                },
                {
                    id: 0,
                    title: 'Завершено'
                },
                {
                    id: 1,
                    title: 'В процессе'
                },
            ],
            offers: [
                {
                    title: 'Нет оффера',
                    id: 0
                },
                {
                    title: 'Conditional',
                    id: 1
                },
                {
                    title: 'Unconditional',
                    id: 2
                },
            ],
            priority: [
                {
                    title: '1',
                    id: 1,
                },
                {
                    title: '2',
                    id: 2,
                },
                {
                    title: '3',
                    id: 3,
                },
                {
                    title: '4',
                    id: 4,
                },
                {
                    title: '5',
                    id: 5,
                },
                {
                    title: '6',
                    id: 6,
                },
                {
                    title: '7',
                    id: 7,
                },
                {
                    title: '8',
                    id: 8,
                },
            ],
            selectedStage: '',
            contractDate: null,
            contractNumber: null,
            countDays: null,
            activeTab: 0,
            isInAdmission: false,
            programId: '',
            openAdd: false,
            openRedactor: false,
            openFile: false,
            editUser: '',
            stips: [],
            steps: [],
            countries: [],
            cities: [
            ],
            stepId: null,
            statusDate: {
                priority: '',
                status: '',
                type_offer: '',
                first_date: '',
                second_date: ''
            },
            stipId: '',
            risks: [],
            documents: '',
            newRisk: '',
            keyCources: '',
            deadlines: [],
            keyTests: '',
            progress: '',
            userBuys: [],
            results: false,
            favourites: false,
            documentTypes: [],
            individulasFromBack: [],
            newRisks: [],
            risksToSend: [],
            isOldStatus: false,
            isChanged: false,
            individuals: [
                {
                    user_id: this.$route.params.id,
                    hyperlink: ''
                },
                {
                    user_id: this.$route.params.id,
                    hyperlink: ''
                }
            ],
            selectedId: 0,
            risksToDelete: [],
            admissionPrograms: false,
            courcesForDelete: [],
            testsForDelete: [],
            documents: [
            ],
        };
    },

    methods: {
        mainIsChanged(){
            this.isMainChanged = true;
        },
        newCoundOfDays(){
            if(this.contStatusId == 2){
                this.countDays = 0;
            }
        },  
        validateCyrillicSurname(event) {
            this.editUser.surname = event.target.value.replace(/[^А-Яа-яЁё]/g, '');
        },
        validateCyrillicName(event) {
            this.editUser.name = event.target.value.replace(/[^А-Яа-яЁё]/g, '');
        },
        courseMethod(item){
            item = item.replace(' ', '</br>');

            console.log(item);
            
            return item;
        },
        newIndex(index) {
            if (this.isMainChanged) {
                if (!confirm("Данные не сохранятся, вы уверены что хотите перейти на новую страницу?")) {
                    return;
                }
            }
            this.activeTab = index;
            this.isInAdmission = false;
            this.isMainChanged = false;
            this.$router.push({ query: { tab: index } });
            if(this.activeTab == 0){
                this.getSteps();
                this.getUser();
                this.getCources();
                this.getTests();
                this.getInt();
                this.getRisks();
            }
            if(this.activeTab == 1){
                this.getCountries();
                this.getBuys();
                this.getUser();
            }
            if(this.activeTab == 2){
                this.getUser()
            }
            if(this.activeTab == 3){
                this.getUser()
            }
            if(this.activeTab == 4){
                this.getFavourites()
            }
            if(this.activeTab == 5){
                this.getAdmissions()
            }
        },
        newProduct(){
            this.getBuys()
        },
        handleChange() {
            this.isChanged = true;
        },
        deleteBuy(id) {
            axios.delete(`/platform/curator/users/purchase/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    toast.success('Успешно удалено!')
                    this.getBuys()
                })
                .catch(err => {
                })
        },
        deleteDocs(id) {
            axios.delete(`/platform/curator/delete-user-documents/${this.programId}?document_type=${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    toast.success('Успешно удалено!')
                })
                .catch(err => {
                })
        },
        reload(){
            if (confirm("Изменения не сохранятся, вы точно хотите сбросить?")) {
                window.location.reload()
            }
        },
        changeProgramm(id, item) {
            this.isInAdmission = true
            this.programId = id
            if (item.admission_status) {
                this.statusDate = item.admission_status
                this.statusDate.first_date = this.formatDate(this.statusDate.first_date)
                this.statusDate.second_date = this.formatDate(this.statusDate.second_date)
                this.statusDate.package_id = item.admission_status?.package.id
            }
            if(item?.admission_status?.status_text){
                this.isOldStatus = true
            }
            if (item.docs) {
                this.deadlines = item.docs.map(doc => {
                // Заменяем поле document_type_id на document_type
                const { document_type_id, date_from, date_to, ...rest } = doc;
                
                return {
                    ...rest,
                    document_type: document_type_id, // заменяем на document_type
                    date_from: this.formatDate(date_from), // форматируем date_from
                    date_to: this.formatDate(date_to) // форматируем date_to
                };
            });
            }
        },
        newDeadline() {
            if (Array.isArray(this.deadlines)) {
                this.deadlines.push({
                    user_admission_id: this.programId,
                    document_type: '',
                    status: 0,
                    date_from: '',
                    date_to: ''
                });
            } else {
                console.error('deadlines is not an array:', this.deadlines);
            }
        },
        newRisk1() {
            if (this.newRisk != '') {
                const risk = {
                    user_id: this.$route.params.id,
                    title: this.newRisk
                }
                // Проверяем, есть ли уже такой риск в массиве risksToSend
                const isDuplicate = this.risksToSend.some(r => r.title === risk.title);
                const isDuplicateFromMain = this.risks.some(r => r.title === risk.title);

                if (isDuplicate || isDuplicateFromMain) {
                    toast.error('Ошибка: элемент с таким же риском уже существует!');
                } else {
                    this.risksToSend.push(risk);
                }
                
            }
            else {
                toast.error('Пусто')
            }
        },
        newRisk2() {
            if (this.newRiskFromSelect !== '') {
                const risk = {
                    user_id: this.$route.params.id,
                    title: this.newRiskFromSelect
                };

                // Проверяем, есть ли уже такой риск в массиве risksToSend
                const isDuplicate = this.risksToSend.some(r => r.title === risk.title);
                const isDuplicateFromMain = this.risks.some(r => r.title === risk.title);

                if (isDuplicate || isDuplicateFromMain) {
                    toast.error('Ошибка: элемент с таким же риском уже существует!');
                } else {
                    this.risksToSend.push(risk);
                }
            }
            else {
                toast.error('Пусто')
            }
        },
        async cityList() {
            await axios.get(`/platform/cities?country_id=${this.editUser.country_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.cities = res.data.data
                })
                .catch(err => {
                })
        },
        formatDate(date) {
            const [day, month, year] = date.split('-');
            return `${year}-${month}-${day}`;
        },
        newCources() {
            this.keyCources.push({
                title: '',
                deadline: null,
                status: '',
                user_id: this.$route.params.id
            });
        },
        newTests() {
            this.keyTests.push({
                title: '',
                deadline: null,
                status: '',
                user_id: this.$route.params.id
            });
        },
        async sendDeadline() {
            try {
                const formattedData = this.deadlines.map(item => ({
                    ...item,
                    date_from: item.date_from,
                    date_to: item.date_to,
                }));

                const data1 = { data: formattedData };

                const headers = {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                };

                const requests = [];

                // Отправка документов
                if (formattedData.length > 0) {
                    requests.push(
                        axios.post('/platform/curator/store-user-documents', data1, { headers })
                            .then(response => {
                                if (response.status !== 201) {
                                    toast.success('Документ успешно добавлен!');
                                }
                            })
                    );
                }

                // Обновление статуса
                const updatedStatusDate = {
                    ...this.statusDate,
                    user_admission_id: this.programId
                };

                if (this.isOldStatus) {
                    requests.push(
                        axios.put(`/platform/curator/users/admissions/status/${this.statusDate.id}`, updatedStatusDate, { headers })
                            .then(response => {
                                if (response.status === 201) {
                                    toast.success('Статус успешно добавлен');
                                }
                            })
                    );
                } else {
                    requests.push(
                        axios.post('/platform/curator/users/admissions/status', updatedStatusDate, { headers })
                    );
                }

                // Выполняем все запросы и ждем их завершения
                await Promise.all(requests);
                // Показываем сообщение об успешном сохранении только если все запросы успешно выполнены
                toast.success('Успешно сохранено. Данные, которые вы не заполняли, останутся без изменений.');
            } catch (error) {
                if (error.config.url.includes('/platform/curator/store-user-documents')) {
                    toast.error('Заполните все поля в виджете - "Сроки поступления и услуг"');
                }
                else{
                    toast.error(error.response?.data?.message || 'Произошла ошибка');
                }
            }
        },
        sendMain() {
            const schData1 = { user_id: this.$route.params.id, title: this.intSch[0].title, date: this.intSch[0].date, id: this.intSch[0].id };
            const schData2 = { user_id: this.$route.params.id, title: this.intSch[1].title, date: this.intSch[1].date, id: this.intSch[1].id };

            const formattedData = this.keyCources.map(item => ({ ...item, deadline: item.deadline }));
            const formattedData1 = this.keyTests.map(item => ({ ...item, deadline: item.deadline }));

            const newSchData = {
                data: [{ schData1 }, { schData2 }].map(item => Object.values(item)[0])
            };

            const agree = {
                user_id: this.$route.params.id,
                study_degree_id: this.stepId,
                send_date: this.contractDate,
                contract_number: this.contractNumber,
                supply: this.supplyId,
                count_of_days: this.countDays,
                left_days: this.countDays,
                status: this.contStatusId,
                user_status: this.clientStatusId
            };

            const indiv = {
                data: this.individuals.map((individual, index) => {
                    if (individual.hyperlink) {
                        individual.link = individual.hyperlink;
                        delete individual.hyperlink;
                    } else {
                        individual.link = this.individulasFromBack[index]?.hyperlink;
                    }
                    if (!individual.user_id) {
                        individual.user_id = this.$route.params.id;
                    }
                    if (this.individulasFromBack[index]) {
                        individual.old_link = this.individulasFromBack[index].hyperlink;
                    }
                    return individual;
                })
            };
            const invalidLink = indiv.data.find(ind => ind.link && !/^https?:\/\//.test(ind.link));
            const headers = {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
            };

            // Отправка risks отдельно
            if (this.risksToSend.length > 0) {
                axios.post('/platform/curator/users-risks', { data: this.risksToSend }, headers)
                    .then(() => {
                        this.risksToSend = [];
                    })
                    .catch(err => {
                        console.error(err);
                        toast.error('Ошибка при сохранении рисков');
                    });
            }

            // Promise.all для всех запросов сразу
            if (invalidLink) {
                toast.error('Ошибка: все ссылки должны начинаться с http:// или https://');
            } else {
                toast.success('Успешно сохранено. Данные, которые вы не заполняли, останутся без изменений.');
                Promise.all([
                    axios.post('/platform/curator/store-user-international-scholarship-widget', newSchData, headers),
                    axios.post('/platform/curator/users/general-english', { data: formattedData }, headers),
                    axios.post('/platform/curator/store-user-document-widget', agree, headers),
                    axios.post('/platform/curator/users/tests', { data: formattedData1 }, headers),
                    axios.post(`/platform/curator/users-individual-plan`, indiv, headers)
                ])
                    .then(() => {
                        this.getSteps();
                        this.getUser();
                        this.getCources();
                        this.getTests();
                        this.getInt();
                        this.getRisks();
                    })
                    .catch(err => {
                        if(formattedData.length > 0){
                            if (err.config.url.includes('/platform/curator/users/general-english')) {
                                toast.error('Заполните все поля в виджете - "GENERAL ENGLISH"');
                            }
                        }
                        if(formattedData1.length > 0){
                            if (err.config.url.includes('/platform/curator/users/tests')) {
                                toast.error('Заполните все поля в виджете - "Тесты"');
                            }
                        }
                    });
            }
            // Удаление рисков
            if (this.risksToDelete.length > 0) {
                Promise.all(
                    this.risksToDelete.map(id =>
                        axios.delete(`/platform/curator/users-risks/${id}`, headers)
                    )
                ).catch(err => {
                    console.error(err);
                    toast.error('Ошибка при удалении некоторых рисков');
                });
            }
            // Удаление тестов
            if (this.testsForDelete.length > 0) {
                Promise.all(
                    this.testsForDelete.map(id =>
                        axios.delete(`/platform/curator/users-tests?id=${id}`, headers)
                    )
                ).then(() => {
                    this.testsForDelete = [];
                }).catch(err => {
                    console.error(err);
                });
            }
            // Удаление курсов
            if (this.courcesForDelete.length > 0) {
                Promise.all(
                    this.courcesForDelete.map(id =>
                        axios.delete(`/platform/curator/users-general-english?id=${id}`, headers)
                    )
                ).then(() => {
                    this.courcesForDelete = [];
                }).catch(err => {
                    console.error(err);
                });
            }
        },
        sendSuper() {
            toast.success("Отправлено");
        },
        deleteGeneral(id) {
            this.keyCources = this.keyCources.filter(item => item.id !== id);
            this.courcesForDelete.push(id)
            this.isMainChanged = true;
            // if (id) {
            //     axios.delete(`/platform/curator/users-general-english?id=${id}`, {
            //         headers: {
            //             Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            //         }
            //     })
            //         .then(res => {
            //             toast.success('Успешно удалено!')
            //             this.getCources()
            //         })
            //         .catch(err => {
            //         })
            // }
            // else {
            //     this.keyCources.pop();
            // }
        },
        deleteRisks(id){
            this.risks = this.risks.filter(risk => risk.id !== id);
            this.risksToDelete.push(id)
            this.isMainChanged = true;
        },
        deleteNewRisks(id){
            this.risksToSend = this.risksToSend.filter(risk => risk.id !== id);
        },
        deleteTest(id) {
            this.keyTests = this.keyTests.filter(item => item.id !== id);
            this.testsForDelete.push(id)
            // if (id) {
            //     axios.delete(`/platform/curator/users-tests?id=${id}`, {
            //         headers: {
            //             Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            //         }
            //     })
            //         .then(res => {
            //             toast.success('Успешно удалено!')
            //             this.getTests()
            //         })
            //         .catch(err => {
            //         })
            // }
            // else {
            //     this.keyTests.pop();
            // }
        },
        confirmGeneral(item) {
            const data = {
                type: item
            }
            axios.put(`/platform/curator/users/status/accept/${this.$route.params.id}`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 202) {
                        toast.success('Успешно подтверждено')
                        this.getUser();
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    toast.error(response.response.data.errors);
                });
        },
        confirmResult(item) {
            const data = {
                type: item
            }
            axios.put(`/platform/curator/users/academic-result/accept/4`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 202) {
                        toast.success('Успешно подтверждено')
                        this.getUser()
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    toast.error(response.response.data.errors);
                });
        },
        declineResult(item) {
            const data = {
                type: item
            }
            axios.put(`/platform/curator/users/academic-result/decline/4`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 202) {

                        toast.success('Успешно обновлено')
                        this.getUser()
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    toast.error(response.response.data.errors);
                });
                
        },
        declineGeneral(item) {
            const data = {
                type: item
            }
            axios.put(`/platform/curator/users/status/decline/${this.$route.params.id}`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 202) {
                        toast.success('Успешно обновлено')
                        this.getUser()
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    toast.error(response.response.data.errors);
                });
        },
        newAcademResults(){
            axios.put("/platform/curator/users/academic-result/update/4", this.results, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 202) {
                        toast.success('Успешно обновлено')
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    toast.error(response.response.data.errors);
                });
        },
        sendData() {
            const processData = {
                process: this.progress,
                stage: this.selectedStage,
                user_id: this.$route.params.id
            }
            if (this.editUser.birthday) {
                this.editUser.birthday = (this.editUser.birthday); // Форматируем дату
            }
            this.editUser.email = ''
            this.editUser.image = ''
            axios.post('/platform/curator/users-stage-process', processData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            }).then((response) => {
                if (response.status === 201) {
                } else {
                    console.log(response.data);
                }
            })
                .catch((response) => {
                    console.log(response.response.data.errors);
                });
            axios.put(`/platform/curator/update/user/${this.$route.params.id}`, this.editUser, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    console.log(response.response.data.errors);
                });
            toast.success('Успешно сохранено. Данные, которые вы не заполняли, останутся без изменений.')
            this.getUser()
            this.isMainChanged = false
            
        },
        deleteDocument(item){
            axios.delete(`/platform/curator/delete-user-basic-documents?id=${item}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then(res => {
                        toast.success('Успешно удалено!')
                        this.getUser()
                    })
                    .catch(err => {
                    })
        },
        async getUser(){
            await axios.get(`/platform/curator/users/${this.$route.params.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    if(res.data.data.user){
                        this.editUser = res.data.data.user
                        if(this.editUser.birthday){
                            this.editUser.birthday = this.formatDate(this.editUser.birthday);
                        }
                    }
                    if(res.data.data.academicResult){
                        this.results = res.data.data.academicResult
                        this.results.gre_date = this.formatDate(this.results?.gre_date)
                        this.results.ielts_date = this.formatDate(this.results?.ielts_date)
                        this.results.kaz_test_date = this.formatDate(this.results?.kaz_test_date)
                        this.results.placement_test_date = this.formatDate(this.results?.placement_test_date)
                        this.results.sat_date = this.formatDate(this.results?.sat_date)
                    }
                    if( res.data.data.private_documents){
                        this.documents = res.data.data.private_documents;
                    }
                    if (res.data.data.individualPlans.length > 0) {
                        this.individulasFromBack = res.data.data.individualPlans
                        if(res.data.data.individualPlans[0].hyperlink){
                            this.individuals[0].hyperlink = res.data.data.individualPlans[0].hyperlink
                        }
                        if(res.data.data.individualPlans[1].hyperlink){
                            this.individuals[1].hyperlink = res.data.data.individualPlans[1].hyperlink
                        }
                    }
                    if (res.data.data.user.progress) {
                        this.progress = res.data.data.user.progress
                    }
                    if (res.data.data.user.stage) {
                        this.selectedStage = res.data.data.user.stage
                    }
                    if (res.data.data.risks) {
                        this.risks = res.data.data.risks
                    }
                    if (res.data.data.documentWidget) {
                        const newData = res.data.data.documentWidget;
                        this.supplyId = newData.supply;
                        this.stepId = newData.study_degree.id;
                        this.contStatusId = newData.status;
                        this.contractDate = this.formatDate(newData.send_date);
                        this.countDays = newData.count_of_days;
                        this.contractNumber = newData.contract_number
                        this.clientStatusId = newData.user_status
                    }
                    this.cityList()
                    if(this.editUser.city_id.id){
                        this.editUser.city_id = this.editUser.city_id.id
                    }
                })
                .catch(err => {
                })
        },
        async getSteps(){
            await axios.get(`/platform/study-degrees`, {
            })
                .then(res => {
                    this.steps = res.data.data
                })
                .catch(err => {
                })
        },
        async getCountries(){
            await axios.get(`/platform/countries`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.countries = res.data.data
            })
            .catch(err => {
            })
        },
        async getBuys() {
            await axios.get(`/platform/curator/user-purchase-history/${this.$route.params.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.userBuys = res.data.data
                })
                .catch(err => {
                })
        },
        async getCources() {
            await axios.get(`/platform/curator/users-general-english?user_id=${this.$route.params.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.keyCources = res.data.data.map(item => ({
                    ...item,
                    deadline: this.formatDate(item.deadline)
                }));
            })
            .catch(err => {
                console.error(err);
            });
        },
        async getAdmissions() {
            await axios.get(`/platform/curator/users/admissions/${this.$route.params.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.admissionPrograms = res.data.data
                })
                .catch(err => {
                })
                
            await axios.get(`/platform/document-types`)
                .then(res => {
                    this.documentTypes = res.data.data
                })
                .catch(err => {
                })
                await axios.get('/platform/packages')
                .then(res => {
                    this.stips = res.data.data;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        async getTests() {
            await axios.get(`/platform/curator/users-tests?user_id=${this.$route.params.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.keyTests = res.data.data.map(item => ({
                    ...item,
                    deadline: this.formatDate(item.deadline)
                }));
                })
                .catch(err => {
                })
        },
        async getFavourites() {
            await axios.get(`/platform/curator/users/favourites/${this.$route.params.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.favourites = res.data.universityPrograms
                })
                .catch(err => {
                })
        },
        async getRisks() {
            await axios.get(`/platform/default-risks`)
                .then(res => {
                    this.newRisks = res.data.data
                })
                .catch(err => {
                })
        },
        async getInt() {
            await axios.get(`/platform/curator/user-international-scholarship-widget/${this.$route.params.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    if(res.data.data.length > 0){
                        this.intSch = res.data.data
                        this.intSch = res.data.data.map(item => ({
                            ...item,
                            date: this.formatDate(item.date)
                    }));
                    }
                })
                .catch(err => {
                })
        }
    },
    mounted() {
        const tabFromQuery = this.$route.query.tab;
        if(this.$router.currentRoute.value.query.isFav){
            this.activeTab = 4
            this.newIndex(4);
        }
        if (tabFromQuery) {
            this.activeTab = Number(tabFromQuery);
            this.newIndex(this.activeTab);
        }
        else{
            this.newIndex(0)
        }
        window.addEventListener("beforeunload", (event) => {
            if (this.isChanged) {
                event.preventDefault();
                event.returnValue = ""; // Пустая строка активирует стандартное предупреждение
            }
        });
    },
    beforeDestroy() {
        // Удаляем слушатель при уничтожении компонента
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    },
};
</script>
<style lang="scss" scoped>
.saveButton{
    transition: all 0.5s ease;
    &:hover{
        background: rgba(0, 0, 0, 0.136);
    }
}
.newcustomInputWhite {
    position: relative;
    display: inline-block;
    width: 32%;
    cursor: pointer;

    label {
        position: absolute;
        top: -8px;
        left: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        background-color: #ffffff;
        /* Цвет фона, чтобы совпадал с фоном */
        padding: 0 10px;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
        color: #000000;
    }

    &__in {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
        border: 1px solid #e0e0e0;
        /* Цвет рамки */
        border-radius: 8px;
        font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        background-color: inherit;
        color: #000;
        /* Цвет текста */
        outline: none;
        align-items: center;

        input,
        select, .customSelectClass {
            width: 50%;
            border: none;
        }
    }
}

.line1 {
    height: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    border-left: 1px solid #0000001A;
}

.activeTab {
    border-bottom: 1px solid red !important;
    color: black !important;
    font-weight: 600 !important;
}

.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}

.line2 {
    border-left: 1px solid #0000001A;
    height: 100%;
}

.bold {
    font-weight: 600;
}

.grey {
    color: #0000008A;
}

select {
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}

a {
    color: inherit;
}

.userCard {
    display: flex;
    flex-direction: column;
    gap: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
    padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    background-color: white;
    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

    &__customTop {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__left {
            display: flex;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            align-items: center;

            &__right {
                display: flex;
                flex-direction: column;
                gap: 5px;

                &__name {
                    font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
                }
            }

            img {
                width: 100px;
                aspect-ratio: 1;
            }
        }

        &__export {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            color: #0000008A;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            border: 1px solid #0000001A;
            border-radius: 10px;
            cursor: pointer;

            img {
                width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            }
        }
    }

    &__top {
        display: flex;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        align-items: center;

        &__right {
            display: flex;
            flex-direction: column;
            gap: 5px;

            &__name {
                font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
            }
        }

        img {
            width: 100px;
            aspect-ratio: 1;
        }
    }

    &__tabs {
        display: flex;
        align-items: flex-end;

        &__in {
            .line2 {
                border-bottom: 1px solid #0000001A;
                width: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
            }

            &__name {
                padding-bottom: 5px;
                border-bottom: 1px solid #0000001A;
                color: #0000004D;
            }

            cursor: pointer;
            display: flex;
            align-items: flex-end;
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

        &__inputs,
        &__inputs1 {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 2%;
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

            .customInputWhite {
                width: 23.5%;
            }

            &__delete {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                aspect-ratio: 1;
                border: 1px solid #FD2D2A;
                border-radius: 10px;
                transition: all 0.5s ease;

                &:hover {
                    background-color: #FD2D2A;

                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(11%) hue-rotate(189deg) brightness(103%) contrast(105%);
                    }
                }

                img {
                    width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                }
            }

            &__select {
                width: 15%;

                select {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &__inputs1 {
            gap: 2%;
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

            .customInputWhite {
                width: 35%;
            }
        }

        &__risk {
            display: flex;
            gap: 2%;

            input,
            button {
                width: 23.5%;
                height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                border: 1px solid #e0e0e0;
                /* Цвет рамки */
                border-radius: 8px;
                font-style: italic;;
            }

            button {
                border: 2px solid #11415D;
                font-style: normal;
                padding: 0px;
                background-color: inherit;
                color: #11415D;
                transition: all 0.5 ease;
                &:hover{
                    background-color: #11415d76;
                }
            }
        }

        &__risks {
            display: flex;
            flex-wrap: wrap;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            align-items: stretch;

            &__in {
                display: flex;
                gap: 10px;
                padding: 10px;
                align-items: center;
                color: white;
                background-color: #FD2D2A;
                cursor: pointer;
                border-radius: 10px;

                img {
                    width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                    height: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                }
            }
        }

        &__buttons {
            display: flex;
            gap: 2%;

            button {
                width: 23.5%;
                border: none;
                height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                border-radius: 10px;
            }

            &__save {
                color: white;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                background-color: #11415D;
                border: 1px solid #11415D;
                transition: all 0.5s ease;

                &:hover {
                    color: white;
                    background-color: #2076a7;
                }
            }

            &__delete {
                background-color: inherit;
                border: 1px solid #0000001A !important;
                color: #0000008A;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                transition: all 0.5s ease;

                &:hover {
                    color: white;
                    background-color: #11415D;
                    border: 1px solid #11415D;
                }
            }
        }
    }

    .general {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

        .customInputWhite {
            width: 32%;
        }

        &__inputs {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        }

        &__buys {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 2%;
            row-gap: 20px;
            height: fit-content;
            &__in,
            &__plus {
                position: relative;
                width: 23.5%;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                justify-content: center;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                border: 1px solid #0000001A;
                &__edit {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    display: flex;
                    gap: 10px;
                    padding: 10px;
                    background-color: #F8F8F8;
                    border-radius: 8px;
                    align-items: center;

                    .line1 {
                        height: 10px;
                    }

                    img {
                        width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px) !important;
                        height: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px) !important;
                        cursor: pointer;
                    }
                }

                &__title {
                    color: #0000008A;
                }

                &__name {
                    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                    font-weight: bold;
                    text-align: center;
                    flex-grow: 1;
                }

                &__sub {
                    color: #FD2D2A;
                    font-weight: bold;
                }

                img {
                    width: 60px;
                    aspect-ratio: 1;
                    object-fit: cover;
                }
            }

            &__plus {
                cursor: pointer;
                width: 23.5%;
                background-color: #F8F8F8;
                box-shadow: 0px 0px 8px 4px #0000000D inset;

                img {
                    width: 30%;
                    aspect-ratio: 1;
                }
            }
        }

        &__progress {
            display: flex;
            flex-direction: column;
            gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

            &__in {
                display: flex;
                gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

                button {
                    width: 23.5%;
                    border: none;
                    border-radius: 10px;
                    background-color: #14D448;
                    color: white;
                }
            }
        }
    }

    .document {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

        &__title {
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            font-weight: 700;
        }

        &__block {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

            &__in {
                width: 23.5%;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 4px 3px 1px #0000000D;
                background: #FFFFFF;
                border-radius: 10px;
                position: relative;

                &__delete {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    cursor: pointer;
                    width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                }

                &__left {
                    width: 15%;

                    img {
                        width: 100%;
                    }
                }

                &__right {
                    width: 75%;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    &__name {
                        font-weight: 700;
                        flex-grow: 1;
                    }

                    &__undefined {
                        display: flex;
                        justify-content: space-between;
                        padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                        border: 1px solid #0000001A;
                        border-radius: 10px;
                        cursor: pointer;

                        &__left {
                            color: #00D928;
                        }

                        &__right {
                            color: #FF9500;
                        }
                    }

                    &__approved {
                        padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                        background-color: #F8F8F8;
                        width: fit-content;
                        border-radius: 10px;
                        color: #0000008A;
                        cursor: pointer;
                    }

                    &__not {
                        padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                        background-color: #FF9500;
                        width: fit-content;
                        color: white;
                        border-radius: 10px;
                        cursor: pointer;
                    }
                }
            }

            &__plus {
                width: 23.5%;
                padding: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px)0px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 0px 8px 4px #0000000D inset;
                border: 1px solid #0000001A;
                border-radius: 10px;
                cursor: pointer;

                img {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        &__info {
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            align-items: center;

            img {
                width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            }
        }
    }

    .favourite {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

        &__block {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

            &__in {
                width: 49%;
            }
        }
    }

    .admission {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

        &__block {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

            &__in {
                width: 49%;
                cursor: pointer;
            }
        }
    }

    .inAdmission {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    }
}

.result {
    &__inputs {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

        &__in {
            width: 32%;

            .newcustomInputWhite {
                width: 100%;
            }

            .customInputWhite {
                width: 100% !important;
            }
        }
    }
}
</style>