<template>
    <progressBar :percentage="userData.progress"/>
    <extend v-if="openExtend" @closeModal="openExtend = false" :props-item="'gala'"/>
    <ReviewModal v-if="openReview" @closeModal="openReview = false" :document_id="selectedDocumentId" :document_name="selectedDocumentName" @getNewData="getUser()"/>
    <div class="home">
        <div class="home__left">
            <platformTable />
        </div>
        <div class="home__right">
            <div class="home__right__top">
                <div class="home__right__top__tabs">
                    <swiper class="home__right__top__tabs__swiper" @swiper="getSwiperCategory" :breakpoints="{
                        0: {
                            slidesPerView: 'auto'
                        }
                    }" :spaceBetween="50" :speed="500" :lazy="true">
                        <swiper-slide v-for="(tab, index) in tabs" :key="tab"
                            :class="{ customTab: selectedTab === index }" @click="selectTab(index)" ref="tabs">
                            {{ tab.name }}
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="home__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>  
            <div class="main" v-if="selectedTab == 0">
                <div class="main__top">
                    <div class="main__top__left">
                        <div class="status">
                            <div class="customTitle">
                                <p>Статус договора</p>
                                <div class="line"></div>
                            </div>
                            <div class="status__block">
                                <div class="status__block__left">
                                    <CanvasJSChart :options="chartOptions" style="background-color: inherit;"/>  
                                    <p v-if="agree.status == 1">{{ agree.left_days }} дней</p>
                                    <p v-if="agree.status == 2">0 дней</p>
                                </div>
                                <div class="status__block__right">
                                    <p class="status__block__right__title" :class="{customGreen: agree.status == 1}" v-if="agree.status == 1 && agree.left_days > 0">Действует</p>
                                    <p class="status__block__right__title" v-if="agree.status == 2 || agree.left_days == 0">Истек</p>
                                    <p style="color: rgba(0, 0, 0, 0.5411764706);">№ {{ agree.contract_number }} от {{ agree.send_date }}</p>
                                    <button @click="openExtend = true" :class="{customGreenBack: agree.status == 1 && agree.left_days > 0}">Продлить</button>
                                </div>
                            </div>
                        </div>
                        <div class="deadlines">
                            <div class="customTitle">
                                <p>Ключевые сроки</p>
                                <div class="line"></div>
                            </div>
                            <div class="deadlines__block">
                                <div class="deadlines__block__in">
                                    <p class="deadlines__block__in__title">General English</p>
                                    <div class="deadlines__block__in__new">
                                        <div class="deadlines__block__in__new__in"
                                            v-for="(newItem, newIndex) in deadlines.generalEnglishes" :key="newIndex">
                                            <div class="deadlines__block__in__new__in__left">
                                                <div class="deadlines__block__in__new__in__left__mini">
                                                    <div class="deadlines__block__in__new__in__left__mini__in"
                                                        :class="{ backGreen: newItem.status == 0, backRed: newItem.status == 1, customRedBack: (newItem.is_coming && newItem.status != 0) }">

                                                    </div>
                                                </div>
                                                <p
                                                    :class="{ textGreen: newItem.status == 0, textRed: newItem.status == 1, customRed: (newItem.is_coming && newItem.status != 0) }">
                                                    {{ newItem.title }}</p>
                                            </div>
                                            <div class="deadlines__block__in__new__in__right">
                                                <p
                                                    :class="{ textRed: newItem.status == 1, textGreen: newItem.status == 0, customRed: (newItem.is_coming && newItem.status != 0) }">
                                                    /{{ newItem.deadline }} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="deadlines__block__in">
                                    <p class="deadlines__block__in__title">Тесты</p>
                                    <div class="deadlines__block__in__new">
                                        <div class="deadlines__block__in__new__in"
                                            v-for="(newItem, newIndex) in deadlines.tests" :key="newIndex">
                                            <div class="deadlines__block__in__new__in__left">
                                                <div class="deadlines__block__in__new__in__left__mini">
                                                    <div class="deadlines__block__in__new__in__left__mini__in"
                                                        :class="{ backGreen: newItem.status == 0, backRed: newItem.status == 1 }">

                                                    </div>
                                                </div>
                                                <p
                                                    :class="{ textGreen: newItem.status == 0, textRed: newItem.status == 1 }">
                                                    {{ newItem.title }}</p>
                                            </div>
                                            <div class="deadlines__block__in__new__in__right">
                                                <p
                                                    :class="{ textRed: newItem.status == 1, textGreen: newItem.status == 0 }">
                                                    /{{ newItem.deadline }} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main__top__center">
                        <div class="customTitle">
                            <p>Утвержденные программы</p>
                            <div class="line"></div>
                        </div>
                        <div class="main__top__center__block">
                            <div class="main__top__center__block__in" v-for="item in admissions" :key="item">   
                                <div class="main__top__center__block__in__left" :style="{ backgroundImage: `url('${item?.program?.images[0].image}')` }">
                                    <img src="@/assets/icons/crown.svg" v-if="item?.admission_status?.package && item?.admission_status?.package?.id != 6"/>
                                </div>
                                <div class="main__top__center__block__in__right">
                                    <p class="main__top__center__block__in__right__title">
                                        <span v-if="item?.admission_status?.priority">{{ item?.admission_status?.priority }}</span>
                                        CHOICE</p>
                                    <p class="main__top__center__block__in__right__sub">{{item?.program?.programName}}</p>
                                    <p class="main__top__center__block__in__right__deadline">Deadline 1: {{item?.admission_status?.first_date}}</p>
                                    <p class="main__top__center__block__in__right__deadline">Deadline 2: {{item?.admission_status?.second_date}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main__top__right">
                        <div class="grant">
                            <div class="customTitle">
                                <p>Международная стипендия</p>
                                <div class="line">
                                </div>
                            </div>
                            <div class="grant__block">
                                <div class="grant__block__in">
                                    <p class="grant__block__in__name">Прием<br/> документов</p>
                                    <p class="grant__block__in__date">{{intSch[0]?.date}}</p>
                                </div>
                                <div class="grant__block__in" >
                                    <p class="grant__block__in__name">Конкурсный<br/> отбор</p>
                                    <p class="grant__block__in__date">{{intSch[1]?.date}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="risk">
                            <div class="customTitle">
                                <p>Риски</p>
                                <div class="line">
                                </div>
                            </div>
                            <div class="risk__block">
                                <ul class="risk__block__in" v-for="item in risks" :key="item">
                                    <li>{{ item.title }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="contact">
                            <div class="customTitle">
                                <p>КОНТАКТЫ И ПОМОЩЬ</p>
                                <div class="line">
                                </div>
                            </div>
                            <div class="contact__block">
                                <swiper :slidesPerView="4" :spaceBetween="20"
                                    :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
                                    :autoplay="{
                                        delay: 3000,
                                    }" :speed="1000" :lazy="true">
                                    <swiper-slide v-for="(item) in contacts" :key="item">
                                        <a :href="item.link"><img :src="item.icon" /></a>
                                        <p v-html="item.title" style="text-align: center;"></p>
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main__bot">
                    <div class="plan">
                        <div class="customTitle">
                            <p>ИНДИВИДУАЛЬНЫЙ ПЛАН</p>
                            <div class="line"></div>
                        </div>
                        <div class="plan__block">
                            <div class="plan__block__in">
                                <a :href="individual[0]?.hyperlink" class="plan__block__in__top">
                                    <img src="@/assets/icons/file.svg" />
                                </a>
                                <p>Наставничество</p>
                            </div>
                            <div class="plan__block__in">
                                <a :href="individual[1]?.hyperlink" class="plan__block__in__top">
                                    <img src="@/assets/icons/file.svg" />
                                </a>
                                <p>Языковые курсы</p>
                            </div>
                        </div>
                    </div>
                    <div class="final" @click="selectTab(3)">
                        <div class="customTitle">
                            <p>ФИНАЛЬНЫЕ ДОКУМЕНТЫ</p>
                            <div class="line"></div>
                        </div>
                        <div class="final__block">
                            <div class="final__block__in" v-for="item in documents" :key="item">
                                <div class="final__block__in__top" :class="{ customBorder: item.status == 1 }">
                                    <img :src="require('@/assets/icons/galochka.svg')" v-if="item.status == 1"/>
                                    <img :src="require('@/assets/icons/file.svg')" v-else/>
                                </div>
                                <p :class="{ textGreen: item.status == 1 }">{{ item.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="general" v-if="selectedTab == 1">
                <div class="general__top">
                    <p class="general__top__title">Ваши покупки</p>
                    <div class="general__top__block">   
                        <div class="general__top__block__in" v-for="item in myBuys" :key="item">
                            <div class="customTitle">
                                <p v-if="item.type == 1">Курсы</p>
                                <p v-if="item.type == 2">Премиум опция</p>
                                <p v-if="item.type == 3">Наставничество</p>
                                <div class="line"></div>
                            </div>
                            <img src="@/assets/icons/mentorMe.svg"/>
                            <p class="general__top__block__in__name" v-if="item?.course?.type_name && item.type == 1">{{ item?.course?.type_name }}</p>
                            <p class="general__top__block__in__name" v-if="item?.package?.title && item.type == 3">{{ item?.package?.title }}</p>
                            <p class="general__top__block__in__sub" v-if="item.comment && item.type == 3">{{ item?.comment }}</p>
                            <p class="general__top__block__in__sub" v-if="item?.course?.title &&  item.type == 1">{{ item?.course?.title }}</p>
                        </div>
                    </div>
                </div>
                <div class="general__bot">
                    <p class="general__bot__title">Личная информация</p>
                    <div class="general__bot__inputs">
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="surname">Фамилия</label>
                                <input type="text" id="surname" v-model="userData.surname" @input="validateCyrillicSurname" @change="mainIsChanged()">
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="name">Имя</label>
                                <input type="text" id="name" v-model="userData.name" @input="validateCyrillicName" @change="mainIsChanged()">
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="iin">ИИН</label>
                                <input type="text" id="iin" v-model="userData.iin" :disabled="userData.iin_status == 1" @change="mainIsChanged()">
                                <img src="@/assets/icons/galochka.svg" v-if="userData.iin_status == 1" class="customInput__img"/>
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="sex">Пол</label>
                                <customSelect :list="genders" v-model="userData.gender" @change="mainIsChanged()"/>
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="date">Дата рождения</label>
                                <input type="date"  value=""  id="date" v-model="userData.birthday" @change="mainIsChanged()">
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="country">Страна</label>
                                <customSelect :list="countries" @change="cityList(), mainIsChanged()" v-model="userData.country_id" />
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="city">Город</label>
                                <customSelect :list="cities" v-model="userData.city_id"  @change="mainIsChanged()"/>
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="address">Адрес</label>
                                <input type="text" id="address" v-model="userData.address"  @change="mainIsChanged()">
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="phone">Телефон</label>
                                <input type="text" id="phone" v-model="userData.phone" :disabled="userData.phone_status == 1"  @change="mainIsChanged()">
                                <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="userData.phone_status == 1"/>
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="mail">Почта</label>
                                <input type="text" id="mail" v-model="userData.email" :disabled="userData.email_status == 1"  @change="mainIsChanged()">
                                <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="userData.email_status == 1"/>
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="tg">Telegram</label>
                                <input type="text" id="tg" v-model="userData.telegram" :disabled="userData.telegram_status == 1"  @change="mainIsChanged()">
                                <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="userData.telegram_status == 1"/>
                            </div>
                        </div>
                        <div class="general__bot__inputs__in">
                            <div class="customInput">
                                <label for="iduser">ID пользователя</label>
                                <input type="text" id="iduser" v-model="userData.id" disabled style="color: #0000004A;"  @change="mainIsChanged()">
                            </div>
                        </div>
                        <div class="general__bot__inputs__in" style="width: 100%;">
                            <div class="customInput">
                                <label>Другое</label>
                                <textarea v-model="userData.other" placeholder="Дополнительная информацию"  @change="mainIsChanged()"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="general__bot__buttons">
                        <button class="general__bot__buttons__save" @click="updateUser()">Применить</button>
                        <button class="general__bot__buttons__delete" @click="reload()">Сбросить</button>
                    </div>
                </div>
            </div>
            <div class="result" v-if="selectedTab == 2">
                <p class="result__title">Академические данные</p>
                <div class="result__inputs">
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="school">Школа</label>
                            <input type="text" id="school" v-model="results.school" :disabled="results.status_school == 1"  @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_school == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="studyField">Углубленное изучение</label>
                            <customSelect v-model="results.depth_study" :list="depthSudy" :disabled="results.status_depth_study == 1" @change="mainIsChanged()"/>
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_depth_study == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="averageScore">Средний балл аттестата</label>
                            <input type="text" id="averageScore" v-model="results.gpa_school" :disabled="results.status_gpa_school == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_gpa_school == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="placementLevel">Placement Test Level</label>
                            <input type="text" id="placementLevel" placeholder="Введите уровень теста" v-model="results.placement_test_level" :disabled="results.status_placement_test_level == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_placement_test_level == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="placementDate">Placement Test Date</label>
                            <input type="date"  value=""  id="placementDate" v-model="results.placement_test_date" :disabled="results.status_placement_test_date == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_placement_test_date == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="ielts">IELTS/TOEFL</label>
                            <input type="text" id="ielts" v-model="results.ielts" :disabled="results.status_ielts == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_ielts == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="ieltsDate">IELTS/TOEFL Date</label>
                            <input type="date"  value=""  id="ieltsDate" v-model="results.ielts_date" :disabled="results.status_ielts_date == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_ielts_date == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="sat">SAT</label>
                            <input type="text" id="sat" v-model="results.sat" :disabled="results.status_sat == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_sat == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="sat">SAT Date</label>
                            <input type="date"  value=""  id="sat" v-model="results.sat_date" :disabled="results.status_sat_date == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_sat_date == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="bachelorGPA">GPA Бакалавриат</label>
                            <input type="text" id="bachelorGPA" v-model="results.gpa_bachelor" :disabled="results.status_gpa_bachelor == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_gpa_bachelor == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="masterGPA">GPA Магистратура</label>
                            <input type="text" id="masterGPA" v-model="results.gpa_master" :disabled="results.status_gpa_master == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_gpa_master == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="kazTest">KazTest/Qazresmi</label>
                            <input type="text" id="kazTest" v-model="results.kaz_test" :disabled="results.status_kaz_test == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_kaz_test == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="kazTestDate">KazTest/Qazresmi Date</label>
                            <input type="date"  value=""  v-model="results.kaz_test_date" :disabled="results.status_kaz_test_date == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_kaz_test_date == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="gre">GRE/GMAT</label>
                            <input type="text" id="gre" v-model="results.gre" :disabled="results.status_gre == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_gre == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in">
                        <div class="customInput">
                            <label for="greDate">GRE/GMAT Date</label>
                            <input type="date"  value=""  id="greDate" v-model="results.gre_date" :disabled="results.status_gre_date == 1" @change="mainIsChanged()">
                            <img src="@/assets/icons/galochka.svg" class="customInput__img" v-if="results.status_gre_date == 1" />
                        </div>
                    </div>
                    <div class="result__inputs__in" style="width: 100%;">
                        <div class="customInput">
                            <label>Другое</label>
                            <textarea v-model="results.other" placeholder="Дополнительная информацию" @change="mainIsChanged()"></textarea>
                        </div>

                    </div>
                </div>
                <p class="result__title" style="margin-top: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);">Дополнительные данные</p>
                <div class="result__info">
                    <img src="@/assets/icons/info.svg" />
                    <p>Здесь будут отображаться данные добавленные Вашим куратором</p>
                </div>
                <div class="result__buttons">
                    <button class="result__buttons__save" @click="newResults()">Применить</button>
                    <button class="result__buttons__delete" @click="reload()">Сбросить</button>
                </div>
            </div>
            <div class="document" v-if="selectedTab == 3">
                <p class="document__title">Файлы и документы</p>
                <div class="document__block">
                    <div class="document__block__in" v-for="item in documents" :key="item.id">
                        <div class="document__block__in__left">
                            <img src="@/assets/icons/file.svg" />
                        </div>
                        <div class="line2"></div>
                        <div class="document__block__in__right">
                            <a :href="item.link" class="document__block__in__right__pdf"
                                :class="{ approved: item?.status == 1, notApproved: item?.status == 2 }">Link
                            </a>
                            <p class="document__block__in__right__name"
                                :class="{ approved: item?.status == 1, notApproved: item?.status == 2 }">{{
                                item?.name }}</p>
                            <div class="document__block__in__right__undefined" v-if="item?.status == 0">
                                <p class="document__block__in__right__undefined__left" @click="changeStatus(item, 1)">
                                    Approve</p>
                                <div class="line2"></div>
                                <p class="document__block__in__right__undefined__right" @click="changeStatus(item, 2)">Not yet</p>
                            </div>
                            <div class="document__block__in__right__approved" v-if="item?.status == 1">Approved
                            </div>
                            <div class="document__block__in__right__not" v-if="item?.status == 2">Not yet</div>
                        </div>
                    </div>
                </div>
                <p class="document__title">Дополнительные документы</p>
                <div class="document__info">
                    <img src="@/assets/icons/info.svg" />
                    <p>Здесь будут отображаться данные добавленные Вашим куратором</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import userInfo from '@/components/userInfo.vue';
import { Navigation } from "swiper";
import extend from '@/components/newPlatform/extend.vue';
import progressBar from '@/components/newPlatform/progressBar.vue';
import ReviewModal from '@/components/newPlatform/reviewModal.vue';
import customSelect from '@/components/customSelect.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        extend,
        progressBar,
        ReviewModal,
        customSelect
    },
    data() {
        return {
            depthSudy: [
                {
                    id: 0,
                    title: 'Физмат'
                },
                {
                    id: 1,
                    title: 'Гуманитарий'
                },
            ],
            selectedTab: 0,
            openExtend: false,
            selectedDocumentId: null,
            selectedDocumentName: '',
            swiperRefCategory: null,
            swiperRef: null,
            activeSwiperIndex: 0,
            agree: false,
            openReview: false,
            contacts: false,
            results: {
                school: '',
                gpa_school: '',
                depth_study: '',
                placement_test_date: '',
                ielts_date: '',
                ielts: '',
                sat: '',
                gre: '',
                gpa_bachelor: '',
                gpa_master: '',
                kaz_test: '',
                kaz_test_date: '',
                gre_date: ''
            },
            deadlines: [],
            circleColor: '',
            circlePercent: 50,
            userData: [],
            intSch: [],
            risks: [],
            individual: [],
            admissions: [],
            isMainChanged: false,
            documents: [
            ],
            myBuys: [],
            final: [
                {
                    img: 'galochka',
                    name: 'Утвержден. программы',
                    status: 'green'
                },
                {
                    img: 'galochka',
                    name: 'Утвержден. программы',
                    status: 'green'
                },
                {
                    img: 'galochka',
                    name: 'Утвержден. программы',
                    status: 'green'
                },
                {
                    img: 'mail',
                    name: 'Реком. письмо 2'
                },
                {
                    img: 'file',
                    name: 'Эссе',
                },
                {
                    img: 'file',
                    name: 'Эссе 2',
                },
                {
                    img: 'dots',
                    name: 'Дополн.',
                },
            ],
            tabs: [
                {
                    name: 'Главная страница'
                },
                {
                    name: 'Общее'
                },
                {
                    name: 'Результаты'
                },
                {
                    name: 'Документы'
                }
            ],
            breakpoints: {
                0: {
                    slidesPerView: 3.4,
                    spaceBetween: 20,
                },
            },
            genders:[
                {
                    title: 'Мужчина',
                    id: 0
                },
                {
                    title: 'Женщина',
                    id: 1
                },
            ],
            countries: [],
            circleInColor: '#ebe4e4',
            cities: [
            ],
        }
    },
    computed: {
        chartOptions() {
        return {
            backgroundColor: "transparent", // Делаем фон прозрачным
            data: [
            {
                type: "doughnut",
                innerRadius: "75%",
                yValueFormatString: "#,###'%'",
                dataPoints: [
                { name: "Осталось", y: this.circlePercent, color: this.circleColor },
                { name: "Прошло", y: 100 - this.circlePercent, color: this.circleInColor },
                ],
            },
            ],
        };
        }
    },
    methods: {
        mainIsChanged(){
            this.isMainChanged = true;
        },
        validateCyrillicSurname(event) {
            this.userData.surname = event.target.value.replace(/[^А-Яа-яЁё]/g, '');
        },
        validateCyrillicName(event) {
            this.userData.name = event.target.value.replace(/[^А-Яа-яЁё]/g, '');
        },
        reload(){
            if (confirm("Изменения не сохранятся, вы точно хотите сбросить?")) {
                window.location.reload()
            }
        },
        changeStatus(item, id){
            const data = {
                id: item.id,
                status: id
            }
            axios.put("/platform/client/basic-documents", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Успешно обновлено!')
                    if(id == 1){
                        this.selectedDocumentId = item.id;
                        this.selectedDocumentName = item.name;
                        this.openReview = true
                    }
                } else {
                    console.log(response.data);
                }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        },
        newResults(){
            axios.post("/platform/client/academic-result", this.results, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
                if (response.status === 201) {
                    toast.success('Успешно обновлено!')
                } else {
                    console.log(response.data);
                }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        },
        selectTab(index) {
            if (this.isMainChanged) {
                if (!confirm("Данные не сохранятся, вы уверены что хотите перейти на новую страницу?")) {
                    return;
                }
            }
            this.selectedTab = index;
            this.swiperRefCategory?.slideTo(index, 500);
            this.scrollToTop();
            this.isMainChanged = false; 
            this.$router.push({ 
                query: { tab: index } ,
            });
            if(this.selectedTab == 0){
                this.getMain();
                this.getUser();
            }
            if(this.selectedTab == 1){
                this.getUser();
                this.getBuys();
            }
            if(this.selectedTab == 2){
                this.getUser();
            }
            if(this.selectedTab == 3){
                this.getUser();
            }
        },
        getSwiperCategory(swiper) {
            this.swiperRefCategory = swiper;
            this.activeSwiperIndex = swiper.activeIndex;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        async cityList() {
            await axios.get(`/platform/cities?country_id=${this.userData.country_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.cities = res.data.data
                })
                .catch(err => {
                })
        },
        async getMain() {
            await axios.get(`/platform/client/key-dates`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.deadlines = res.data.data
                })
                .catch(err => {
                })
                .catch(err => {
                })
        await axios.get(`/platform/contacts`)
                .then(res => {
                    this.contacts = res.data.data.filter(item => item.type_id == 3)
                })
                .catch(err => {
                })
            await axios.get(`/platform/client/users/international-scholarship-widgets`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }
        })
            .then(res => {
                this.intSch = res.data.data
            })
            .catch(err => {
            })
            await axios.get(`/platform/client/users-document-widget`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }
        })
            .then(res => {
                this.agree = res.data.data
                this.circlePercent = (this.agree.left_days*100)/this.agree.count_of_days
                if(this.agree.status == 2){
                    this.circlePercent = 0
                }
                if(this.circlePercent == 0){
                    this.circleInColor = 'red'
                }
                if(this.circlePercent < 25){
                    this.circleColor = '#FD2D2A'
                }
                if(this.circlePercent >= 25 && this.circlePercent < 50){
                    this.circleColor = '#fd622a'
                }
                if(this.circlePercent >= 50){
                    this.circleColor = '#2BC38E'
                }
            })
            .catch(err => {
            })
            await axios.get(`/platform/client/risks`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.risks = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/platform/client/users-individual-plans`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.individual = res.data.data
                })
                .catch(err => {
                })
                await axios.get(`/platform/client/user-admissions`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.admissions = res.data.data
                    this.admissions.sort((a, b) => a.admission_status.priority - b.admission_status.priority);
                })
                .catch(err => {
                })
        },
        async getUser() {
                await axios.get(`/platform/countries`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then(res => {
                        this.countries = res.data.data
                    })
                await axios.get(`/platform/client/user`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.userData = res.data.data.user
                    this.userData.birthday = this.formatDate(this.userData.birthday)    
                    if(this.userData.country_id){
                        this.cityList()
                        this.userData.city_id = this.userData.city_id.id
                    }
                    this.results = res.data.data.academicResult
                    console.log(this.results);
                    this.results.gre_date = this.formatDate(this.results.gre_date)
                    this.results.sat_date = this.formatDate(this.results.sat_date)
                    this.results.ielts_date = this.formatDate(this.results.ielts_date)
                    this.results.kaz_test_date = this.formatDate(this.results.kaz_test_date)
                    this.results.placement_test_date = this.formatDate(this.results.placement_test_date)
                    this.documents = res.data.data.private_documents;

                })
                .catch(err => {
                })
        },
        async getBuys(){
            await axios.get(`/platform/client/history-purchase`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.myBuys = res.data.data
                })
                .catch(err => {
                })
        },
        formatDate(date) {
            const [day, month, year] = date.split('-'); // Разделяем строку
            return `${year}-${month}-${day}`; // Формируем новую строку в нужном формате
        },
        updateUser(){
            console.log(this.userData.birthday);
            
            this.userData.image = '';
            this.userData.email = '';
            axios.put("/platform/client/user/update", this.userData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Успешно обновлено!')
                } else {
                    console.log(response.data);
                }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        }
    },
    mounted() {
        const tabFromQuery = this.$route.query.tab;
        if (tabFromQuery) {
            this.selectedTab = Number(tabFromQuery);
            this.swiperRefCategory?.slideTo(this.selectedTab, 0);
            this.selectTab(this.selectedTab);
        }
        else{
            this.selectTab(0)
        }
    }
}
</script>
<style lang="scss" scoped>
.customBorder {
    border: 1px solid #14D448 !important;
}

.backGreen {
    background-color: #00D928;
}
.noneClass{
    display: none !important;
}
.backRed {
    background-color: #fd622a;
}

.textGreen {
    color: #00D928;
}

.textRed {
    color: #fd622a;
}

.main {
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

    &__top {
        display: flex;
        justify-content: space-between;
        &__center {
            box-shadow: 0px 4px 3px 1px #0000000D;
            background: #FFFFFF;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            border-radius: 10px;
            width: 100%;
            height: 830px;
            overflow: auto;
            &__block {
                display: flex;
                flex-direction: column;
                gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

                &__in {
                    display: flex;
                    justify-content: space-between;

                    &__left {
                        width: 40%;
                        padding: 5px;
                        box-shadow: 0px 0px 8px 4px #0000004D inset;
                        border-radius: 5px;
                        aspect-ratio: 1;
                        height: fit-content;
                        background-position: center;
                        background-size: cover;
                    }

                    &__right {
                        width: 55%;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        &__title {
                            color: #FD2D2A;
                        }

                        &__sub {
                            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 20px);
                            font-weight: 600;
                        }

                        &__deadline {
                            color: #0000008A;
                        }
                    }
                }
            }
        }

        &__left,
        &__center,
        &__right {
            width: 32%;
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        .status {
            background-image: url('@/assets/icons/backGroundStatus.png');
            background-position: center;
            background-size: cover;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            border-radius: 10px;
            width: 100%;
            height: 250px;
            &__block {
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__left {
                    width: 40%;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #0000008A;
                    position: relative;
                    div{
                        height: 100% !important;
                    }
                    p{
                        position: absolute;
                    }
                }

                &__right {
                    width: 57%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    &__title {
                        font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                        font-weight: 700;
                        color: #FD2D2A;
                    }

                    &__sub {
                        color: #0000008A;
                    }

                    button {
                        width: 80%;
                        border: none;
                        background-color: #FF0000;
                        padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px)0px;
                        color: white;
                        border-radius: 5px;
                    }
                }
            }
        }

        .deadlines {
            background-image: url('@/assets/icons/backGroundDate.png');
            background-position: center;
            background-size: cover;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            display: flex;
            flex-direction: column;
            // background-image: url('@/assets/icons/keyBack.png');
            // background-position: cover;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            border-radius: 10px;
            width: 100%;
            height: 540px;
            overflow: auto;
            &__block {
                display: flex;
                flex-direction: column;
                gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

                &__in {
                    display: flex;
                    flex-direction: column;
                    gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

                    &__title {
                        font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                        font-weight: 700;
                    }

                    &__new {
                        display: flex;
                        flex-direction: column;
                        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

                        &__in {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            &__left {
                                display: flex;
                                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                                &__mini {
                                    width: 22px;
                                    height: 22px;
                                    border: 1px solid #0000001A;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    &__in {
                                        height: 16px;
                                        width: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .grant,
        .risk,
        .contact {
            box-shadow: 0px 4px 3px 1px #0000000D;
            background: #FFFFFF;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            border-radius: 10px;
            width: 100%;
            height: 250px; 
        }
        .grant {
            &__block {
                display: flex;
                justify-content: space-between;

                &__in {
                    width: 48%;
                    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)10px min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
                    border: 1px solid #0000001A;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

                    &__name {
                        color: #0000008A;
                        flex-grow: 1;
                    }

                    &__date {
                        font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
                        font-weight: 700;
                    }
                }
            }
        }
        .risk {
            color: white;
            background-image: url('@/assets/icons/risk.png');
            background-size: cover;
            position: relative;
            overflow: auto;
            p {
                color: white;
                z-index: 2;
            }

            &__block {
                z-index: 2;

                &__in {

                    &:nth-child(1),
                    &:nth-child(2) {
                        font-weight: 700;
                    }
                }
            }

        }
        .contact {
            &__block {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .swiper {
                    width: 100%;
                    &-slide {
                        display: flex;
                        flex-direction: column;
                        gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                        align-items: center;
                        cursor: pointer;
                        font-size: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px);
                        img {
                            width: 100%;
                            aspect-ratio: 1;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }

    &__bot {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .plan,
        .final {
            box-shadow: 0px 4px 3px 1px #0000000D;
            background: #FFFFFF;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            border-radius: 10px;
        }

        .plan {
            width: 32%;

            &__block {
                display: flex;
                justify-content: space-between;

                &__in {
                    width: 45%;
                    display: flex;
                    flex-direction: column;
                    gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                    align-items: center;
                    color: #0000008A;

                    &__top {
                        width: 100%;
                        aspect-ratio: 1.2;
                        border: 1px solid #0000001A;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;

                        img {
                            width: 45px;
                        }
                    }
                }
            }
        }

        .final {
            width: 66%;

            &__block {
                display: flex;
                flex-wrap: wrap;
                row-gap: 20px !important;
                gap: 5%;
                &__in {
                    width: 15%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    cursor: pointer;
                    gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

                    img {
                        width: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
                    }

                    &__top {
                        width: 100%;
                        aspect-ratio: 0.8;
                        border: 1px solid #0000001A;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    @media(max-width:768px) {
        &__top {
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

            &__left,
            &__center,
            &__right {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            }

            .status {
                padding: 10px;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                &__block {
                    &__left {
                        width: 30%;
                    }
                    &__right {
                        width: 65%;
                        &__title {
                            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                        }

                        button {
                            width: 50%;
                        }
                    }
                }
            }

            .deadlines {
                padding: 10px;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                font-size: 14px !important;

                &__block {
                    gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                    &__in {
                        gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                        &__title {
                            font-size: 16px;
                        }
                    }
                }
            }

            &__center {
                padding: 10px;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                &__block {
                    gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                    &__in {
                        &__left {
                            width: 20%;
                        }

                        &__right {
                            width: 78%;
                            font-size: 14px !important;

                            &__sub {
                                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                            }
                        }
                    }
                }
            }

            .grant,
            .risk,
            .contact {
                padding: 10px;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            }

            .grant {
                &__block {
                    &__in {
                        padding: 10px;
                        gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                        &__date {
                            font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                        }
                    }
                }
            }
        }

        &__bot {
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

            .plan,
            .final {
                padding: 10px;
                width: 100%;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            }

            .plan {
                &__block {
                    &__in {
                        width: 49%;
                    }
                }
            }

            .final {
                &__block {
                    flex-wrap: wrap;
                    gap: 2%;
                    row-gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

                    &__in {
                        width: 32%;

                        &__top {
                            aspect-ratio: 1;
                        }
                    }
                }
            }
        }
    }
}

.customTitle {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    gap: 10px;
    align-items: center;
    text-align: center;
    font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
    color: #0000008A;
    width: 100%;

    @media(max-width:768px) {
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
    }
}

.line1 {
    border: 1px solid #0000001A;
}

.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}

.line2 {
    border-left: 1px solid #0000001A;
    height: 100%;
}

.customTab {
    color: #000000 !important;
}

.home {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    min-height: 100vh;

    &__left {
        width: 20%;
    }

    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__tabs {
                width: 70%;
                font-weight: 600;
                font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);;
                color: #00000026;
                display: flex;
                position: relative;

                &__swiper {
                    width: 100%;

                    .swiper-slide {
                        cursor: pointer;
                        width: fit-content;
                    }
                }

                .customTab {
                    color: #000; // Активный таб выделяется
                }
            }

            &__right {
                width: 25%;
            }
        }
    }

    @media(max-width:768px) {
        padding-top: 0px;

        &__left {
            display: none;
        }

        &__right {
            width: 100%;
            padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            border-radius: 0px;

            &__top {
                &__tabs {
                    width: 100%;
                    margin-left: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                    font-size: 21px;
                }

                &__right {
                    display: none;
                }
            }
        }
    }
}

.general {
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

    &__top,
    &__bot {
        &__title {
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            font-weight: 700;
        }

        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    }

    &__top {
        &__block {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: 20px;
            &__in {
                width: 23.5%;
                box-shadow: 0px 4px 3px 1px #0000000D;
                background: #FFFFFF;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                border-radius: 10px;
                font-weight: 700;
                img {
                    width: 60px;
                    aspect-ratio: 1;
                    object-fit: cover;
                }

                &__name {
                    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                    text-align: center;
                }

                &__sub {
                    color: #FD2D2A;
                }
            }
        }
    }

    &__bot {
        &__inputs {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

            &__in {
                width: 23.5%;
            }
        }

        &__buttons {
            display: flex;
            gap: 2%;

            button {
                width: 23.5%;
                border: none;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)0px;
                border-radius: 10px;
            }

            &__save {
                color: white;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                background-color: #11415D;
                border: 1px solid #11415D;
                transition: all 0.5s ease;

                &:hover {
                    color: white;
                    background-color: #2076a7;
                }
            }

            &__delete {
                background-color: inherit;
                border: 1px solid #0000001A !important;
                color: #0000008A;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                transition: all 0.5s ease;

                &:hover {
                    color: white;
                    background-color: #11415D;
                    border: 1px solid #11415D;
                }
            }
        }
    }

    @media(max-width:768px) {

        &__top,
        &__bot {
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

            &__title {
                font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
            }
        }

        &__top {
            &__block {
                &__in {
                    width: 49%;
                    gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                    padding: 10px;

                    .line {
                        display: none;
                    }

                    &__name {
                        font-size: 16px;
                    }
                }
            }
        }

        &__bot {
            &__inputs {
                &__in {
                    width: 49%;

                    input {
                        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                    }
                }
            }

            &__buttons {
                button {
                    width: 49%;
                    padding: 10px 0px;
                    font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                }
            }
        }
    }
}

.result {
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

    &__title {
        font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
        font-weight: 700;
    }

    &__inputs {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

        &__in {
            width: 23.5%;
        }
    }

    &__info {
        display: flex;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        align-items: center;

        img {
            width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        }
    }

    &__buttons {
        display: flex;
        gap: 2%;

        button {
            width: 23.5%;
            border: none;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)0px;
            border-radius: 10px;
        }

        &__save {
            color: white;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            background-color: #11415D;
            transition: all 0.5s ease;

            &:hover {
                color: white;
                background-color: #2076a7;
            }
        }

        &__delete {
            background-color: inherit;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            border: 1px solid #0000001A !important;
            color: #0000008A;
            transition: all 0.5s ease;

            &:hover {
                color: white;
                background-color: #11415D;
                border: 1px solid #11415D;
            }
        }
    }

    @media(max-width:768px) {
        &__title {
            font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
        }

        &__inputs {
            &__in {
                width: 100%;
            }
        }

        &__info {
            display: none;
        }

        &__buttons {
            button {
                width: 49%;
                padding: 10px 0px;
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
            }
        }
    }
}

.approved {
    color: #14D448;
}

.notApproved {
    color: #FF9500;
}

.document {
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    &__title {
        font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
        font-weight: 700;
        margin-top: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
    }

    &__block {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__in {
            width: 23.5%;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 4px 3px 1px #0000000D;
            background: #FFFFFF;
            border-radius: 10px;
            &__left {
                width: 15%;
                img {
                    width: 100%;
                }
            }
            &__right {
                width: 75%;
                display: flex;
                flex-direction: column;
                gap: 5px;
                &__name {
                    font-weight: 700;
                    flex-grow: 1;
                }
                &__undefined {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    border: 1px solid #0000001A;
                    border-radius: 10px;
                    cursor: pointer;

                    &__left {
                        color: #00D928;
                    }

                    &__right {
                        color: #FF9500;
                    }
                }
                &__approved {
                    padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    background-color: #F8F8F8;
                    width: fit-content;
                    border-radius: 10px;
                    color: #0000008A;
                    cursor: pointer;
                }
                &__not {
                    padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    background-color: #FF9500;
                    width: fit-content;
                    color: white;
                    border-radius: 10px;
                    cursor: pointer;
                }
            }
        }

        &__plus {
            width: 23.5%;
            padding: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px)0px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 8px 4px #0000000D inset;
            border: 1px solid #0000001A;
            border-radius: 10px;
            cursor: pointer;

            img {
                width: 60px;
                height: 60px;
            }
        }
    }

    &__info {
        display: flex;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        align-items: center;

        img {
            width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        }
    }

    @media(max-width:768px) {
        &__title {
            font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
            margin-top: 0px;
        }

        &__block {
            &__in {
                width: 100%;
                align-items: center;
            }
        }
    }
}
</style>