<template>
    <div class="aboutBack" @click="closeModal">
        <div class="about" >
            <p class="about__title">Подробнее о курсе</p>
            <div class="line"></div>
            <p class="about__sub" v-if="item?.description" v-html="item?.description"></p>
            <button class="about__ready" @click="buyItem()">Купить</button>
        </div> 
    </div>
</template>
<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import extend from './extend.vue';
export default {
  components: { extend },
    props: ['item', 'isPremium', 'isCourse', 'itemId'],
    data(){
        return{
            isOk: false,
            openExtend: false   
        }
    },
    methods: {
        closeModal(){
            this.$emit('closeModal')
        },
        buyItem(){
            this.closeModal();
            this.$emit('buyNewItem')
            // const forBack = this.isPremium
            //     ? { premium_option_id: this.itemId }
            //     : this.isCourse
            //     ? { course_id: this.itemId }
            //     : {};
            // axios.post("/platform/client/baskets", forBack, {
            //     headers: {
            //         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            //     }
            // })
            // .then((response) => {
            //     if (response.status === 201) {
            //         toast.success('Успешно добавлено в корзину!')
            //     } else {
            //         console.log(response.data);
            //     }
            // })
            // .catch((response) => { 
            //     console.log(response.response.data.errors);
            // });
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
}
.aboutBack{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #0000005b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}
.about{
    width: 33%;
    background-color: white;
    padding: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px) 60px;
    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    &__title{
        text-align: center;
        font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
        font-weight: 600;
        color: #11415D;
    }
    &__ready{
        margin-top: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        background-color: #11415D;
        color: white;
        border: none;
    }
    button{
        width: 48%;
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)0px;
        border-radius: 10px;
    }
    &__ok{
        width: 40%;
    }
}
</style>