<template>
    <div class="modal1Back">
        <div class="modal1">
            <img src="@/assets/icons/exit.svg" class="exit" @click="closeModal"/>
            <div class="modal1__top">
                <p class="modal1__top__title">Добавить программу</p>
                <p class="modal1__top__sub">Заполните все поля для добавления новой программы</p>
                <div class="line"></div>
            </div>
            <p class="customTitle">Название программы и категоризация</p>
            <div class="modal1__inputs">
                <div class="customInput">
                    <label>Название программы</label>
                    <input type="text" v-model="formData.program_name.ru" placeholder="University of London" />
                </div>
                <div class="customInput">
                    <label>Стипендия</label>    
                    <customSelect :list="stips" v-model="formData.scholarship_id" />
                </div>
                <div class="customInput">
                    <label>Степень</label>
                    <customSelect :list="steps" v-model="formData.study_degree_id" />
                </div>
                <div class="customInput">
                    <label>Категория</label>
                    <customSelect :list="categories" v-model="formData.cat_id" @change="selectCat()"/>
                </div>
                <div class="customInput">
                    <label>Подкатегория</label>
                    <customSelect :list="subCategories" v-model="formData.subCat_id" />
                </div>
                <div class="customInput">
                    <label>Ссылка на сайт программы</label>
                    <input type="text" v-model="formData.link" placeholder="Ссылка" />
                </div>
                <div class="customInput">
                    <label>Название ВУЗа</label>
                    <customSelect :list="univers" v-model="formData.university_id" @change="selectUniver()"/>
                </div>
                <div class="customInput">
                    <label>Страна</label>
                    <input placeholder="Страна" disabled/>
                </div>
                <div class="customInput">
                    <label>Штат</label>
                    <input placeholder="Штат" disabled/>
                </div>
                <div class="customInput">
                    <label>Город</label>
                    <input placeholder="Город" disabled/>
                </div>
                <div class="customInput">
                    <label>Уровень</label>
                    <customSelect :list="top" v-model="formData.top" />
                </div>
                <div class="customInput">
                    <label>GPA (select)</label>
                    <customSelect :list="gpaSelect"/>
                </div>
                <div class="customInput">
                    <label>GPA</label>
                    <input type="text" v-model="formData.gpa_value" />
                </div>
                <div class="customInput">
                    <label>IELTS</label>
                    <customSelect :list="ielts" v-model="formData.ielts_value" />
                </div>
                <div class="customInput">
                    <label>TOEFL</label>
                    <input v-model="formData.toefl_value"/>
                </div>
                <div class="customInput" v-if="formData.study_degree_id == 4">
                    <label>GRE/GMAT</label>
                    <customSelect :list="justGre" v-model="formData.is_gre" />
                </div>
                <div class="customInput" v-if="formData.study_degree_id == 3 || formData.study_degree_id == 2 || formData.study_degree_id == null">
                    <label>SAT</label>
                    <customSelect :list="greGmat" v-model="formData.is_sat" />
                </div>
                <div class="customInput">
                    <label>Deadline 1</label>
                    <input type="date"  value=""  v-model="formData.deadline_1" />
                </div>
                <div class="customInput">
                    <label>Deadline 2</label>
                    <input type="date"  value=""  v-model="formData.deadline_2" />
                </div>
                <div class="customInput">
                    <label>Application fee</label>
                    <input type="text" v-model="formData.application_fee_value" />
                </div>
                <div class="customInput">
                    <label>Продолжительность обучения</label>
                    <customSelect :list="durations" v-model="formData.duration_value" />
                </div>
                <div class="customInput">
                    <label>Стоимость обучения $/год</label>
                    <input type="text" v-model="formData.tuition_cost" />
                </div>
                <div class="customInput">
                    <label>Партнеры</label>
                    <customSelect :list="yesorno" v-model="formData.partnership" />
                </div>
                <div class="customInput">
                    <label>Выбор студента</label>
                    <customSelect :list="yesorno" v-model="formData.enrollment" />
                </div>
                <div class="customInput">
                    <label>WES</label>
                    <customSelect :list="greGmat" v-model="formData.wes" />
                </div>
                <div class="customInput">
                    <label>Ширина карты</label>
                    <input  v-model="formData.longitude" />
                </div>
                <div class="customInput">
                    <label>Долгота карты</label>
                    <input v-model="formData.latitude" />
                </div>
                <div class="customInput">
                    <label>Ссылка на видео</label>
                    <input  />
                </div>
            </div>
            <p class="customTitle">Медиа файлы</p>
            <div class="modal1__files">
                <div class="modal1__files__in" v-for="(image, index) in formData.image" :key="index">
                    <img :src="image.preview" alt="Uploaded Image"/>
                </div>
                <label class="modal1__files__plus">
                    <input type="file" multiple @change="handleFileUpload" style="display: none;"/>
                    <img src="@/assets/icons/plus.svg"/>
                    <p>Добавить</p>
                </label>
            </div>
            <p class="customTitle">Описание программы</p>
            <div class="modal1__textarea">
                <textarea v-model="formData.description.ru" placeholder="..."></textarea>
            </div>
            <div class="modal1__documents">
                <div class="customInput">
                    <label>Академические требования</label>
                    <textarea v-model="formData.docs.ru"></textarea>
                </div>
                <div class="customInput">
                    <label>Перечень документов</label>
                    <textarea v-model="formData.other.ru"></textarea>
                </div>
                <div class="customInput" style="width: 100%;">
                    <label>Другое</label>
                    <textarea v-model="formData.other.ru" style="height: 200px;"></textarea>
                </div>
            </div>
            <div class="modal1__buttons">
                <button @click="postProgram" class="modal1__buttons__save">Отправить</button>
                <button class="modal1__buttons__delete">Предпросмотр</button>
            </div>
        </div>
    </div>
</template>
<script>
import customSelect from '../customSelect.vue';
import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    data(){
        return{
            categories: [],
            subCategories: [],
            countries: [],
            selectedUniver: '',
            selectedCountry: '',
            steps: [],
            selectedCat: '',
            stips: [],
            specials: [],
            yesorno: [
                {
                    id: 0,
                    title: 'Нет'
                },          
                {
                    id: 1,
                    title: 'Да'
                },
            ],
            gpaSelect: [
                {
                    id: 0,
                    title: '< 3'
                },          
                {
                    id: 1,
                    title: '> 3,33'
                },
            ],
            top: [
                {
                    id: 30,
                    title: 'ТОП-50'
                },
                {
                    id: 250,
                    title: 'ТОП-250'
                },
            ],
            partnerships: [
                {
                    id: 0,
                    title: 'Партнер'
                },
                {
                    id: 1,
                    title: 'нет'
                },
            ],
            choose: [
                {
                    id: 0,
                    title: 'Выбор'
                },
                {
                    id: 1,
                    title: 'нет'
                },
            ],
            justGre: [
                {
                    id: 1,
                    title: 'Требуется'
                },
                {
                    id: 0,
                    title: 'Не требуется'
                },
                {
                    id: 2,
                    title: 'Рекомендуется'
                },
            ],
            greGmat: [
                {
                    id: 1,
                    title: 'Требуется'
                },
                {
                    id: 0,
                    title: 'Не требуется'
                },
            ],
            durations: [],
            ielts: [],
            univers: [],
            formData: {
                program_name: {
                    en: "Computer Science",
                    ru: "Компьютерные науки",
                    kz: "Есептеу техникасы"
                },
                subCat_id: null,
                scholarship_id: null,
                study_degree_id:null,
                university_id: 1,
                is_sat: null,
                cat_id: null,
                video: 'test.kz',
                work_experience: 'test',
                link: '',
                top: null,
                duration_value: '',
                toefl_value: '',
                application_fee_value: '1',
                program_id:null,
                partnership: null,
                language: 'en',
                longitude: '152',
                latitude: '154',
                letter: 'a',
                enrollment: null,
                tuition_cost: null,
                deadline_1: null,
                deadline_2: null,
                wes: '',
                is_gmat: '',
                gpa_value: null,
                ielts_value: null,
                toefl_value: null,
                is_gre: null,
                code: null,
                description: {
                    ru: "",
                    kz: "kz",
                    en: "en"
                },
                other: { },
                details: {
                    ru: "ru",
                    kz: "kz",
                    en: "en"
                },
                docs: {
                    ru: "",
                    kz: "kz",
                    en: "en"
                },
                image: []
            }
        }
    },
    components: {
        customSelect
    },
    methods: {
        selectCat() {
            this.subCategories = this.categories.filter(item => item.id == this.formData.cat_id)[0].subCategories
        },
        selectUniver() {
            this.selectedUniver = this.univers.filter(item => item.id == this.formData.university_id)
            console.log(this.selectedUniver);
            
        },
        async getPage() {
            await axios.get(`/platform/scholarships`, {
            })
                .then(res => {
                    this.stips = res.data.data
                })
                .catch(err => {
                })
                await axios.get(`platform/universities`, {
            })
                .then(res => {
                    this.univers = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/V1/programs`, {
            })
                .then(res => {
                    this.specials = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/platform/filter-values?type=5`)
                .then(res => {
                    this.durations = res.data.data.map(item => ({
                        ...item,
                        title: item.value // Заменяем value на title
                    }));
                })
                .catch(err => {
                    console.error(err);
                });
            await axios.get(`/platform/filter-values?type=2`)
            .then(res => {
                this.ielts = res.data.data.map(item => ({
                    ...item,
                    title: item.value // Заменяем value на title
                }));
            })
            .catch(err => {
                console.error(err);
            });
                await axios.get(`/platform/countries`, {
            })
                .then(res => {
                    this.countries = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/platform/study-degrees`, {
            })
                .then(res => {
                    this.steps = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/platform/categories`, {
            })
                .then(res => {
                    this.categories = res.data.data
                })
                .catch(err => {
                })
        },
        closeModal(){
            this.$emit('closeModal')
        },
        handleFileUpload(event) {
            const files = Array.from(event.target.files);
            files.forEach(file => {
                this.formData.image.push({
                    file,
                    preview: URL.createObjectURL(file)
                });
            });
        },
        async postProgram() {
    try {
        // Define required fields excluding fields like 'is_toefl', 'other', etc.
        const requiredFields = [
            'program_name.ru', 'program_name.kz', 'program_name.en', 
            'subCat_id', 'scholarship_id', 'study_degree_id', 
            'university_id', 'cat_id', 'video', 
            'work_experience', 'link', 'top', 'duration_value', 
            'application_fee_value', 'program_id', 'language', 
            'longitude', 'latitude', 'letter', 
            'enrollment', 'tuition_cost', 'deadline_1', 
            'deadline_2', 'wes', 'is_gmat', 
            'gpa_value', 'ielts_value', 'is_gre', 
            'code', 'description.ru', 'description.kz', 'description.en',
            'details.ru', 'details.kz', 'details.en',
            'docs.ru', 'docs.kz', 'docs.en',
        ];
        // Helper function to check if a nested field exists
            const checkField = (field) => {
                const fields = field.split('.');
                let value = this.formData;
                for (let f of fields) {
                    value = value[f];
                    if (value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
                        return false;
                    }
                }
                return true;
            };

            // Validate each field in the requiredFields list
            // for (let field of requiredFields) {
            //     if (!checkField(field)) {
            //         toast.error("Заполните все обязательные поля");
            //         return;
            //     }
            // }

            // // Validate 'image' field to ensure it is not empty
            // if (!this.formData.image || this.formData.image.length === 0) {
            //     toast.error("Заполните все обязательные поля");
            //     return;
            // }

            // If all required fields are valid, prepare the form data to send
            this.formData.toefl_value = this.formData.ielts_value;
            this.formData.is_gmat = this.formData.is_gre;
            const formDataToSend = new FormData();
            
            for (let key in this.formData) {
                if (key !== 'images') {
                    formDataToSend.append(key, this.formData[key]);
                }
            }

            this.formData.image.forEach((image, index) => {
                formDataToSend.append('image', image.file);
            });

            // Send the request to the server
            const response = await axios.post('/V1/universityPrograms', formDataToSend, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            });

            console.log('Program published successfully:', response.data);

        } catch (error) {
            console.error('Error while posting program:', error);
        }
    }
    },
    async created() {
        this.getPage();
    },
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
.modal1Back{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 999;
    align-items: center;
    background-color: #0000007a;
}
.modal1{
    width: 64%;
    background: white;
    padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)60px;
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    .exit{
        position: absolute;
        top: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        cursor: pointer;
        right: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        filter: brightness(0) saturate(100%);
        width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    }
    &__top{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        width: 100%;
        &__title{
            color: #11415D;
            font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
            font-weight: 600;
        }
        &__sub{
            color: #0000008A;
        }
    }
    &__inputs{
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    }
    &__files{
        display: flex;
        gap: 1%;
        align-items: center;
        &__in{
            width: 10%;
            img{
                width: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                border-radius: 10px;
                cursor: pointer;
            }
        }
        &__plus{
            width: 10%;
            border-radius: 10px;
            cursor: pointer;
            color: #0000008A;
            aspect-ratio: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            background-color: #F0F2F5;
            align-items: center;
            justify-content: center;
            img{
                width: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                height: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
            }
        }
    }
    &__documents{
        display: flex;
        flex-wrap: wrap;
        row-gap: 30px;
        justify-content: space-between;
        .customInput{
            width: 48%;
        }
    }
    &__textarea{
        width: 100%;
        textarea{
            width: 100%;
            height: 250px;
            resize: none;
            border: 1px solid #0000001A;
            border-radius: 10px;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        }
    }
    &__buttons{
        display: flex;
        gap: 2%;
        button{
            width: 30%;
            border: none;
            height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            border-radius: 10px;
        }
        &__save{
            color: white;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            background-color: #11415D;
            border: 1px solid #11415D;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #2076a7;
            }
        }
        &__delete{
            background-color: inherit;
            border: 1px solid #0000001A !important;
            color: #0000008A;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #11415D;
                border: 1px solid #11415D;
            }
        }
    }
}
.customTitle{
    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
    font-weight: 600;
}
.customInput{
    position: relative;
    display: inline-block;
    width: 32%;
    cursor: pointer !important;
    label{
        position: absolute;
        top: -8px;
        left: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
        padding: 0 10px;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
        color: #c0c0c0; 
        z-index: 98;
    }
    input, select {
        width: 100%;
        padding: 0px min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
        border: 1px solid #e0e0e0; /* Цвет рамки */
        border-radius: 8px;
        font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        background-color: inherit;
        color: #000; /* Цвет текста */
        outline: none;
    }
    div{
        width: 100%;
        resize: none;
        border-radius: 10px;
    }
    textarea{
        width: 100%;
        border: 1px solid #00000010;
        height:min(max(150px, calc(9.375rem + ((1vw - 7.68px) * 13.0208))), 300px);
        resize: none;
        border-radius: 10px;
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    }
    img{
        position: absolute;
        left: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        top: 50%;
        width: 5%;
        height: 10px;
        transform: translate(-50%, -50%);
        filter: brightness(0) saturate(100%) invert(46%) sepia(75%) saturate(3165%) hue-rotate(213deg) brightness(100%) contrast(103%);
    }
    }
</style>