<template>
    <div class="modal1Back">
        <div class="modal1">
            <div class="modal1__top">
                <label class="modal1__top__left" for="curatorImage">
                    <img :src="imagePreview || userInfo.image" alt="curator image"/>
                    <p>ID {{ userInfo.id }}</p>
                    <input 
                        type="file" 
                        id="curatorImage" 
                        style="display: none;" 
                        @change="handleFileChange"
                    />
                </label>
                <div class="modal1__top__right">
                    <p class="modal1__top__right__name">{{ userInfo.name }}</p>
                    <div class="modal1__top__right__status">
                        <img src="@/assets/icons/curatorIcon.svg"/>
                        <p>Куратор</p>
                    </div>
                    <div class="line"></div>
                    <p class="modal1__top__right__email">{{ userInfo.email }}</p>
                    <p class="modal1__top__right__phone">{{ userInfo.phone }}</p>
                </div>
            </div>
            <div class="modal1__info">
                <p class="modal1__info__title">Редактирование данных</p>
                <p class="modal1__info__sub">Информация устарела? Без проблем, укажите новую!</p>
            </div>
            <div class="modal1__inputs">
                <div class="customInputWhite">
                    <label>Фамилия</label>
                    <input placeholder="Фамилия" v-model="editableUser.surname"/>
                </div>
                <div class="customInputWhite">
                    <label>Имя</label>
                    <input placeholder="Имя" v-model="editableUser.name" @input="validateCyrillic"/>
                </div>
                <div class="customInput">
                    <label>E-Mail</label>
                    <input type="email" v-model="editableUser.email"/>
                </div>
                <div class="customInput">
                    <label>Телефон</label>
                    <input type="text" v-model="editableUser.phone"/>
                </div>
            </div>
            <div class="modal1__buttons">
                <button class="modal1__buttons__save" @click="saveChanges">Применить</button>
                <button class="modal1__buttons__delete" @click="cancelChanges">Закрыть</button>
            </div>
        </div>
    </div>
</template>

<script>
import customSelect from '../customSelect.vue';
import axios from 'axios';
import { mapGetters } from "vuex";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    components: {
        customSelect
    },
    data() {
        return {
            editableUser: {}, // Копия данных для редактирования
            imagePreview: null, // Локальное состояние для превью изображения
            originalUser: {}, // Данные пользователя для восстановления
        };
    },
    methods: {
        validateCyrillic(event) {
            this.editableUser.name = event.target.value.replace(/[^А-Яа-яЁё]/g, '');
        },
        initializeModal() {
            this.editableUser = { ...this.userInfo };
            this.originalUser = { ...this.userInfo };
            this.imagePreview = null;
        },
        // Закрытие модалки и восстановление исходных данных
        cancelChanges() {
            this.editableUser = { ...this.originalUser };
            this.imagePreview = null;
            this.$emit('closeModal');
        },
        // Обработка изменения изображения
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.imagePreview = URL.createObjectURL(file);
                this.editableUser.image = file;
            }
        },
        // Сохранение изменений через API
        async saveChanges() {
            const formData = new FormData();
            formData.append('name', this.editableUser.name);
            formData.append('surname', this.editableUser.surname);
            formData.append('phone', this.editableUser.phone);
            formData.append('image', this.editableUser.image);

            try {
                await axios.post('/platform/curator/update-self', formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                toast.success('Успешно обновлено!')
                this.originalUser = { ...this.editableUser };
                this.$emit('closeModal');
            } catch (response) {
                toast.error(response.response.data.message);
            }
        },
    },
    computed: {
        ...mapGetters(["userInfo"])
    },
    mounted() {
        this.initializeModal(); // Инициализация данных при открытии модалки
    },
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
.modal1Back{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 999;
    align-items: center;
    background-color: #0000007a;
}
.modal1{
    width: 33%;
    background: white;
    padding: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    &__top{
        display: flex;
        justify-content: space-between;
        &__left{
            width: 30%;
            aspect-ratio: 1;
            position: relative;
            border-radius: 50%;
            &::before{
                content: '';
                position: absolute;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%);
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            p{
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-weight: 600;   
            }
            img{
                width: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                height: auto;
                border-radius: 50%;
            }
        }
        &__right{
            width: 65%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            &__name{
                font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
                color: #11415D;
                font-weight:700;
            }
            &__status{
                img{
                    width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                }
                display: flex;
                gap: 10px;
                align-items: center;
                color: #FD2D2A;
                font-weight: 600;
            }
            &__email, &__phone{
                color: #0000008A;
                font-weight: 600;
            }
        }
    }
    &__info{
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__title{
            font-weight: 600;
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            color: #11415D;
        }
        &__sub{
            color: #0000008A;
        }
    }
    &__inputs{
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    }
        &__buttons{
            display: flex;
            gap: 4%;
            button{
                width: 48%;
                border: none;
                height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                border-radius: 10px;
            }
            &__save{
                color: white;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                background-color: #11415D;
                border: 1px solid #11415D;
                transition: all 0.5s ease;
                &:hover{
                    color: white;
                    background-color: #2076a7;
                }
            }
            &__delete{
                background-color: inherit;
                border: 1px solid #0000001A !important;
                color: #0000008A;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                transition: all 0.5s ease;
                &:hover{
                    color: white;
                    background-color: #11415D;
                    border: 1px solid #11415D;
                }
            }
        }
}
</style>