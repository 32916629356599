<template>
    <div class="addNewsBack" @click="closeModal">
        <div class="addNews" @click.stop>
            <p class="addNews__title">Добавить новость</p>
            <p class="addNews__sub">Заполните все поля для добавления новой новости</p>
            <div class="line"></div>
            <p class="mainTitle">Название новости</p>
            <input placeholder="Введите название...."/>
            <p class="mainTitle">Баннер публикации</p>
            <img src="@/assets/icons/addNews.svg"/>
            <p class="mainTitle">Ссылка</p>
            <input placeholder="..."/>
            <div class="addNews__buttons">
                <button class="addNews__buttons__left" @click="closeModal">Опубликовать</button>
                <button class="addNews__buttons__right">Предпросмотр</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    props: [
    ],
    methods: {
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
    .addNewsBack{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        z-index: 999;
        align-items: center;
        background-color: #0000007a;
    }
    .addNews{
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        width: 50%;
        background-color: white;
        padding: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px) 60px;
        max-height: 90vh;
        overflow: auto;
        border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        img{
            width: 50%;
        }
        &__title{
            font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
            font-weight: 600;
            text-align: center;
            color: #2BC38E;
        }
        &__sub{
            color: #0000008A;
            text-align: center;
        }
        input{
            border: 1px solid #0000001A;
            border-radius: 10px;
            padding: 10px;
            width: 100%;
            outline: none;
        }
        .mainTitle{
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            font-weight: 600;
            text-align: start;
        }
        &__buttons{
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            button{
                width: 30%;
                border-radius: 10px;
                padding: 10px 0px;
            }
            &__left{
                background-color: #2BC38E;
                color: white;
                border: none;
            }
            &__right{
                background-color: transparent;
                color: #0000008A;
                border: 1px solid #0000001A;
            }
        }
    }
</style>