<template>
    <AboutProgram v-if="openModal" @closeModal="openModal = false" :isCourse="true" :item="selectedItem" @buyNewItem="openExtend = true"/>
    <extend v-if="openExtend"  @closeModal="openExtend = false" :props-item="selectedItem" :is-course="true"/>
    <div class="schools">
        <div class="schools__left">
            <platformTable />
        </div>
        <div class="schools__right">
            <div class="schools__right__top">
                <div class="schools__right__top__tabs">
                    <div class="schools__right__top__tabs__in" v-for="(tab, index) in tabs" :key="tab"
                        :class="{ customTab: selectedTab == index }" @click="newTab(index)">
                        {{ tab.name }}
                    </div>
                </div>
                <div class="schools__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="schools__right__block" v-if="selectedTab == 0">
                <div class="schools__right__block__sale saleCard" v-for="item in sales" :key="item" :style="{ backgroundImage: `url('${item?.image}')` }">
                    <div class="schools__right__block__sale__top">
                        <p class="schools__right__block__sale__top__title">{{ item.name }}</p>
                        <p class="schools__right__block__sale__top__text">{{ item.description }}</p>
                    </div>
                    <a class="redButton" :href="item.link" target="_blank">Записаться</a>
                </div>
                <div class="offer customBlock">
                    <div class="customTitle">
                        <p>Описание и стоимость услуг</p>
                        <div class="line"></div>
                    </div>
                    <div class="offer__block">
                        <img src="@/assets/icons/selectRed.svg" class="arrowLeft1" style="rotate: 180deg;"/>
                        <swiper 
                        class="offer__block__swiper"
                        :navigation="{
                            nextEl: '.arrowRight1',
                            prevEl: '.arrowLeft1'}"
                        :breakpoints="{
                            0: {slidesPerView: 3, spaceBetween: 15},
                            640: { slidesPerView: 4, spaceBetween: 30 },
                        }"
                            >
                            <swiper-slide v-for="item in offers" :key="item" class="offer__block__swiper__slide">
                                <img :src="item.image"/>
                                <a :href="item.file" target="_blank"><p class="offer__block__swiper__slide__name">{{item.title}}</p></a>
                            </swiper-slide>
                        </swiper>
                        <img src="@/assets/icons/selectRed.svg" class="arrowRight1"/>
                    </div>
                </div>
                <div class="indiv customBlock">
                    <div class="customTitle">
                        <p>Индивидуальные курсы general english</p>
                        <div class="line"></div>
                    </div>
                    <div class="indiv__block">
                        <div class="indiv__block__in" v-for="item in individual" :key="item.id">
                            <div class="indiv__block__in__left">
                                <p class="indiv__block__in__left__name">{{ item.title }}</p>
                                <p class="indiv__block__in__left__time">{{ item.price }}</p>
                            </div>
                            <button  @click="newItemId(item)">Купить</button>
                        </div>
                    </div>
                </div>
                <div class="indiv customBlock">
                    <div class="customTitle">
                        <p>Групповые курсы general english</p>
                        <div class="line"></div>
                    </div>
                    <div class="indiv__block">
                        <div class="indiv__block__in" v-for="item in group" :key="item">
                            <div class="indiv__block__in__left">
                                <p class="indiv__block__in__left__name">{{ item.title }}</p>
                                <p class="indiv__block__in__left__time">{{ item.price }}</p>
                            </div>
                            <button  @click="newItemId(item)">Купить</button>
                        </div>
                    </div>
                </div>
                <div class="indiv customBlock">
                    <div class="customTitle">
                        <p>платные онлайн курсы</p>
                        <div class="line"></div>
                    </div>
                    <div class="indiv__block">
                        <div class="indiv__block__in" v-for="item in paidCources" :key="item.id">
                            <div class="indiv__block__in__left">
                                <p class="indiv__block__in__left__name">{{ item.title }}</p>
                                <p class="indiv__block__in__left__time">{{ item.price }}</p>
                            </div>
                            <button  @click="newItemId(item)">Купить</button>
                        </div>
                    </div>
                </div>
                <div class="online customBlock">
                    <div class="customTitle">
                        <p>ОНЛАЙН бесплатные материалы</p>
                        <div class="line"></div>
                    </div>
                    <div class="online__block">
                        <div class="online__block__in" v-for="item in online" :key="item">
                            <div class="online__block__in__box1">
                                <div class="online__block__in__box1__color" :class="{customColor: item.status == 0}"></div>
                            </div>
                            <div class="online__block__in__name" :class="{customName: item.status == 0}">{{ item.title }}</div>
                            <div class="greenLine" v-if="item.status == 1"></div>
                            <div class="greyLine" v-if="item.status == 0"></div>
                            <a :href="item.link" v-if="item.status == 1">Доступен</a>
                            <a v-else style="border: 1px solid #8e8e8e; color: #8e8e8e;">Недоступен</a>
                        </div>
                    </div>
                </div>
                <div class="offer customBlock">
                    <div class="customTitle">
                        <p>Книги и гайды</p>
                        <div class="line"></div>
                    </div>
                    <div class="offer__block">
                        <img src="@/assets/icons/selectRed.svg" class="arrowLeft2" style="rotate: 180deg;"/>
                        <swiper 
                        class="offer__block__swiper"
                        :navigation="{
                            nextEl: '.arrowRight2',
                            prevEl: '.arrowLeft2'}"
                        :breakpoints="{
                            0: {slidesPerView: 3, spaceBetween: 15},
                            640: { slidesPerView: 4, spaceBetween: 30 },
                        }">
                            <swiper-slide v-for="item in booksForLang" :key="item" class="offer__block__swiper__slide">
                                <img :src="item.image"/>
                                <a :href="item.link" target="_blank" class="offer__block__swiper__slide__name">{{ item.title }}</a>
                            </swiper-slide>
                        </swiper>
                        <img src="@/assets/icons/selectRed.svg" class="arrowRight2"/>
                    </div>
                </div>
                <div class="offer customBlock">
                    <div class="customTitle">
                        <p>НАШИ УЧИТЕЛЯ</p>
                        <div class="line"></div>
                    </div>
                    <div class="offer__block">
                        <img src="@/assets/icons/selectRed.svg" class="arrowLeft3" style="rotate: 180deg;"/>
                        <swiper 
                        class="offer__block__swiper"
                        :navigation="{
                            nextEl: '.arrowRight3',
                            prevEl: '.arrowLeft3'}"
                        :breakpoints="{
                            0: {slidesPerView: 3, spaceBetween: 15},
                            640: { slidesPerView: 4, spaceBetween: 30 },
                        }">
                            <swiper-slide v-for="item in teachers" :key="item" class="offer__block__swiper__slide">
                                <img :src="item.image"/>
                                <a :href="item.file" target="_blank" v-if="item.file"><p class="offer__block__swiper__slide__name">{{item.surname}} {{item.name}}</p></a>
                                <a :href="item.link"  target="_blank" v-if="item.link"><p class="offer__block__swiper__slide__name">{{item.surname}} {{item.name}}</p></a>
                            </swiper-slide>
                        </swiper>
                        <img src="@/assets/icons/selectRed.svg"  class="arrowRight3"/>
                    </div>
                </div>
                <div class="contact1 customBlock">
                    <div class="customTitle">
                        <p>КОНТАКТЫ И ПОМОЩЬ</p>
                        <div class="line"></div>
                    </div>
                    <div class="contact1__block">
                        <a :href="item.link" class="contact1__block__in" v-for="item in contacts" :key="item" target="_blank">
                            <img :src="item.icon"/>
                            <p>{{ item.title}}</p>
                        </a>
                    </div>
                </div>
            </div>
            <div class="schools__right__block" v-if="selectedTab == 1">
                <div class="cert saleCard" v-for="item in sales1" :key="item" :style="{ backgroundImage: `url('${item?.image}')` }">
                    <img src="@/assets/icons/british.jpg" class="cert__icon"/>
                    <p class="cert__title">
                        {{ item.name }}
                    </p>
                    <a class="redButton" :href="item?.file" target="_blank" v-if="item.file">Показать сертификат</a>
                    <a class="redButton" :href="item?.link" target="_blank" v-else>Показать сертификат</a>
                </div>
                <div class="test customBlock">
                    <div class="customTitle">
                        <p>ТЕСТЫ</p>
                        <div class="line"></div>
                    </div>
                    <div class="offer__block">
                        <img src="@/assets/icons/selectRed.svg" class="arrowLeft5" style="rotate: 180deg;"/>
                        <swiper 
                        class="offer__block__swiper"
                        :navigation="{
                            nextEl: '.arrowRight5',
                            prevEl: '.arrowLeft5'}"
                        :breakpoints="{
                            0: {slidesPerView: 3, spaceBetween: 15},
                            640: { slidesPerView: 4, spaceBetween: 30 },
                        }">
                            <swiper-slide v-for="item in testWidget" :key="item" class="offer__block__swiper__slide">
                                <img src="@/assets/icons/testIcon.png"/>
                                <a :href="item.file" target="_blank" class="offer__block__swiper__slide__name">{{item.name}}</a>
                            </swiper-slide>
                        </swiper>
                        <img src="@/assets/icons/selectRed.svg" class="arrowRight5"/>
                    </div>
                    <!-- <div class="test__block">
                        <div class="test__block__in" v-for="item in testWidget" :key="item">
                            <img src="@/assets/icons/testIcon.png"/>
                            <a :href="item.file" target="_blank"><p class="test__block__in__name">{{ item.name }}</p></a>
                        </div>
                    </div> -->
                </div>
                <div class="online customBlock">
                    <div class="customTitle">
                        <p>ОНЛАЙН КУРСЫ</p>
                        <div class="line"></div>
                    </div>
                    <div class="online__block">
                        <div class="online__block__in" v-for="item in onlineTest" :key="item">
                            <div class="online__block__in__box1">
                                <div class="online__block__in__box1__color" :class="{customColor: item.status == 0}"></div>
                            </div>
                            <div class="online__block__in__name" :class="{customName: item.status == 0}">{{ item.title }}</div>
                            <div class="greenLine" v-if="item.status == 1"></div>
                            <div class="greyLine" v-if="item.status == 0"></div>
                            <a :href="item.link" v-if="item.status == 1">Доступен</a>
                            <a v-else style="border: 1px solid #8e8e8e; color: #8e8e8e;">Недоступен</a>
                        </div>
                    </div>
                </div>
                <div class="offer customBlock">
                    <div class="customTitle">
                        <p>Книги и гайды</p>
                        <div class="line"></div>
                    </div>
                    <div class="offer__block">
                        <img src="@/assets/icons/selectRed.svg" class="arrowLeft4" style="rotate: 180deg;"/>
                        <swiper 
                        class="offer__block__swiper"
                        :navigation="{
                            nextEl: '.arrowRight4',
                            prevEl: '.arrowLeft4'}"
                        :breakpoints="{
                            0: {slidesPerView: 3, spaceBetween: 15},
                            640: { slidesPerView: 4, spaceBetween: 30 },
                        }">
                            <swiper-slide v-for="item in booksForTest" :key="item" class="offer__block__swiper__slide">
                                <img :src="item.image"/>
                                <a :href="item.link" target="_blank" class="offer__block__swiper__slide__name">{{item.title}}</a>
                            </swiper-slide>
                        </swiper>
                        <img src="@/assets/icons/selectRed.svg" class="arrowRight4"/>
                    </div>
                </div>
                <div class="indiv customBlock">
                    <div class="customTitle">
                        <p>ПОДГОТОВКА К ТЕСТАМ</p>
                        <div class="line"></div>
                    </div>
                    <div class="indiv__block">
                        <div class="indiv__block__in" v-for="item in tests" :key="item">
                            <div class="indiv__block__in__left">
                                <p class="indiv__block__in__left__name">{{ item?.title }}</p>
                                <p class="indiv__block__in__left__time">{{ item.price }}</p>
                            </div>
                            <button @click="newItemId(item)">Купить</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import extend from '@/components/newPlatform/extend.vue';
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import userInfo from '@/components/userInfo.vue';
import { Navigation } from "swiper";
import AboutProgram from '@/components/newPlatform/aboutProgram.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        AboutProgram,
        extend
    },
    data() {
        return {
            selectedTab: 0,
            openModal: false,
            openExtend: false,
            courses: false,
            booksForLang: false,
            booksForTest: false,
            teachers: false,
            contacts: false,
            testWidget: false,
            selectedItem: '',
            offers: false,
            onlineTest: [
            ],
            online: [],
            tests: [],
            group: [],
            paidCources: [],
            sales: [
            ],
            sales1: [],
            individual: [
            ],
            tabs: [
                {
                    name: 'Языковая школа'
                },
                {
                    name: 'Тестовая школа'
                },
            ],
        }
    },
    methods:{
        newTab(index) {
            this.selectedTab = index,
            this.$router.push({ query: { tab: index } });
        },
        newItemId(item){
            this.selectedItem = item;
            this.openModal = true;
        },
        async getPage() {    
            await axios.get(`/platform/courses`)
            .then(res => {
                this.courses = res.data.data
                this.individual = this.courses.filter(item => item.type === 0);
                this.online = this.courses.filter(item => item.type === 1);
                this.group = this.courses.filter(item => item.type === 2);
                this.tests = this.courses.filter(item => item.type === 3);
                this.onlineTest = this.courses.filter(item => item.type === 4);
                this.paidCources = this.courses.filter(item => item.type === 5);
            })
            .catch(err => {
            })  
            await axios.get(`/platform/books`)
            .then(res => {
                this.booksForLang = res.data.books.filter(item => item.cat_id == 2)
                this.booksForTest = res.data.books.filter(item => item.cat_id == 1)
            })
            .catch(err => {
            })   
            await axios.get(`/platform/teachers`)
            .then(res => {
                this.teachers = res.data.teachers
            })
            .catch(err => {
            })  
            await axios.get(`/platform/contacts`)
                .then(res => {
                    this.contacts = res.data.data.filter(item => item.type_id == 4)
                })
                .catch(err => {
                })
            .catch(err => {
            })  
            await axios.get(`/platform/quizzes`)
            .then(res => {
                this.testWidget = res.data.data
            })
            .catch(err => {
            }) 
            await axios.get(`/V1/page/widgets`)
            .then(res => {
                this.offers = res.data
            })
            .catch(err => {
            }) 
            await axios.get(`/V1/page/certifications`)
            .then(res => {
                this.sales1 = res.data
            })
            .catch(err => {
            })
            await axios.get(`/V1/page/certifications`)
            .then(res => {
                this.sales1 = res.data
            })
            .catch(err => {
            })
            await axios.get(`/V1/offers`)
            .then(res => {
                this.sales = res.data.offers
                console.log(sales);
                
            })
            .catch(err => {
            }) 

        },
    },
    async created() {
        this.getPage();
    },
    mounted() {
        const tabFromQuery = this.$route.query.tab;
        if (tabFromQuery) {
            this.selectedTab = Number(tabFromQuery);
        }
    },
}
</script>
<style lang="scss" scoped>
.customButton{
    border: 1px solid  #000000 !important;
    color: #000000 !important;
}
a{
    text-decoration: none;
    color: inherit;
    transition: all 0.5s ease;  
    &:hover{
        color: blue;
    }
}
.customColor{
    background-color: inherit !important;
}
.customText{
    color: #000000 !important;
}
.customName{
    color: #8e8e8e !important;
}
.customLine{
    border-top: 1px solid  #000000 !important;
}
.customBlock{
    display: flex;
    flex-direction: column;
    gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    background-color: white;
    width: 49%;
    p, a{
        font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.3472))), 14px) !important;
    }
    border-radius: 10px;
    height: min(max(160px, calc(10rem + ((1vw - 7.68px) * 12.1528))), 300px);
    overflow: auto;
    @media(max-width:768px){
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        width: 100%;
    }
}
.indiv {
    &__block {
        display: flex;
        flex-direction: column;
        gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        &__in {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left {
                display: flex;
                align-items: center;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                &__name {
                    font-weight: 700;
                    display: -webkit-box;
                    -webkit-line-clamp: 3; /* Limiting the text to 3 lines */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            button {
                width: 20%;
                border: 1px solid #FD2D2A;
                background-color: inherit;
                color: #FD2D2A;
                border-radius: 5px;
                font-weight: 700;
            }
        }
    }
    @media(max-width:768px){
        &__block{
            font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
        }
    }
}
.online{
    &__block{
        display: flex;
        flex-direction: column;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        &__in{
            display: flex;
            align-items: center;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            justify-content: space-between;
            &__box1{
                width: 5% !important;
                aspect-ratio: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #0000001A;
                &__color{
                    width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    background-color: #00D928;
                }
            }
            &__name{
                color: #00D928;
            }
            a{
                width: 20%;
                border: none;
                text-align: center;
                background-color: inherit;
                border: 1px solid #00DA0F;
                color: #00DA0F;
                border-radius: 5px;
            }
        }
    }
    @media(max-width:768px){
        &__block{
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
            &__in{
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                &__box1{
                    &__color1{
                        width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                        height: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                    }
                }
                button{
                    width: 30%;
                    font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                }
            }
        }
    }
}
.greenLine{
    border-top: 1px solid #00DA0F;
    flex-grow: 1;
}
.greyLine{
    border-top: 1px solid #8e8e8e;
    flex-grow: 1;
}
.arrowLeft1,.arrowLeft2,.arrowLeft3,.arrowLeft4{
    position: absolute;
    width: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    height: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    bottom: 50%;
    left: 0%;
}
.arrowRight1,.arrowRight2,.arrowRight3,.arrowRight4{
    position: absolute;
    width: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    height: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    bottom: 50%;
    right: 0%;
}
.offer{
    &__block{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &__swiper{
            width: 90%;
            &__slide{
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                text-align: center;
                &__name {
                    font-weight: 700;
                    display: -webkit-box;
                    -webkit-line-clamp: 3; /* Limiting the text to 3 lines */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                img{
                    width: 100%;
                    aspect-ratio: 0.8;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
        }
    }
    @media(max-width:768px){
        &__block{
            &__swiper{
                width: 85%;
            }
        }
    }
}
.line {
    width: 100%;
    border-top: 1px solid #0000001A;
}
.customTitle {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-transform: uppercase;
    @media(max-width:768px){
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
    }
}
.customTab {
    color: #000000 !important;
}
.saleCard{
    width: 49%;
    border-radius: 10px;
    height: min(max(160px, calc(10rem + ((1vw - 7.68px) * 12.1528))), 300px);
    background-size: cover;
    background-position: center;
    color: white;
    padding: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    display: flex;
    flex-direction: column;
    position: relative;
    &::before{
        content: '';
        border-radius: 10px;
        // background: linear-gradient(180deg, rgba(0, 0, 0, 0.54) 0%, rgba(0, 0, 0, 0) 50.07%);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        backdrop-filter: blur(4px);
         background: rgba(255, 255, 255, 0.1);
        z-index: 1;
    }
    @media(max-width:768px){
        width: 100%;
        aspect-ratio: 2.25;
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
    }
}
.schools {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__tabs {
                width: 70%;
                font-weight: 600;
                font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);;
                color: #00000026;
                display: flex;
                gap: 40px;
                white-space: nowrap;
                overflow: hidden;
                -webkit-overflow-scrolling: touch;
                &__in {
                    cursor: pointer
                }
            }

            &__right {
                width: 25%;
            }
        }
        &__block {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 2%;
            row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            &__sale {
                justify-content: space-between;
                &__top {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    &__title {
                        font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);;
                        font-weight: 700;
                        z-index: 2;
                    }
                    &__text {
                        font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                        z-index: 2;
                    }
                }
            }
        }
    }
    @media(max-width:768px){
        padding-top: 0px;
        &__left{
            display: none;
        }
        &__right{
            width: 100%;
            padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            border-radius: 0px;
            &__top{
                &__tabs{
                    width: 100%;
                    font-size: 21px;
                    margin-left: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                }
                &__right{
                    display: none;
                }
            }
            &__block{
                row-gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                &__sale{
                    &__top{
                        &__title{
                            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                        }
                        &__text{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
.redButton{
    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
    width: fit-content;
    background-color: #FF0000;
    border: none;
    color: white;
    z-index: 2;
    border-radius: 5px;
    @media(max-width:768px){
        padding: 10px;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
    }
}
.cert{
    justify-content: flex-end;
    align-items: center;
    gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    text-align: center;
    &__icon{
        width: min(max(45px, calc(2.8125rem + ((1vw - 7.68px) * 3.9063))), 90px);
        height: min(max(45px, calc(2.8125rem + ((1vw - 7.68px) * 3.9063))), 90px);
        border-radius: 24px;
        z-index: 2;
    }
    P{
        font-weight: 700;
        font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);;
        z-index: 2;
    }
    @media(max-width:768px){
        gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        p{
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
        }
    }
}
.book{
    &__block{
        display: flex;
        justify-content: space-between;
        &__in{
            width: 18%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            img{
                width: 100%;
                aspect-ratio: 0.8;
                object-fit: cover;
                border-radius: 5px;
            }
            p{
                color: #0000008A;
            }
        }
    }
}
.contact1{
    &__block{
        display: flex;
        justify-content: space-around;
        padding: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px)0px;
        &__in{
            width: 22%;
            img{
                width: 60%;
                aspect-ratio: 1;
                object-fit: cover;
                cursor: pointer;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            font-weight: 700;
        }
    }
    @media(max-width:768px){
        &__block{
            padding: 10px 0px;
            flex-wrap: wrap;
            gap: 5%;
            row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            &__in{
                width: 30%;
            }
        }
    }
}
.test{
    &__block{
        display: flex;
        justify-content: space-between;
        &__in{
            width: 20%;
            display: flex;
            flex-direction: column;
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            align-items: center;
            text-align: center;
            &__name{
                font-weight: 700;
            }
            &__bot{
                color: #0000008A;
            }
            img{
                width: 100%;
                aspect-ratio: 1 !important;
                height: auto;
                object-fit: cover;
            }
        }
        @media(max-width:768px){
            flex-wrap: wrap;
            gap: 5%;
            row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            &__in{
                width: 30%;
            }
        }
    }
}
</style>