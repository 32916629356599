<template>
    <div class="schools">
        <div class="schools__left">
            <platformTable />
        </div>
        <div class="schools__right">
            <div class="schools__right__top">
                <div class="schools__right__top__tabs">
                    <div class="schools__right__top__tabs__in" v-for="(tab, index) in tabs" :key="tab"
                        :class="{ customTab: selectedTab == index }" @click="selectedTab = index">
                        {{ tab.name }}
                    </div>
                </div>
                <div class="schools__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="lang" v-if="selectedTab == 0">
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет №1</p>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Основной текст</label>
                            <textarea>Запишись на бесплатный пробный урок</textarea>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Дополнительный текст</label>
                            <textarea>Дополнительный текст</textarea>
                        </div>
                    </div>
                    <div class="lang__block1__right">
                        <div class="lang__block1__right__top">
                            <div class="customInputFile">
                                <span>Изображение</span>
                                <label class="customInput__file">
                                    <div class="customInput__div">
                                        <p>Файл</p>
                                        <img src="@/assets/icons/document.svg"/>
                                    </div>
                                    <input type="file" style="display: none;"/>
                                </label>
                            </div>
                        </div>
                        <div class="lang__block1__right__top">
                            <div class="customInput">
                                <label>Ссылка</label>
                                <input type="text" placeholder="...">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет №2</p>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Основной текст</label>
                            <textarea>Запишись на бесплатный пробный урок</textarea>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Дополнительный текст</label>
                            <textarea>Дополнительный текст</textarea>
                        </div>
                    </div>
                    <div class="lang__block1__right">
                        <div class="lang__block1__right__top">
                            <div class="customInputFile">
                                <span>Изображение</span>
                                <label class="customInput__file">
                                    <div class="customInput__div">
                                        <p>Файл</p>
                                        <img src="@/assets/icons/document.svg"/>
                                    </div>
                                    <input type="file" style="display: none;"/>
                                </label>
                            </div>
                        </div>
                        <div class="lang__block1__right__top">
                            <div class="customInput">
                                <label>Ссылка</label>
                                <input type="text" placeholder="...">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет “Индивидуальные курсы general english”</p>
                    <div class="customTabTitle__right">
                        <div class="customTabTitle__right__add">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Добавить поле</p>
                        </div>
                    </div>
                </div>
                <div class="lang__block1" v-for="item in 4" :key="item">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="Elementary"/>
                        </div>
                    </div> 
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="text" placeholder="..."/>
                        </div>
                    </div>
                    <div class="lang__block1__status">
                        <div class="customInput">
                            <label>Описание</label>
                            <input type="text" placeholder="Подробная инфо."/>
                        </div>
                    </div>
                    <div class="lang__block1__delete">
                        <img src="@/assets/icons/delete.svg"/>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет “ОНЛАЙН КУРСЫ”</p>
                    <div class="customTabTitle__right">
                        <div class="customTabTitle__right__add">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Добавить поле</p>
                        </div>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="Elementary"/>
                        </div>
                    </div> 
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                    </div>
                    <div class="lang__block1__status">
                        <div class="customInput">
                            <label>Статус</label>
                            <customSelect />
                        </div>
                    </div>
                    <div class="lang__block1__delete">
                        <img src="@/assets/icons/delete.svg"/>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="PRE-INTERMEDIATE"/>
                        </div>
                    </div> 
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                    </div>
                    <div class="lang__block1__status">
                        <div class="customInput">
                            <label>Статус</label>
                            <customSelect />
                        </div>
                    </div>
                    <div class="lang__block1__delete">
                        <img src="@/assets/icons/delete.svg"/>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="PRE-INTERMEDIATE"/>
                        </div>
                    </div> 
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                    </div>
                    <div class="lang__block1__status">
                        <div class="customInput">
                            <label>Статус</label>
                            <customSelect />
                        </div>
                    </div>
                    <div class="lang__block1__delete">
                        <img src="@/assets/icons/delete.svg"/>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="UPPER-INTERMEDIATE"/>
                        </div>
                    </div> 
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                    </div>
                    <div class="lang__block1__status">
                        <div class="customInput">
                            <label>Статус</label>
                            <customSelect />
                        </div>
                    </div>
                    <div class="lang__block1__delete">
                        <img src="@/assets/icons/delete.svg"/>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет “Групповые курсы general english”</p>
                    <div class="customTabTitle__right">
                        <div class="customTabTitle__right__add">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Добавить поле</p>
                        </div>
                    </div>
                </div>
                <div class="lang__block1" v-for="item in 4">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="Elementary"/>
                        </div>
                    </div> 
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="text" placeholder="..."/>
                        </div>
                    </div>
                    <div class="lang__block1__status">
                        <div class="customInput">
                            <label>Описание</label>
                            <input type="text" placeholder="Подробная инфо."/>
                        </div>
                    </div>
                    <div class="lang__block1__delete">
                        <img src="@/assets/icons/delete.svg"/>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет “Предложение”</p>
                    <div class="customTabTitle__right">
                        <div class="customTabTitle__right__delete">
                            <img src="@/assets/icons/delete.svg"/>
                            <p>Удалить поле</p>
                        </div>
                        <div class="customTabTitle__right__add">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Добавить поле</p>
                        </div>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Название элемента</label>
                             <input placeholder="Пробный урок"/>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInputFile">
                            <span>Изображение</span>
                            <label class="customInput__file">
                                <div class="customInput__div">
                                    <p>Файл</p>
                                    <img src="@/assets/icons/document.svg"/>
                                </div>
                                <input type="file" style="display: none;"/>
                            </label>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Ссылка</label>
                             <input placeholder="..."/>
                        </div>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Название элемента</label>
                             <input placeholder="Speaking Club"/>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInputFile">
                            <span>Изображение</span>
                            <label class="customInput__file">
                                <div class="customInput__div">
                                    <p>Файл</p>
                                    <img src="@/assets/icons/document.svg"/>
                                </div>
                                <input type="file" style="display: none;"/>
                            </label>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Ссылка</label>
                             <input placeholder="..."/>
                        </div>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет “Книги и гайды”</p>
                    <div class="customTabTitle__right">
                        <div class="customTabTitle__right__delete">
                            <img src="@/assets/icons/delete.svg"/>
                            <p>Удалить поле</p>
                        </div>
                        <div class="customTabTitle__right__add">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Добавить поле</p>
                        </div>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Название элемента</label>
                             <input placeholder="Пробный урок"/>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInputFile">
                            <span>Изображение</span>
                            <label class="customInput__file">
                                <div class="customInput__div">
                                    <p>Файл</p>
                                    <img src="@/assets/icons/document.svg"/>
                                </div>
                                <input type="file" style="display: none;"/>
                            </label>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Ссылка</label>
                             <input placeholder="..."/>
                        </div>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Название элемента</label>
                             <input placeholder="Speaking Club"/>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInputFile">
                            <span>Изображение</span>
                            <label class="customInput__file">
                                <div class="customInput__div">
                                    <p>Файл</p>
                                    <img src="@/assets/icons/document.svg"/>
                                </div>
                                <input type="file" style="display: none;"/>
                            </label>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Ссылка</label>
                             <input placeholder="..."/>
                        </div>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет “НАШИ УЧИТЕЛЯ”</p>
                    <div class="customTabTitle__right">
                        <div class="customTabTitle__right__delete">
                            <img src="@/assets/icons/delete.svg"/>
                            <p>Удалить поле</p>
                        </div>
                        <div class="customTabTitle__right__add">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Добавить поле</p>
                        </div>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Название элемента</label>
                             <input placeholder="Пробный урок"/>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInputFile">
                            <span>Изображение</span>
                            <label class="customInput__file">
                                <div class="customInput__div">
                                    <p>Файл</p>
                                    <img src="@/assets/icons/document.svg"/>
                                </div>
                                <input type="file" style="display: none;"/>
                            </label>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Ссылка</label>
                             <input placeholder="..."/>
                        </div>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Название элемента</label>
                             <input placeholder="Speaking Club"/>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInputFile">
                            <span>Изображение</span>
                            <label class="customInput__file">
                                <div class="customInput__div">
                                    <p>Файл</p>
                                    <img src="@/assets/icons/document.svg"/>
                                </div>
                                <input type="file" style="display: none;"/>
                            </label>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Ссылка</label>
                             <input placeholder="..."/>
                        </div>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет “Контакты и помощь”</p>
                    <div class="customTabTitle__right">
                        <div class="customTabTitle__right__add">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Добавить поле</p>
                        </div>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Название элемента</label>
                             <input placeholder="Пробный урок"/>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInputFile">
                            <span>Изображение</span>
                            <label class="customInput__file">
                                <div class="customInput__div">
                                    <p>Файл</p>
                                    <img src="@/assets/icons/document.svg"/>
                                </div>
                                <input type="file" style="display: none;"/>
                            </label>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Ссылка</label>
                             <input placeholder="..."/>
                        </div>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Название элемента</label>
                             <input placeholder="Speaking Club"/>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInputFile">
                            <span>Изображение</span>
                            <label class="customInput__file">
                                <div class="customInput__div">
                                    <p>Файл</p>
                                    <img src="@/assets/icons/document.svg"/>
                                </div>
                                <input type="file" style="display: none;"/>
                            </label>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Ссылка</label>
                             <input placeholder="..."/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lang" v-if="selectedTab == 1">
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет Партнеры British Council</p>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Основной текст</label>
                            <textarea>Запишись на бесплатный пробный урок</textarea>
                        </div>
                    </div>
                    <div class="lang__block1__right">
                        <div class="lang__block1__right__top">
                            <div class="customInputFile">
                                <span>Изображение</span>
                                <label class="customInput__file">
                                    <div class="customInput__div">
                                        <p>Файл</p>
                                        <img src="@/assets/icons/document.svg"/>
                                    </div>
                                    <input type="file" style="display: none;"/>
                                </label>
                            </div>
                        </div>
                        <div class="lang__block1__right__top">
                            <div class="customInput">
                                <label>Ссылка</label>
                                <input type="text" placeholder="...">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет “ОНЛАЙН КУРСЫ”</p>
                    <div class="customTabTitle__right">
                        <div class="customTabTitle__right__add">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Добавить поле</p>
                        </div>
                    </div>
                </div>
                <div class="lang__block1" v-for="item in 4">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="Elementary"/>
                        </div>
                    </div> 
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                    </div>
                    <div class="lang__block1__status">
                        <div class="customInput">
                            <label>Статус</label>
                            <customSelect />
                        </div>
                    </div>
                    <div class="lang__block1__delete">
                        <img src="@/assets/icons/delete.svg"/>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет “Книги и гайды”</p>
                    <div class="customTabTitle__right">
                        <div class="customTabTitle__right__delete">
                            <img src="@/assets/icons/delete.svg"/>
                            <p>Удалить поле</p>
                        </div>
                        <div class="customTabTitle__right__add">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Добавить поле</p>
                        </div>
                    </div>
                </div>
                <div class="lang__block1" v-for="item in 2">
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Название элемента</label>
                             <input placeholder="Пробный урок"/>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInputFile">
                            <span>Изображение</span>
                            <label class="customInput__file">
                                <div class="customInput__div">
                                    <p>Файл</p>
                                    <img src="@/assets/icons/document.svg"/>
                                </div>
                                <input type="file" style="display: none;"/>
                            </label>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                             <label>Ссылка</label>
                             <input placeholder="..."/>
                        </div>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет “ПОДГОТОВКА К ТЕСТАМ”</p>
                    <div class="customTabTitle__right">
                        <div class="customTabTitle__right__add">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Добавить поле</p>
                        </div>
                    </div>
                </div>
                <div class="lang__block1">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="Elementary"/>
                        </div>
                    </div> 
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                    </div>
                    <div class="lang__block1__status">
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="text" placeholder="...">
                        </div>
                    </div>
                    <div class="lang__block1__delete">
                        <img src="@/assets/icons/delete.svg"/>
                    </div>
                </div>
                <div class="customTabTitle">
                    <p class="customTabTitle__left">Виджет "Тесты"</p>
                </div>
                <div class="lang__block1" v-for="item in 2">
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Название теста</label>
                            <input placeholder="Тест"/>
                        </div>
                    </div>
                    <div class="lang__block1__left">
                        <div class="customInput">
                            <label>Ссылка</label>
                            <input placeholder="..."/>
                        </div>
                    </div>
                    <div class="lang__block1__delete">
                        <img src="@/assets/icons/delete.svg"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import userInfo from '@/components/userInfo.vue';
import { Navigation } from "swiper";
import AboutProgram from '@/components/newPlatform/aboutProgram.vue';
import customSelect from '@/components/customSelect.vue';
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        AboutProgram,
        customSelect
    },
    data() {
        return {
            selectedTab: 0,
            tabs: [
                {
                    name: 'Языковая школа'
                },
                {
                    name: 'Тестовая школа'
                },
            ],
        }
    }
}
</script>
<style lang="scss" scoped>
.line {
    width: 100%;
    border-top: 1px solid #0000001A;
}
.customTab {
    color: #000000 !important;
}
.schools {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #0A1C15 0px, #2BC48E 500px);
    padding-top: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        display: flex;
        flex-direction: column;
        gap: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__tabs {
                width: 70%;
                font-weight: 600;
                font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);;
                color: #00000026;
                display: flex;
                gap: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
                white-space: nowrap;
                overflow: hidden;
                -webkit-overflow-scrolling: touch;
                &__in {
                    cursor: pointer
                }
            }

            &__right {
                width: 25%;
            }
        }
    }
    .lang{
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__block1{
            display: flex;
            flex-wrap: wrap;
            gap: 3.5%;
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            align-items: stretch;
            &__left{
                width: 31%;
            }
            &__right{
                height: 150px;
                width: 31%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            &__delete{
                width: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #FD2D2A;
                border-radius: 10px;
                cursor: pointer;
            }
            &__status{
                width: calc(27.5% - 50px);
            }
        }
    }
}
</style>