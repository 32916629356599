<template>
    <div class="curatorBack" @click="closeModal">
        <div class="curator" v-if="!isOk" @click.stop>
            <p class="curator__title">Оценить услугу</p>
            <p class="curator__sub">Оцените услугу “{{document_name}}”</p>
            <div class="line"></div>
            <img src="@/assets/icons/reviewImage.svg" class="curator__ok"/>
            <p class="curator__text">{{document_name}}</p>
            <div class="curator__stars">
                <div 
                    v-for="(item, index) in 5" 
                    :key="index"
                    @click="setRating(index + 1)"
                >
                    <img 
                        src="@/assets/icons/starNew.svg" 
                        :class="{'active-star': index < stars}"
                    />
                </div>
            </div>
            <button class="curator__ready" @click="saveData()">Оценить</button>
        </div> 
        <div class="curator" v-else>
            <p class="curator__title">Оценить услугу</p>
            <p class="curator__sub">Спасибо за Ваш отзыв о некорректной информации. Наша команда скоро проведет проверку</p>
            <div class="line"></div>
            <img src="@/assets/icons/ok.svg" class="curator__ok"/>
            <button class="curator__ready" @click="closeModal">Готово</button>
        </div> 
    </div>
</template>

<script>
import axios from 'axios';
export default {
    props: ['document_id', 'document_name'],
    data() {
        return {
            isOk: false,
            stars: 5
        };
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        setRating(rating) {
            this.stars = rating;
        },
        saveData(){
            const data = {
                document_id: this.document_id,
                rate: this.stars
            }
            axios.post('/platform/rate-documents', data , {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 200) {
                this.isOk = true;
                this.$emit('getNewData');
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}
.curatorBack {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #0000005b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.curator {
    width: 25%;
    background-color: white;
    padding: 20px 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    &__text {
        font-weight: 600;
    }
    &__title {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #11415D;
    }
    &__sub {
        color: #0000008A;
        text-align: center;
    }
    &__ready {
        margin-top: 20px;
        background-color: #11415D;
        color: white;
        border: none;
        width: 48%;
        padding: 15px 0;
        border-radius: 10px;
    }
    &__stars {
        display: flex;
        gap: 20px;
        align-items: center;
        img {
            width: 30px;
            height: 30px;
            cursor: pointer;
            transition: filter 0.3s ease;
        }
        .active-star {
            filter: brightness(0) saturate(100%) invert(82%) sepia(32%) saturate(4727%) hue-rotate(4deg) brightness(114%) contrast(101%);        }    
    }
    &__ok {
        width: 30%;
    }
}
</style>