<template>
    <div class="curatorBack" @click="closeModal">
        <div class="curator" v-if="isOk == false" @click.stop>
            <p class="curator__title">Запросить обсуждение</p>
            <p class="curator__sub">Готовы обсудить выбранные университеты с куратором? Выберите время и назначьте встречу!</p>
            <div class="line"></div>
            <div class="curator__dateTime">
                <div class="curator__dateTime__in">
                    <div class="customInput">
                        <label>Выберите дату</label>
                        <input type="date" v-model="forBack.plannedFor"/>
                    </div>
                </div>
                <div class="curator__dateTime__in">
                    <div class="customInput">
                        <label>Выберите время</label>
                        <CustomSelect :list="times" v-model="forBack.time_id"/>
                    </div>
                </div>
            </div>  
            <!-- <div class="curator__org">
                <div class="customInput">
                    <label>Вставьте ссылку</label>
                    <input type="text" placeholder="..." v-model="forBack.url"/>
                </div>
            </div> -->
            <p class="curator__text">*При выборе Meet / Zoom, ссылка будет отправлена Вам за 30 минут до начала беседы</p>
            <div class="curator__buttons">
                <button class="curator__buttons__left" @click="sendData()">Запланировать</button>
                <button class="curator__buttons__right" @click="closeModal">Я передумал(-а)</button>
            </div>
        </div> 
        <div class="curator" v-else>
            <p class="curator__title">Запрос отправлен!</p>
            <p class="curator__sub">Ваш куратор скоро с вами свяжется.</p>
            <div class="line"></div>
            <img src="@/assets/icons/ok.svg" class="curator__ok"/>
            <button class="curator__ready" @click="closeModal">Готово</button>
        </div> 
    </div>
</template>
<script>
import CustomSelect from './customSelect.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
    components: {
        CustomSelect
    },
    data(){
        return{
            isOk: false,
            times: [],
            forBack: {
                plannedFor: '',
                time_id: ''
            }
        }
    },
    methods: {
        closeModal(){
            this.$emit('closeModal')
        },
        async getTimes(){
            await axios.get(`/platform/client/meeting-times`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    // Assuming res.data is an array of objects
                    this.times = res.data.map(item => ({
                        ...item,
                        title: item.time, // Change "time" field to "title"
                        // Remove "time" if no longer needed
                    }));
                })
                .catch(err => {
                })
        },
        sendData(){
            if (this.forBack.plannedFor) {
                const date = new Date(this.forBack.plannedFor);
                // Форматируем в нужный формат (Y-m-d H:i:s)
                this.forBack.plannedFor = date.toISOString().slice(0, 19).replace('T', ' ');
            }
            axios.post("/platform/client/meeting", this.forBack, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Успешно отправлено!')
                    this.isOk = true;
                } else {
                    console.log(response.data);
                }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        }
    },
    
    computed:{
        ...mapGetters(["userInfo"])
    },
    
  async created() {
      this.getTimes();
  },
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
}
.curatorBack{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #0000005b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.curator{
    width: 33%;
    background-color: white;
    padding: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px) 60px;
    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    &__title{
        text-align: center;
        font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
        font-weight: 600;
        color: #11415D;
    }
    &__sub{
        color: #0000008A;
        text-align: center;
    }
    &__dateTime{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        &__in{
            width: 100%;
        }
    }
    &__org{
        width: 100%;
        margin-top: 10px;
    }
    &__text{
        color: #0000008A;
    }
    &__ready{
        margin-top: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        background-color: #11415D;
        color: white;
        border: none;
    }
    button{
        width: 48%;
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px) 0px;
        border-radius: 10px;
    }
    &__buttons{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        &__left{
            background-color: #11415D;
            color: white;
            border: none;
        }
        &__right{
            border: 1px solid #0000001A;
            background-color: inherit;
        }
    }
    &__ok{
        width: 40%;
    }
}
.customInput{
  position: relative;
  display: inline-block;
  width: 100%;
  label{
    position: absolute;
    top: -8px;
    left: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
    padding: 0 10px;
    font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
    color: #c0c0c0; 
    z-index: 98;
  }
  select {
    width: 100%;
    cursor: pointer !important;
    padding: 0px min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
    border: 1px solid #e0e0e0; /* Цвет рамки */
    border-radius: 8px;
    font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    background-color: inherit;
    font-weight: bold;
    color: #000; /* Цвет текста */
    outline: none;
  }
}
</style>