<template>
    <AddPodcast :type="'podcast'" @closeModal="openModal = false, isEdit = false" v-if="openModal" :isEdit="isEdit"/>
    <div class="mainStyle1" :class="{mainStyle3: $store.state.role == 'supervisor'}">
        <div class="mainStyle1__left">
            <platformTable />
        </div>
        <div class="mainStyle1__right">
            <div class="mainStyle1__right__top">
                <div class="mainStyle1__right__top__left">
                    <p class="mainStyle1__right__top__left__title">Международные гранты</p>
                </div>
                <div class="mainStyle1__right__top__right">
                   <userInfo />
                </div>
            </div>
            <div class="mobile">
                <div class="selects ">
                    <div class="selects__in">
                        <div class="customInput">
                            <label>Выберите стипендию</label>
                            <customSelect />
                        </div>
                    </div>
                    <div class="selects__in">
                        <div class="customInput">
                            <label>Выберите степень</label>
                            <customSelect />
                        </div>
                    </div>
                </div>
            </div>
            <div class="line" v-if="$store.state.role=='user'"></div>
            <div class="world">
                <div class="world__left">
                    <p class="customBold">Выберите международную стипендию</p>
                    <div class="world__left__block">
                        <div class="world__left__block__in" v-for="(item, index) in stips" :key="item"
                            @click="selectStip(item, index)" :class="{ customStip: selectedStip == index }">
                            <div class="world__left__block__in__top">
                                <img :src="item.image" />
                            </div>
                            <p>{{ item.title }}</p>
                        </div>
                    </div>
                </div>
                <div class="world__right">
                    <p class="customBold">Выберите степень</p>
                    <div class="world__right__block">
                        <div class="world__right__block__in1" v-for="(item, index) in steps" :key="item"
                            @click="selectStep(item, index)" :class="{ customStep: selectedStep == index }">
                            <div class="world__left__block__in__top">
                                <img :src="item.image" />
                            </div>
                            <p>{{ item.title }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="line" v-if="$store.state.role=='user'"></div>
            <div class="block">
                <!-- Show grants based on computed property -->
                <div class="block__in" v-for="item in grantsToShow" :key="item.id">
                    <grantCard @openModal="openModal = true, isEdit = true" :is-grant="true" :item="item"/>
                </div>
                <div class="plus" v-if="$store.state.role == 'supervisor'" @click="openModal = true">
                    <img src="@/assets/icons/plus.svg"/>
                </div>
            </div>
            <button @click="toggleShowMore" class="more" v-if="grants.length > 10">
                {{ showAll ? 'Скрыть' : 'Показать больше' }}
            </button>
        </div>
    </div>
</template>

<script>
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import grantCard from '@/components/grantCard.vue';
import { Swiper, SwiperSlide } from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
import userInfo from '@/components/userInfo.vue';
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import customSelect from '@/components/customSelect.vue';
import AddPodcast from '@/components/supervisor/addPodcast.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";

export default {
    components: {
        platformTable, Swiper, SwiperSlide, newCardComponent, userInfo, grantCard, customSelect, AddPodcast
    },
    data() {
        return {
            activeTab: 'list',
            selectedStip: -1,
            selectedStep: -1,
            stipId: '',
            stepId: '',
            stips: false,
            steps: false,
            grants: [],
            openModal: false,
            showAll: false,
            isEdit: false
        };
    },
    computed: {
        // Compute the grants to show based on whether showAll is true or false
        grantsToShow() {
            if (this.showAll) {
                return this.grants;
            }
            return this.grants.slice(0, 10); // Show only the first 5 grants if showAll is false
        }
    },
    methods: {
        toggleShowMore() {
            this.showAll = !this.showAll;
        },
        selectStep(item, index) {
            if (this.selectedStep === index) {
                this.selectedStep = -1;
                this.stepId = '';
            } else {
                this.selectedStep = index;
                this.stepId = item.id;
            }
            this.getPage();
        },
        selectStip(item, index) {
            if (this.selectedStip === index) {
                this.selectedStip = -1;
                this.stipId = '';
            } else {
                this.selectedStip = index;
                this.stipId = item.id;
            }
            this.getPage();
        },
        async getPage() {
            try {
                const grantRes = await axios.get(`/platform/grant-international-items?scholarship_id=${this.stipId}&study_degree_id=${this.stepId}`);
                this.grants = grantRes.data.data;

                const stipRes = await axios.get(`/platform/scholarships`);
                this.stips = stipRes.data.data;

                const stepRes = await axios.get(`/platform/study-degrees`);
                this.steps = stepRes.data.data;
            } catch (err) {
                console.error(err);
            }
        }
    },
    async created() {
        this.getPage();
    }
};
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.plus{
    border: 1px solid #0000001A;
    width: 18%;
    display: flex;
    aspect-ratio: 0.763;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 8px 4px #0000000D inset;
    border-radius: 10px;
    cursor: pointer;
}
.customTitle{
    color: #0000008A;
    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
    font-weight: 600;
}
.customStip, .customStep {
    color: white !important;
    background: #FD2D2A !important;
    font-weight: 600;
    .world__left__block__in__top {
        background-color: white !important;
    }
    .category__block__in__top {
        background-color: white !important;
    }
    .line {
        border-top: 1px solid white !important;
    }
}
.customStep{
    img{
        filter: brightness(0) saturate(100%) invert(32%) sepia(76%) saturate(4782%) hue-rotate(347deg) brightness(105%) contrast(97%) !important;
    }
}
.selects{
    display: flex;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    &__in{
        width: 25%;
    }
    @media(max-width:768px){
        flex-direction: column;
        gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        &__in{
            width: 100%;
        }
    }
}
.customBold {
    font-weight: 600;
    color: #0000008A;
    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px) !important;
}
.world{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    &__left{
        width: 60%;
        &__block{
            gap: 2.5%;
            &__in{
                &__top{
                    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)10px;
                    width: 100%;
                    border-radius: 10px;
                    img{
                        width: 100%;
                    }
                    border: 1px solid #0000001A;
                }
                width: 18%;
            }
        }
    }
    &__right{
        width: 32%;
        &__block{
            gap: 5%;
            &__in1{
                width: 30%;
            }
        }
    }
    &__left, &__right{
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__block{
            display: flex;
            flex-wrap: wrap;
            row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            &__in, &__in1{
                cursor: pointer;
                display: flex;
                padding: 8px 8px 10px;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                font-weight: 600;
                img{
                    height: 35px;
                }
            }
            &__in1{
                background-color: #FFFFFF;
                img{
                    height: 35px;
                    filter: brightness(0) saturate(100%) invert(71%) sepia(1%) saturate(503%) hue-rotate(114deg) brightness(98%) contrast(96%);
                }
            }
            &__in{
                color: #FD2D2A;
                background: white;
                img{
                    object-fit: contain;
                }
                .line{
                    border-top: 1px solid #FD2D2A;
                }
            }
        }
    }
    @media(max-width:768px){
        display: none;
    }
}
.block{
display: flex;
flex-wrap: wrap;
gap: 2.5%;
row-gap: 20px;
&__in{
    width: 18%;
}
@media(max-width:768px){
    gap: 2%;
    &__in{
        width: 49%;
    }
}
}
</style>