<template>
    <talkCurator v-if="openCurator" @closeModal="openCurator = false"/>
    <div class="favorite">
        <div class="favorite__left">
            <platformTable />
        </div>
        <div class="favorite__right">
            <div class="favorite__right__top">
                <div class="favorite__right__top__left">
                    <div class="favorite__right__top__left__title">Избранное</div>
                    <button class="favorite__right__top__left__talk" @click="openCurator = true">
                        <img src="@/assets/icons/talk.svg"/>
                        <p>Обсуждение с куратором</p>
                    </button>
                </div>
                <div class="favorite__right__top__right">
                    <userInfo />
                </div>      
            </div>
            <div class="search">
                <div class="line"></div>
                <div class="search__top">
                    <div class="search__top__left">
                        <input type="search" placeholder="Поиск программ...." v-model="searchModel" @input="updateFilters"/>
                        <img src="@/assets/icons/find.svg"/>
                    </div>  
                    <div class="search__top__images">
                        <img src="@/assets/icons/redChange.svg"/>
                    </div>
                    <div class="search__top__right">
                        <p>Сортировка:</p>  
                        <customSelect :is-border="true" :list="sort" @change="updateFilters" v-model="sortBy"/>
                    </div>
                </div>
                <div class="search__results">
                    <div class="search__results__in" v-for="item in favs" :key="item.id">
                        <newCardComponent :item="item" @newFav="newFav()"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userInfo from '@/components/userInfo.vue';
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import talkCurator from '@/components/talkCurator.vue';
import customSelect from '@/components/customSelect.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default {
    components: {
        userInfo,  platformTable, newCardComponent, talkCurator, customSelect
    },
    data(){
        return{
            activeTab: 'list',
            openCurator: false,
            favs: [],
            searchModel: '',
            sortBy: 0,
            sort: [
                {
                    title: 'Выбор',
                    id: 0,
                    type1: '',
                    order_by: 'asc'
                },
                {
                    title: 'Топ-50',
                    id: 1,
                    type1: 'top',
                    order_by: 'asc'
                },
                {
                    title: 'Топ-250',
                    id: 2,
                    type1: 'top',
                    order_by: 'desc'
                },
                {
                    title: 'Партнеры',
                    id: 3,
                    type1: 'is_partners',
                    order_by: 'asc'
                },
                {
                    title: 'Выбор наших менти',
                    id: 4,
                    type1: 'is_student_choose',
                    order_by: 'asc'
                },
            ],
        }
    },
    methods: {
        async getPage(){
            await axios.get(`/platform/client/users/favourites`, {
            headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.favs = res.data.universityPrograms
            })
            .catch(err => {
            }) 
        },
        
        async updateFilters() {
            try {
                const newSort = this.sort.find(item => item.id == this.sortBy)?.type1
                const newOder = this.sort.find(item => item.id == this.sortBy)?.order_by
                const payload = {
                    search: this.searchModel,
                    sort: newSort,
                    order_by: newOder
                };
                // Отправляем запрос
                const response = await axios.get("/platform/client/users/favourites", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                    params: payload,
                });

                // Если запрос успешен, обновляем this.programs
                this.favs = response.data.universityPrograms;

                // Для отладки (опционально)
                console.log("Filters updated successfully:", this.programs);
            } catch (error) {
                // Обрабатываем ошибку
                console.error("Error updating filters:", error);
            }
        },
        newFav(){
            this.getPage(); 
        }
    },
    async created() {
        this.getPage();
    },
}
</script>
<style lang="scss" scoped>
.button-pulse {
  animation: pulse 2s infinite 3s cubic-bezier(0.25, 0, 0, 1);
  box-shadow: 0 0 0 0 white;
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 255, 255, 0); 
  }
}
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.activeTab{
    background-color: white !important;
}
    .favorite{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        display: flex;
        flex-direction: column;
        gap: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left{
                display: flex;
                gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                align-items: center;
                &__title{
                    font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);;
                    font-weight: 600;
                }
                &__talk{
                    display: flex;
                    gap: 10px;
                    transition: .5s all ease-in-out;
                    position: relative;
                    align-items: center;
                    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                    background-color: inherit;
                    border: 1px solid #11415D;
                    border-radius: 5px;
                    color: #11415D;
                    font-weight: 700;
                    cursor: pointer;
                    img{
                        width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    }
                }
            }
            &__right {
                width: 25%;
            }
        }
    }
    
    @media(max-width:768px){
      padding-top: 0px;
      &__left{
          display: none;
      }
      &__right{
          width: 100%;
          padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
          border-radius: 0px; 
          gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
          &__top{
            &__left{
              width: 100%;
              flex-direction: column;
              text-align: center;
              justify-content: center;
              &__title{
                font-size: 21px;
              }
            }
            &__right{
                display: none;
            }
          }
      }
  }
    }
    .search{
    display: flex;
    flex-direction: column;
    gap: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        &__left{
            width: 63%;
            position: relative;
            input{
                width: 100%;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                border: 1px solid #0000001A;
                background-color: inherit;
                border-radius: 10px;
            }
            img{
                width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                position: absolute;
                right: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
            }
        }
        &__right{
            width: fit-content;
            display: flex;
            cursor: pointer;
            gap: 5px;
            border-radius: 10px;
            align-items: center;
            gap: 10px;
            border: 1px solid #0000001A;
            padding: 0px 10px;
            p{
                color: #0000008A;
                width: 100%;
            }
            select{
                border: none;
                background-color: transparent;
            }
        }
        &__images{
            display: none;
            align-items: center;
            justify-content: center;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            width: 23%;
        }
    }
    &__results{
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        row-gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
        &__in{
            width: 48%;
        }
    }
    @media(max-width:768px){
        gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        &__top{
            &__left{
                width: 75%;
                input{
                    padding: 10px min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                }
                img{
                    right: 10px;
                }
            }
            &__right{
                display: none;
            }
            &__images{
                display: flex;
            }
        }
    }
    }
</style>