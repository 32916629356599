<template>
    <div class="extendBack" @click="closeModal()">
        <div class="extend" @click.stop>
            <p class="extend__title">Продление договора</p>
            <p class="customText">Выберите способ оплаты и срок продления договора</p>
            <div class="line"></div>
            <div class="extend__tabs">
                <p class="customText">Срок продления</p>
                <div class="extend__tabs__block">
                    <div class="extend__tabs__block__in" v-for="item in tabs" :key="item.id" :class="{customTab: selectedTab.id == item.id}" @click="selectTab(item)"> 
                        <p class="customText">{{ item.name }}</p>
                    </div>
                </div>
            </div>
            <div class="extend__method">
                <p class="customText">Способ оплаты</p>
                <div class="extend__method__block">
                    <div class="extend__method__block__in" @click="selectedMethod = 'card'" :class="{customTab: selectedMethod == 'card'}">
                        <img src="@/assets/icons/card.svg"/>
                        <p class="customText">Картой</p>
                    </div>
                    <div class="extend__method__block__in" @click="selectedMethod = 'kaspi'" :class="{customTab: selectedMethod == 'kaspi'}">
                        <img src="@/assets/icons/kaspi.svg"/>
                        <p class="customText">Kaspi QR</p>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <p class="customText">Отсканируйте QR-код для оплаты продления договора в приложении Kaspi.kz</p>
            <img :src="selectedTab.image" class="extend__kaspi" v-if="selectedMethod == 'kaspi'"/>
            <p v-if="selectedMethod == 'kaspi'" class="extend__price">{{selectedTab.price}} Тг</p>
            <button v-if="selectedMethod == 'kaspi'" @click="closeModal()">Готово</button>
            <button v-else>Продолжить</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
  props: {
    propsItem: {
      type: Object,
      required: false, // Делаем необязательным
      default: null, // Если не передан, будет `null`
    },
    isCourse: {
      type: Boolean,
      required: false,
      default: false, // Значение по умолчанию
    }
  },
  data() {
    return {
      selectedTab: '',
      selectedMethod: 'kaspi',
      tabs: false,
    };
  },
  methods: {
    selectTab(item) {
      this.selectedTab = item;
    },
    closeModal() {
      this.$emit('closeModal');
      console.log('gala');
      
    },
    async getPage() {
      if (this.isCourse && !this.propsItem) {
        console.warn("propsItem не передан, загрузка отменена.");
        return;
      }

      try {
        if (this.isCourse) {
          const res = await axios.get(`/platform/courses-items/${this.propsItem.id}`);
          this.tabs = res.data.data;
        } else {
          const res = await axios.get(`/platform/status-contracts?lang=ru`);
          this.tabs = res.data.data;
        }
        this.selectedTab = this.tabs.length ? this.tabs[0] : ''; // Проверяем, есть ли данные в массиве
      } catch (err) {
        console.error("Ошибка при загрузке данных:", err);
      }
    },
  },
  watch: {
    propsItem: {
      handler(newValue) {
        if (newValue) this.getPage(); // Вызываем getPage, когда propsItem появился
      },
      immediate: true, // Запускаем обработчик сразу при создании компонента
    },
  },
  created() {
    if (!this.propsItem && this.isCourse) {
      console.warn("propsItem отсутствует при создании компонента.");
    } else {
      this.getPage();
    }
  },
};
</script>
<style lang="scss" scoped>
    .customTab{
        background-color: #11415D;
        font-weight: 600;
        img{
            filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(6592%) hue-rotate(227deg) brightness(129%) contrast(100%) !important; 
        }
        p{
            
        color: white;
        }
    }
    .extendBack{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 99;
    }
    img{
        width: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    }
    .customText{
        color: #0000008A;
        font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
    }
    .line{
        border-top: 1px solid #0000001A;
        width: 100%;
    }
    .extend{
        background-color: white;
        width: 35%;
        padding: 45px;
        display: flex;
        flex-direction: column;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        align-items: center;
        text-align: center;
        border-radius: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        &__kaspi{
            width: 30%;
        }
        &__price{
            font-weight: 700;
            font-size: 24px;
        }
        button{
            width: 40%;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)0px;
            border-radius: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            background-color: #11415D;
            border: none;
            color: white;
        }
        &__title{
            font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
            color: #11415D;
            font-weight: 600;
        }
        &__tabs{
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            width: 100%;
            &__block{
                display: flex;
                justify-content: space-between;
                width: 100%;
                border: 1px solid #0000001A;
                border-radius: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                &__in{
                    flex: 1;
                    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)0px;
                    cursor: pointer;
                    border-right: 1px solid #0000001A;
                    &:first-child{
                        border-radius: 20px 0px 0px 20px !important;
                    }
                    &:last-child{
                        border-radius: 0px 20px 20px 0px;
                    }
                    &:only-child {
                        border-radius: 20px !important;
                    }
                }
            }
        }
        &__method{
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            width: 100%;
            &__block{
                display: flex;
                justify-content: space-between;
                width: 100%;
                border: 1px solid #0000001A;
                border-radius: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                &__in{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    justify-content: center;
                    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)0px;
                    border-right: 1px solid #0000001A;
                    cursor: pointer;
                    &:first-child{
                        border-radius: 20px 0px 0px 20px !important;
                    }
                    &:last-child{
                        border-right: none;
                        border-radius: 0px 20px 20px 0px;
                    }
                    &:only-child {
                        border-radius: 20px !important;
                        border-right: none;
                    }
                    img{
                        width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                        filter: brightness(0) saturate(100%) invert(77%) sepia(10%) saturate(0%) hue-rotate(141deg) brightness(84%) contrast(104%);
                    }
                }
            }
        }
    }
</style>